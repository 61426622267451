// eslint-disable-next-line import/prefer-default-export
export const modules = [
  {
    value: 'lr-independence',
    label: 'Independence',
  },
  {
    value: 'lr-mental-health-wellbeing',
    label: 'Mental Health & Wellbeing',
  },
  {
    value: 'lr-relationships',
    label: 'Relationships',
  },
  {
    value: 'lr-sexuality-and-sexual-health',
    label: 'Sexuality & Sexual Health',
  },
  {
    value: 'lr-drugs-alcohol',
    label: 'Drugs & Alcohol',
  },
  {
    value: 'lr-safe-travel',
    label: 'Safe Travel',
  },
];

export const modulesKeyedByValue = modules.reduce((carry, module) => {
  // eslint-disable-next-line no-param-reassign
  carry[module.value] = module;
  return carry;
}, {});
