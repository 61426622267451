export default {
  default: {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target'],
  },
  hooks: {
    uponSanitizeElement: (currentNode) => {
      // if an iframe has innerHTML, dompurify will force remove it, even if its allowed
      if (currentNode.nodeName === 'IFRAME') {
        // eslint-disable-next-line no-param-reassign
        currentNode.innerHTML = '';
      }
    },
  },
};
