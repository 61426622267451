/* eslint-disable no-shadow */
const TAX = 0.1;

// Prices ex GST
const PRODUCTS = {
  tiers: [
    {
      name: 'Essentials',
      price: 580,
      type: 'tier',
    },
    {
      name: 'Premium',
      price: 880,
      type: 'tier',
    },
    {
      name: 'Ultimate',
      price: 2880,
      type: 'tier',
    },
  ],

  addons: [
    {
      name: 'AchieveWorks',
      price: 575,
      type: 'addon',
    },
    {
      name: 'All My Own Work',
      price: 400,
      type: 'addon',
    },
    {
      name: 'Life ready',
      price: 600,
      type: 'addon',
    },
  ],
};

export const state = () => ({
  cart: [],
});

export const mutations = {
  // Only allow one tier in cart
  addTierToCart: (state, name) => {
    const tier = PRODUCTS.tiers.find((tier) => tier.name === name);

    if (tier) {
      state.cart = state.cart.filter((item) => item.type !== 'tier');
      state.cart.push(tier);
    }
  },
  // Only allow unique addons in cart
  addAddonToCart: (state, name) => {
    const addon = PRODUCTS.addons.find((addon) => addon.name === name);

    if (addon) {
      const index = state.cart.findIndex((item) => item.name.includes(name));
      if (index !== -1) {
        state.cart.splice(index, 1);
      }

      state.cart.push(addon);
    }
  },
  removeAddonFromCart: (state, name) => {
    const index = state.cart.findIndex((item) => item.name === name && item.type === 'addon');
    if (index !== -1) {
      state.cart.splice(index, 1);
    }
  },
  clearCart: (state) => {
    state.cart = [];
  },
};

export const getters = {
  findCartTierByName: (state) => (name) =>
    state.cart.find((item) => item.type === 'tier' && item.name === name),
  selectedTier: (state) => state.cart.find((item) => item.type === 'tier'),
  cartAddons: (state) => state.cart.filter((item) => item.type === 'addon'),
  cartAddonByName: (state) => (name) => state.cart.find((addon) => addon.name === name),
  cartTotalExcludingTax: (state) => state.cart.reduce((total, product) => total + product.price, 0),
  totalIncludingTax: (state, getters) =>
    (getters.cartTotalExcludingTax + TAX * getters.cartTotalExcludingTax).toFixed(2),
  taxAmount: (state, getters) => (TAX * getters.cartTotalExcludingTax).toFixed(2),
  cartQty: (state) => state.cart.length,
  productTierByName: () => (name) =>
    PRODUCTS.tiers.find((addon) => addon.name.toLowerCase() === name.toLowerCase()),
  productAddonByName: () => (name) =>
    PRODUCTS.addons.find((addon) => addon.name.toLowerCase() === name.toLowerCase()),
};
