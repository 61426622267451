import { onErrorCaptured, useContext } from '@nuxtjs/composition-api';

export default function useDatadogLogging() {
  const { $logger, $config } = useContext();
  if ($config.datadogLoggingEnabled) {
    onErrorCaptured((e) => {
      $logger.error(e);
    });
  }
}
