<template>
  <footer class="d-flex justify-content-between px-4 px-md-6 py-4">
    <div>{{ year }} &copy; {{ tPlatform('name') }}</div>
    <div><NuxtLink class="terms-link" to="/terms-policy">Terms & Conditions</NuxtLink></div>
  </footer>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import useI18n from '@/hooks/i18n';

export default defineComponent({
  components: {},
  props: {},
  setup() {
    const { tPlatform } = useI18n();
    const year = new Date().getFullYear();
    return { tPlatform, year };
  },
});
</script>

<style lang="scss" scoped>
footer {
  color: $primary-1;
}

.terms-link {
  color: $primary-1;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
</style>
