import { findMenuItemByFullSlug } from '@/utils/navMenu';

export default async ({ store, error, route }) => {
  const cleanedFullSlug = route.params.relativeUrl
    .split('/')
    .filter((p) => p.length)
    .join('/');

  const foundMenuItem = findMenuItemByFullSlug(cleanedFullSlug, store.getters.schoolNavMenuItems);

  // dont match sub menus
  if (!foundMenuItem || foundMenuItem.type === 'sub-menu') {
    error({ statusCode: 404 });
  }
};
