// eslint-disable import/prefer-default-export
export const resetFocus = () => {
  const element = document.getElementById('main-content');

  if (element) {
    element.focus();
  }
};

export const getFocusableElements = (selector, targetElement) => {
  let element;

  if (selector) {
    element = document.querySelector(selector);
  } else if (targetElement) {
    element = targetElement;
  }

  if (!element) return null;

  const allElements = element.querySelectorAll(
    'a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"], iframe',
  );

  return allElements;
};

export const trapFocus = (container) => {
  const focusableElements = getFocusableElements(null, container);
  if (!focusableElements) {
    return;
  }

  const firstElement = focusableElements[0];
  const lastElement = focusableElements[focusableElements.length - 1];

  firstElement.focus(); // Added focus as some of the modals when opened did not set focus inside the modal

  container.addEventListener('keydown', (event) => {
    if (event.key === 'Tab') {
      if (event.shiftKey) {
        if (document.activeElement === firstElement) {
          event.preventDefault();
          lastElement.focus();
        }
      } else if (document.activeElement === lastElement) {
        event.preventDefault();
        firstElement.focus();
      }
    }
  });
};
