<template>
  <GlobalLayoutWrapper>
    <div class="content-wrapper">
      <div class="header-container">
        <Navigation />
      </div>

      <div class="container-fluid px-0 main mt-5">
        <main tabindex="-1" id="main-content">
          <Nuxt />
        </main>
      </div>

      <div class="footer-container">
        <Footer :userType="roleType"></Footer>
      </div>
    </div>
  </GlobalLayoutWrapper>
</template>

<script>
import { defineComponent, useStore, computed } from '@nuxtjs/composition-api';
import Footer from '@/components/Footer';
import GlobalLayoutWrapper from '@/components/layout/GlobalLayoutWrapper';
import Navigation from '@/components/layout/navigation';
import useAuth from '@/hooks/auth';

export default defineComponent({
  components: {
    Footer,
    GlobalLayoutWrapper,
    Navigation,
  },
  setup() {
    const store = useStore();
    const { user } = useAuth();

    const roleType = computed(() => user.value?.role_type ?? null);

    const toggleSideNav = () => {
      store.dispatch('ui/toggleSidebar');
    };

    return { toggleSideNav, roleType };
  },
});
</script>

<style lang="scss" scoped>
.content-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex-grow: 1;
  padding-bottom: 5rem;
}

.navigation {
  position: relative;
}
</style>
