<template>
  <div class="floating-ui d-inline-block" ref="floatingContainerElm">
    <div
      @click="toggleFloating"
      @keydown.enter.prevent="toggleFloating"
      @keydown.esc.prevent="closeFloating"
      class="d-inline-block"
      ref="referenceElm"
    >
      <slot name="trigger" :contentId="floatingId" :isOpen="isOpen"></slot>
    </div>
    <template v-if="isOpen">
      <Transition mode="out-in" name="slide-fade">
        <div
          ref="floatingElm"
          class="floating"
          :style="{ '--arrowTransform': arrowTransform, ...floatingStyles }"
          :id="floatingId"
          role="tooltip"
          :aria-hidden="isOpen ? 'false' : 'true'"
        >
          <slot name="content"></slot>
          <span class="arrow" ref="arrowElm" :style="arrowStyles"></span>
        </div>
      </Transition>
    </template>
  </div>
</template>

<script>
import { ref, computed, defineComponent, watch } from '@nuxtjs/composition-api';
import {
  useFloating,
  offset,
  shift,
  autoPlacement,
  autoUpdate,
  arrow,
  hide,
} from '@floating-ui/vue';
import useOutsideClick from '@/hooks/outsideClick';

export default defineComponent({
  setup() {
    const floatingContainerElm = ref(null);
    const referenceElm = ref(null);
    const floatingElm = ref(null);
    const arrowElm = ref(null);
    const offsetAmount = 10;
    const isOpen = ref(false);
    const floatingId = `tooltip-${Math.random().toString(36).substr(2, 9)}`;
    const trackOutsideClick = ref(false);
    const middleware = ref([
      offset(offsetAmount),
      shift(),
      autoPlacement({ alignment: 'top', autoAlignment: false }),
      hide(),
      arrow({ element: arrowElm }),
    ]);

    const { floatingStyles, middlewareData, placement } = useFloating(referenceElm, floatingElm, {
      whileElementsMounted: autoUpdate,
      middleware,
    });

    const arrowX = computed(() => middlewareData.value.arrow?.x ?? null);
    const arrowY = computed(() => middlewareData.value.arrow?.y ?? null);

    const staticSide = computed(() => {
      const side = placement.value.split('-')[0];
      return {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
      }[side];
    });

    const isHidden = computed(() => middlewareData.value.hide?.referenceHidden ?? null);

    const arrowStyles = computed(() => ({
      left: arrowX.value != null ? `${arrowX.value}px` : '',
      top: arrowY.value != null ? `${arrowY.value}px` : '',
      [staticSide.value]: `${-offsetAmount}px`,
    }));

    const arrowTransform = computed(() => {
      let arrowTransformValue;
      if (staticSide.value === 'top') {
        arrowTransformValue = 'translateX(-50%) translateY(70%) rotate(45deg)';
      }
      if (staticSide.value === 'right') {
        arrowTransformValue = 'translateX(-115%) translateY(0%) rotate(45deg)';
      }
      if (staticSide.value === 'bottom') {
        arrowTransformValue = 'translateX(-50%) translateY(-70%) rotate(45deg)';
      }
      if (staticSide.value === 'left') {
        arrowTransformValue = 'translateX(15%) translateY(0) rotate(45deg)';
      }
      return arrowTransformValue;
    });

    const openFloating = () => {
      if (!isOpen.value) {
        isOpen.value = true;
      }
    };

    const closeFloating = () => {
      if (isOpen.value) {
        isOpen.value = false;
        trackOutsideClick.value = false;
      }
    };

    const toggleFloating = () => {
      isOpen.value = !isOpen.value;
    };

    const { onOutsideClick } = useOutsideClick(floatingContainerElm);

    onOutsideClick(() => {
      closeFloating();
    });

    watch(isHidden, (val) => {
      if (val) {
        floatingElm.value.classList.add('hidden');
      } else {
        floatingElm.value.classList.remove('hidden');
      }
    });

    return {
      floatingId,
      floatingContainerElm,
      referenceElm,
      floatingElm,
      arrowElm,
      floatingStyles,
      arrowStyles,
      arrowTransform,
      isOpen,
      openFloating,
      closeFloating,
      toggleFloating,
    };
  },
});
</script>

<style lang="scss" scoped>
.floating-ui {
  position: relative;
}

.floating {
  width: max-content;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  border-radius: 6px;
  z-index: 9;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
}

.arrow {
  position: absolute;
  overflow: hidden;
  height: 10px;
  width: 10px;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    transform: var(--arrowTransform);
    top: 0;
    left: 50%;
    display: block;
  }
}

.slide-fade-enter-active {
  transition: opacity 0.25s ease;
}

.slide-fade-enter {
  transition: opacity 0.25s ease;
}

.slide-fade-leave-active {
  transition: opacity 0.25s ease;
}

.slide-fade-leave-to,
.slide-fade-enter-from {
  transform: translateX(10px);
  opacity: 0;
}

.hidden {
  visibility: hidden;
}
</style>
