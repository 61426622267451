import { ROLE_TYPE_STUDENT } from '@/constants/role_types';
import { ROLE_TEACHER_PRIMARY } from '@/constants/roles';
import { PRICING_TIERS_PAID } from '@/constants/pricingTiers';

export default ({ $auth, route, error, store }) => {
  if (!route?.meta?.[0]) {
    return;
  }

  const meta = route.meta[0];
  if (
    Array.isArray(meta.requiredRoleTypes) &&
    !meta.requiredRoleTypes.includes($auth.user?.currentOrgRole?.role_type)
  ) {
    error({
      statusCode: 401,
      message: 'Not Authorized',
    });
  }

  if (
    Array.isArray(meta.requiredRoles) &&
    !meta.requiredRoles.includes($auth.user?.currentOrgRole?.role)
  ) {
    error({
      statusCode: 401,
      message: 'Not Authorized',
    });
  }

  if (
    meta.requiredUserPermission !== undefined &&
    $auth.user?.currentOrgRole?.role !== ROLE_TEACHER_PRIMARY && // only applies to secondary teachers for now
    ($auth.user?.currentOrgRole?.role_type === ROLE_TYPE_STUDENT ||
      !$auth.user?.currentOrgRole?.permissions ||
      !$auth.user.currentOrgRole.permissions.includes(meta.requiredUserPermission))
  ) {
    error({
      statusCode: 401,
      message: 'Not Authorized',
    });
  }

  const { schoolPermissions, schoolTestPermissions, school } = store.getters;
  if (
    meta.requiredSchoolPermission !== undefined &&
    !(
      schoolPermissions[meta.requiredSchoolPermission] ||
      schoolTestPermissions[meta.requiredSchoolPermission]
    )
  ) {
    error({
      statusCode: 401,
      message: 'Not Authorized',
    });
  }

  const allowedPricingTiers = meta.allowedPricingTiers || PRICING_TIERS_PAID;
  if (school && !allowedPricingTiers.includes(school.pricing_tier)) {
    error({
      statusCode: 401,
      message: 'Not Authorized',
    });
  }
};
