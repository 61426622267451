import { modules } from '@/utils/lifeReady';

const activityIconPathMappings = {
  'App\\Models\\Activity\\Type\\Scorm': 'default/play',
  'App\\Models\\Activity\\Type\\Quiz': 'default/clipboard',
};

const isActivityDisabledForUser = (program, activity, user, schoolYearDisabledComponents) => {
  if (user?.currentOrgRole?.role_type === 'teacher') {
    return false;
  }

  const studentYear = user?.currentOrgRole?.year || user?.currentSchoolStudentOrgRole?.year;

  if (!studentYear) {
    return false;
  }

  return schoolYearDisabledComponents.some(
    (dc) =>
      dc.component_type === `${program.slug}-activity` &&
      // eslint-disable-next-line eqeqeq
      dc.component_id == activity.id &&
      dc.year === studentYear,
  );
};

export default (store, user, filterDisabledActivities = true) =>
  store.state.programs
    .map((program) => {
      const journeys = store.state.activities
        .filter((a) => a.type === 'App\\Models\\Activity\\Type\\Journey')
        .filter((a) => a.program_id === program.id)
        .map((j) => {
          const activityState = user.activityStates.find((as) => as.activity_id === j.id);
          const status = activityState ? activityState.status : 'not-started';
          const pdfDownloadUrl = activityState ? activityState.pdf_download_url : null;

          const allChildActivities = store.state.activities
            .filter((a) => a.parent_id === j.id)
            .sort((a, b) => (a.order > b.order ? 1 : -1));

          const childActivities = allChildActivities
            .map((a) => {
              // eslint-disable-next-line no-shadow
              const activityState = user.activityStates.find((as) => as.activity_id === a.id);

              return {
                status: activityState ? activityState.status : 'not-started',
                progress: activityState ? activityState.progress : 0,
                ...a,
                route: `/journeys/${j.slug}/${a.slug}`,
                iconPath: activityIconPathMappings[a.type],
                disabled: isActivityDisabledForUser(
                  program,
                  a,
                  user,
                  store.state.schoolYearDisabledComponents,
                ),
              };
            })
            .filter((a) => {
              if (filterDisabledActivities) {
                return !a.disabled;
              }
              return true;
            });

          const enabledActivityCount = childActivities.filter((a) => !a.disabled).length;

          const totalActivities = childActivities.length;
          const activitiesCompleted = childActivities.reduce((carry, ca) => {
            if (ca.status === 'complete') {
              carry += 1; // eslint-disable-line no-param-reassign
            }
            return carry;
          }, 0);

          const percentageComplete = Math.round((activitiesCompleted / totalActivities) * 100);

          const nextActivity = childActivities.find((ca) =>
            ['not-started', 'in-progress'].includes(ca.status),
          );

          const meta = JSON.parse(j.meta);
          return {
            ...j,
            iconPath: `programs/${program.slug}/${j.slug}`,
            description: meta?.description,
            route: `/journeys/${j.slug}`,
            status,
            childActivities,
            activitiesCompleted,
            totalActivities,
            percentageComplete,
            nextActivity,
            pdfDownloadUrl,
            completedAllActivities: childActivities.length === activitiesCompleted,
            allChildActivitiesDisabled: enabledActivityCount === 0,
            allChildActivitiesEnabled: allChildActivities.length === enabledActivityCount,
          };
        })
        .filter((j) => j.childActivities.length)
        .sort((a, b) => (a.order > b.order ? 1 : -1));

      const journeyWithNextActivity = journeys.find((j) => j.nextActivity);
      const nextActivity = journeyWithNextActivity ? journeyWithNextActivity.nextActivity : null;

      const totalJourneyCount = journeys.length;

      const completedJourneyCount = journeys.filter((j) => j.status === 'complete').length;

      const totalModuleMinutes = user.currentSchoolModuleHoursAssignments
        .filter((ma) => ma.program_id === program.id)
        .reduce((carry, a) => {
          // eslint-disable-next-line no-param-reassign
          carry += a.minutes;
          return carry;
        }, 0);

      const totalModuleHours = Math.round(totalModuleMinutes / 60);

      const moduleHours = modules.reduce((carry, mod) => {
        // eslint-disable-next-line no-param-reassign
        carry[mod.value] = Math.round(
          user.currentSchoolModuleHoursAssignments
            .filter((ma) => ma.program_id === program.id)
            .filter((ma) => ma.module === mod.value)
            .reduce((c, a) => {
              // eslint-disable-next-line no-param-reassign
              c += a.minutes;
              return c;
            }, 0) / 60,
        );
        return carry;
      }, {});

      return {
        ...program,
        journeys,
        nextActivity,
        totalJourneyCount,
        completedJourneyCount,
        totalModuleHours,
        moduleHours,
      };
    })
    .filter((p) => p.journeys.length)
    .sort((a, b) => (a.order > b.order ? 1 : -1));
