export default async ({ store, redirect, route }) => {
  if (!store.state.auth.user) {
    return;
  }

  if (route.name !== 'custom-resume-select-modules') {
    return;
  }

  const formAttemptId = route.params.id;

  const customResumeActivityState = store.state.auth.user.activityStates.find(
    (item) => String(item.form_attempt_id) === formAttemptId,
  );

  if (!customResumeActivityState) {
    return;
  }

  redirect(`/custom-resume/${formAttemptId}`);
};
