<template>
  <GlobalLayoutWrapper>
    <main tabindex="-1" id="main-content">
      <Nuxt />
    </main>
  </GlobalLayoutWrapper>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import GlobalLayoutWrapper from '@/components/layout/GlobalLayoutWrapper';

export default defineComponent({
  components: {
    GlobalLayoutWrapper,
  },
  head() {},
  setup() {},
});
</script>

<style lang="scss">
body {
  position: relative;
}
</style>
