import { provide, onGlobalSetup, defineNuxtPlugin, onMounted } from '@nuxtjs/composition-api';
import { DefaultApolloClient } from '@vue/apollo-composable';

export default defineNuxtPlugin(({ app }) => {
  onGlobalSetup(() => {
    onMounted(() => {
      // eslint-disable-next-line no-param-reassign
      app.apolloProvider.defaultClient.disableNetworkFetches = false;
    });
    provide(DefaultApolloClient, app.apolloProvider.defaultClient);
  });
});
