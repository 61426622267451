import { useContext, computed, useStore } from '@nuxtjs/composition-api';
import buildUserPrograms from '@/utils/buildUserPrograms';
import buildUserFormAttempts from '@/utils/buildUserFormAttempts';
import useRecommendations from '@/hooks/recommendations';
import useSchool from '@/hooks/school';
import { ROLE_TEACHER_PRIMARY } from '@/constants/roles';
import { COUNTRY_CODE_USA, COUNTRY_CODE_AUS } from '@/constants/countries';
import { ROLE_TYPE_TEACHER, ROLE_TYPE_STUDENT } from '@/constants/role_types';
import { TEST_FEATURE_CAP_AND_WALLET } from '@/constants/pricingTiers';
import useMixpanel from '@/hooks/mixpanel';

const schoolRoleColorMap = {
  0: '#EA9710',
  1: '#069FD6',
  2: '#E54B42',
  3: '#7FB836',
  4: '#365AB8',
  5: '#6F36B8',
  6: '#B836A3',
  7: '#CA0813',
  8: '#944828',
  9: '#528086',
};

const getOrgInitials = (orgName) => {
  if (!orgName) {
    return '?';
  }

  const parts = orgName.split(' ');

  if (parts.length === 0) {
    return '?';
  }

  if (parts.length === 1) {
    return parts[0][0].toUpperCase();
  }

  return parts[0][0].toUpperCase() + parts[1][0].toUpperCase();
};

export default function useAuth() {
  const { $auth, $cookies } = useContext();
  const store = useStore();
  const { hasSchoolPermission, school, hasTestFeatureEnabled } = useSchool();
  const mixpanel = useMixpanel();
  const { getRecommendations } = useRecommendations();

  const user = computed(() => {
    if (!$auth.user) {
      return null;
    }

    return {
      ...$auth.user,
      settings: $auth.user.settings ? JSON.parse($auth.user.settings) : {},
      role_type: $auth.user.currentOrgRole.role_type || null,
      role: $auth.user.currentOrgRole.role || null,
      class_year: $auth.user.currentOrgRole.class_year || null,
      roleSettings: $auth.user.currentOrgRole.settings
        ? JSON.parse($auth.user.currentOrgRole.settings)
        : {},
      completedRecommendationKeys: $auth.user.completedRecommendations.reduce((carry, cr) => {
        carry.push(cr.key);
        return carry;
      }, []),
      programs: buildUserPrograms(store, $auth.user),
      formAttempts: buildUserFormAttempts(store, $auth.user),
      permissions: $auth.user.currentOrgRole.permissions,
      schoolRoles: $auth.user.schoolRoles.map((role, index) => {
        return {
          ...role,
          bgColor: schoolRoleColorMap[index % 10],
          orgInitials: getOrgInitials(role.org.name),
        };
      }),
    };
  });

  const isTeacher = computed(() => {
    if (!user.value) {
      return false;
    }

    if (user.value.role_type === ROLE_TYPE_TEACHER) {
      return true;
    }

    return false;
  });

  const isPrimaryTeacher = computed(
    () => isTeacher.value && user.value.role === ROLE_TEACHER_PRIMARY,
  );

  const hasPermission = (permission) => {
    if (!user.value) {
      return false;
    }

    if (!isTeacher.value) {
      return false;
    }

    if (isPrimaryTeacher.value) {
      return true;
    }

    if (user.value.permissions.includes(permission)) {
      return true;
    }

    return false;
  };

  const nextCareerInvestigation = computed(() => {
    if (!user.value) {
      return null;
    }

    return user.value.formAttempts['career-investigator'].find((ci) => ci.activityState === null);
  });

  const findFormAttempt = (formSlug, formAttemptId) => {
    if (!user.value) {
      return null;
    }

    return user.value.formAttempts[formSlug].find((fa) => fa.id === formAttemptId);
  };

  const aboutMeLocaleConfig = {
    [COUNTRY_CODE_USA]: ['gender'],
    [COUNTRY_CODE_AUS]: [
      'gender',
      'is_atsi_or_fn',
      'has_disability',
      'has_tfn',
      'leave_school_when',
    ],
  };

  const profileSectionMissingRequiredFieldCounts = computed(() => {
    if (!user.value) {
      return null;
    }

    if (user.value.role_type !== ROLE_TYPE_STUDENT) {
      return null;
    }

    const countryCode = school.value.country.code;

    const sectionMissingFieldCounts = {
      accountDetails: 0,
      aboutMe: 0,
      parents: 0,
    };

    if (!user.value.first_name) {
      sectionMissingFieldCounts.accountDetails += 1;
    }

    if (!user.value.last_name) {
      sectionMissingFieldCounts.accountDetails += 1;
    }

    // USI required for current 11 / 12 students
    if ([12, 11].includes(user.value.year) && !user.value.usi) {
      sectionMissingFieldCounts.accountDetails += 1;
    }

    if (aboutMeLocaleConfig[countryCode].includes('gender') && user.value.gender === null) {
      sectionMissingFieldCounts.aboutMe += 1;
    }

    if (
      aboutMeLocaleConfig[countryCode].includes('is_atsi_or_fn') &&
      user.value.is_atsi_or_fn === null
    ) {
      sectionMissingFieldCounts.aboutMe += 1;
    }

    if (user.value.is_atsi_or_fn) {
      if (user.value.language_groups.length === 0) {
        sectionMissingFieldCounts.aboutMe += 1;
      }

      if (user.value.speaks_atsi_language_at_home === null) {
        sectionMissingFieldCounts.aboutMe += 1;
      }
    }

    if (
      aboutMeLocaleConfig[countryCode].includes('has_disability') &&
      user.value.has_disability === null
    ) {
      sectionMissingFieldCounts.aboutMe += 1;
    }

    if (user.value.has_disability) {
      if (user.value.disability_types.length === 0) {
        sectionMissingFieldCounts.aboutMe += 1;
      }

      if (user.value.has_disability_pension === null) {
        sectionMissingFieldCounts.aboutMe += 1;
      }
    }

    if (aboutMeLocaleConfig[countryCode].includes('has_tfn') && user.value.has_tfn === null) {
      sectionMissingFieldCounts.aboutMe += 1;
    }

    if (
      aboutMeLocaleConfig[countryCode].includes('leave_school_when') &&
      user.value.leave_school_when === null
    ) {
      sectionMissingFieldCounts.aboutMe += 1;
    }

    if (!user.value.parent_1_first_name) {
      sectionMissingFieldCounts.parents += 1;
    }

    if (!user.value.parent_1_last_name) {
      sectionMissingFieldCounts.parents += 1;
    }

    if (!user.value.parent_1_email) {
      sectionMissingFieldCounts.parents += 1;
    }

    return sectionMissingFieldCounts;
  });

  const profileNotCompleted = computed(() => {
    if (!profileSectionMissingRequiredFieldCounts.value) {
      return false;
    }
    return Boolean(
      profileSectionMissingRequiredFieldCounts.value.accountDetails ||
        profileSectionMissingRequiredFieldCounts.value.aboutMe ||
        profileSectionMissingRequiredFieldCounts.value.parents,
    );
  });

  const userRecommendations = computed(() =>
    getRecommendations(user.value.role_type)
      .filter((rec) => {
        if (
          user.value.role_type === ROLE_TYPE_TEACHER &&
          rec.primaryOnly &&
          user.value.role !== ROLE_TEACHER_PRIMARY
        ) {
          return false;
        }

        if (rec.requiredPermission && !hasPermission(rec.requiredPermission)) {
          return false;
        }

        if (rec.requiredSchoolPermission && !hasSchoolPermission(rec.requiredSchoolPermission)) {
          return false;
        }

        if (rec.requiredCopilotActivated || rec.requiredWalletActivated) {
          if (!hasTestFeatureEnabled(TEST_FEATURE_CAP_AND_WALLET)) return false;
        }

        return true;
      })
      .map((rec) => ({
        ...rec,
        completed: user.value.completedRecommendationKeys.includes(rec.key),
      })),
  );

  const userDefaultActivatedActionPlan = computed(() => {
    if (!user.value.pspActionPlans.length) {
      return null;
    }

    if (!user.value.pspActionPlans[0].occupation) {
      return null;
    }

    return user.value.pspActionPlans[0];
  });

  const logout = async () => {
    mixpanel.logout($auth.user.guid);
    await store.dispatch('psp/resetPspState');
    await $auth.safeLogout();
  };

  const isImpersonating = computed(() => {
    return $auth.isImpersonating;
  });

  const couldBeAnotherUser = computed(() => {
    if (isImpersonating.value) {
      return true;
    }

    // When logging in via Login Token, this is set for some of the
    // Login Token types, is also enforced in the backend
    if ($cookies.get('auth.unsafe_token_login')) {
      return true;
    }

    return false;
  });

  const canEditCoreDetails = computed(() => {
    return !couldBeAnotherUser.value || !user.value.is_multi_school;
  });

  const isMultiSchoolUser = computed(() => {
    if (!user.value) {
      return false;
    }

    if (couldBeAnotherUser.value) {
      return false;
    }

    return user.value.is_multi_school;
  });

  return {
    user,
    userRecommendations,
    hasPermission,
    isTeacher,
    isPrimaryTeacher,
    nextCareerInvestigation,
    findFormAttempt,
    profileSectionMissingRequiredFieldCounts,
    profileNotCompleted,
    logout,
    userDefaultActivatedActionPlan,
    isMultiSchoolUser,
    isImpersonating,
    couldBeAnotherUser,
    canEditCoreDetails,
  };
}
