export default function useEventCallback() {
  let callback = null;

  const register = (fn) => {
    callback = fn;
  };

  const trigger = (param) => {
    if (callback) {
      return callback(param);
    }
    return null;
  };

  return {
    register,
    trigger,
  };
}
