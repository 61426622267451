<template>
  <div class="d-flex justify-content-center align-items-center centered-container">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
.centered-container {
  min-height: calc(80vh - 150px);
}
</style>
