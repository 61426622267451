/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client/core';

export const BASE_AUTH_USER_FIELDS = gql`
  fragment BaseAuthUserFields on User {
    id
    settings
    guid
    first_name
    last_name
    full_name
    email
    onboarded
    usi
    student_number
    form_contact_email
    phone_number
    gender
    is_atsi_or_fn
    language_groups
    speaks_atsi_language_at_home
    has_disability
    has_disability_pension
    disability_types
    has_tfn
    leave_school_when
    parent_1_first_name
    parent_1_last_name
    parent_1_email
    parent_2_first_name
    parent_2_last_name
    parent_2_email
    quick_tour_complete
    is_sso_linked
    is_multi_school
    schoolRoles {
      id
      org {
        id
        name
      }
    }
    currentOrgRole {
      id
      org_id
      role_type
      role
      settings
      permissions
      class_year
      year
    }
    formAttempts {
      id
      activity_id
      title
      created_at
      occupation {
        id
        name
        slug
      }
      migrated_occupation_text
      external_id
      external_password
    }
    activityStates {
      id
      form_attempt_id
      activity_id
      status
      progress
      updated_at
      pdf_download_url
    }
    completedRecommendations {
      id
      key
    }
    currentSchoolModuleHoursAssignments {
      id
      module
      minutes
      program_id
    }
    currentSchoolFavouriteGroups {
      id
    }
    emails {
      id
      value
      verified
      is_primary
      is_sso_linked
    }
    inUseAuthMethod {
      value
      verified
    }
    latestCompletedQuizAttempt {
      id
    }
    pspActionPlans {
      id
      attempt_id
      occupation {
        id
        name
        slug
      }
    }
    recentNotifications {
      id
      target_id
      target_type
      type
      data
      status
      read
      updated_at
    }
  }
`;
