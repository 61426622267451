import { ref, computed, onMounted, onUnmounted } from '@nuxtjs/composition-api';

export default function useBreakpoint() {
  const getInnerWidth = () => {
    if (!process.client) {
      return null;
    }

    return window.innerWidth;
  };

  const currentWidth = ref(getInnerWidth());

  const onResize = () => {
    currentWidth.value = getInnerWidth();
  };

  onMounted(() => {
    if (!process.client) {
      return;
    }

    window.addEventListener('resize', onResize);
  });

  onUnmounted(() => {
    if (!process.client) {
      return;
    }

    window.removeEventListener('resize', onResize);
  });

  const currentBreakpoint = computed(() => {
    if (!process.client) {
      return null;
    }

    if (currentWidth.value < 576) {
      return 'xs';
    }

    if (currentWidth.value < 768) {
      return 'sm';
    }

    if (currentWidth.value < 992) {
      return 'md';
    }

    if (currentWidth.value < 1200) {
      return 'lg';
    }

    if (currentWidth.value < 1400) {
      return 'xl';
    }

    return 'xxl';
  });

  return currentBreakpoint;
}
