export default ({ $auth, redirect, store }) => {
  const { getters } = store;
  const { schoolPermissions } = getters;
  if (!schoolPermissions.website) {
    if ($auth.user) {
      redirect('/dashboard');
    } else {
      redirect('/login');
    }
  }
};
