export default [
  'dashboard-page',
  'login-page',
  'unverified-page',
  'verify-page',
  'secondary-verify-page',
  'register-page',
  'forgot-password-page',
  'reset-password-page',
  'notifications-page',
  'career-pass',
  'career-pass-profile',
  'career-pass-preview',
  'career-pass-share',
  'terms-policy-website',
  'profile-page',
  'sitemap',
  'credential-management',
  'credential-assignment',
  'students-bulk-index',
  'students-bulk-add',
  'students-bulk-update',
  'students-bulk-view-upload',
  'students-invite',
  'student-groups',
  'student-groups-create',
  'student-groups-add-student',
  'student-groups-view',
  'student-profile-edit',
  'teacher-psp-readonly',
  'psp-saved-plans',
  'psp-skills-strengths',
  'psp-action-plan',
  'psp-occupation-page',
  'post-school-planner-explore',
  'subscription-centre',
  'token-login',
  'switch-role',
  'invitation-page',
  'course-search-index',
  'diagnostics',
];
