/* eslint-disable no-shadow */

export const state = () => ({
  enteringImpersonate: false,
  leavingImpersonate: false,
});

export const mutations = {
  setEnteringImpersonate: (state, enteringImpersonate) => {
    state.enteringImpersonate = enteringImpersonate;
  },
  setLeavingImpersonate: (state, leavingImpersonate) => {
    state.leavingImpersonate = leavingImpersonate;
  },
};

export const getters = {};

export const actions = {};
