export const PRICING_TIER_ESSENTIAL = 'essential';
export const PRICING_TIER_TEST_ESSENTIAL = 'test_essential';
export const PRICING_TIER_PREMIUM = 'premium';
export const PRICING_TIER_SA_DFE = 'sa_dfe';
export const PRICING_TIER_TEST_PREMIUM = 'test_premium';
export const PRICING_TIER_CUSTOM = 'custom';
export const PRICING_TIER_SHADOW = 'shadow';
export const PRICING_TIER_FREE = 'free';

export const TEST_FEATURE_E_PORTFOLIO_CREDENTIALS = 'e_portfolio_credentials';
export const TEST_FEATURE_CAP_AND_WALLET = 'cap_and_wallet';
export const TEST_FEATURE_COURSE_SEARCH_V2 = 'course_search_v2';
export const TEST_FEATURE_ACADEMY = 'academy';

export const PRICING_TIERS_PAID = [
  PRICING_TIER_ESSENTIAL,
  PRICING_TIER_TEST_ESSENTIAL,
  PRICING_TIER_PREMIUM,
  PRICING_TIER_SA_DFE,
  PRICING_TIER_TEST_PREMIUM,
  PRICING_TIER_CUSTOM,
  PRICING_TIER_SHADOW,
];

export const PRICING_TIERS_ALL = [
  PRICING_TIER_ESSENTIAL,
  PRICING_TIER_TEST_ESSENTIAL,
  PRICING_TIER_PREMIUM,
  PRICING_TIER_SA_DFE,
  PRICING_TIER_TEST_PREMIUM,
  PRICING_TIER_CUSTOM,
  PRICING_TIER_SHADOW,
  PRICING_TIER_FREE,
];
