<template>
  <button
    class="icon-button"
    :disabled="disabled || loading"
    @click.stop="click"
    :aria-label="ariaLabel ? ariaLabel : ''"
  >
    <IconDefault :width="width" :height="height" :path="path" />
  </button>
</template>

<script>
import { defineComponent, useRouter } from '@nuxtjs/composition-api';
import IconDefault from '@/components/icon/Default';

export default defineComponent({
  components: {
    IconDefault,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: [String, Object],
      required: false,
    },
    isExternal: {
      type: Boolean,
      required: false,
    },
    path: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: false,
    },
    height: {
      type: Number,
      required: false,
    },
    preventBubble: {
      type: Boolean,
      required: false,
      default: false,
    },
    ariaLabel: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();

    const click = () => {
      if (!props.to) {
        emit('click');
        return;
      }

      if (props.isExternal) {
        window.open(props.to);
        return;
      }

      router.push(props.to);
    };

    return { click };
  },
});
</script>

<style lang="scss" scoped>
.icon-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    :not(:disabled) {
      transform: scale(1.1);
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }
  }
}
</style>
