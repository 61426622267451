<template>
  <button
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    @click="$emit('click', $event)"
    class="nav-menu-button d-flex align-items-center"
    :class="{ 'is-active': isActive, 'level-not-active': !isActive && !levelIsActive }"
  >
    <div class="me-3">{{ menuItem.text }}</div>
    <RightChevronIcon
      :width="8"
      :height="10"
      :color="hovered || isActive ? 'white' : websiteThemeColor"
    />
  </button>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import RightChevronIcon from '@/components/icon/inline/ChevronRightAlternate';
import useSchool from '@/hooks/school';

export default defineComponent({
  components: { RightChevronIcon },
  props: {
    menuItem: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
    },
    levelIsActive: {
      type: Boolean,
    },
  },
  setup() {
    const { websiteThemeColor } = useSchool();
    const hovered = ref(false);

    return { websiteThemeColor, hovered };
  },
});
</script>

<style lang="scss" scoped>
.nav-menu-button {
  background: none;
  border: none;
  font: inherit;
  outline: inherit;
  font-size: toRem(16);
  font-weight: 700;
  border-radius: 8px;
  text-align: left;
  width: 100%;

  color: $school-theme-color;
  background-color: white;
  padding: 12px 8px;
  margin-bottom: 4px;

  &:hover {
    color: white;
    background-color: $school-theme-color;
  }

  &.level-not-active {
    opacity: 0.8;
  }

  &.is-active {
    opacity: 0.8;
    color: white;
    background-color: $school-theme-color;
  }
}
</style>
