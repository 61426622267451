<template>
  <div class="top-nav">
    <div
      v-if="$auth.user && $auth.isImpersonating"
      class="top-nav-impersonate d-flex justify-content-center"
    >
      <div class="impersonate-container d-flex align-items-center justify-content-center py-2 px-3">
        <div class="me-2">Impersonating Student</div>
        <button
          class="no-style impersonate-button px-2 py-1"
          @click="$auth.leaveImpersonate($route.path)"
        >
          Exit View
        </button>
      </div>
    </div>
    <div class="top-nav-menu d-flex justify-content-between align-items-center">
      <div>
        <button
          class="no-style mobile-sidebar-toggle"
          @click="toggleSidebar"
          type="button"
          aria-label="Toggle Side Menu"
          :aria-expanded="sidebarExpanded ? 'true' : 'false'"
        >
          <Hamburger :width="32" />
        </button>
      </div>
      <div class="nav-dropdowns">
        <NotificationsNav v-if="isTeacher" :isFreeTier="true" />

        <NavDropdown
          v-if="isTeacher"
          :openInNewTab="true"
          iconPath="free-tier/help"
          :href="platformSupportUrl"
        ></NavDropdown>

        <NavDropdown :minWidth="isMultiSchoolUser ? 275 : 200" iconPath="free-tier/profile">
          <template v-if="isMultiSchoolUser" v-slot:button-badge>
            <MultiSchoolRoleIcon :isSmall="true" :role="currentRole" />
          </template>

          <ul class="menu-items no-style">
            <li>
              <NavDropdownLink
                :isFirst="true"
                to="/profile"
                iconPath="free-tier/menu-icons/account"
                text="Profile"
              />
            </li>
            <li v-if="isMultiSchoolUser">
              <RoleSwitcher :highlightOnHover="true" />
            </li>
            <li>
              <NavDropdownLink
                :isLast="true"
                iconPath="free-tier/menu-icons/logout"
                text="Logout"
                @click="logout()"
              />
            </li>
          </ul>
        </NavDropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, useStore } from '@nuxtjs/composition-api';
import NavDropdown from '@/components/layout/freeTier/NavDropdown';
import useAuth from '@/hooks/auth';
import usePlatform from '@/hooks/platform';
import Hamburger from '@/components/icon/inline/Hamburger';
import NavDropdownLink from '@/components/layout/freeTier/NavDropdownLink';
import RoleSwitcher from '@/components/layout/navigation/RoleSwitcher';
import MultiSchoolRoleIcon from '@/components/layout/navigation/MultiSchoolRoleIcon';
import NotificationsNav from '@/components/layout/navigation/Notifications';
import { PLATFORM_CAREERPASS, PLATFORM_CAREERTOOLS } from '@/constants/platforms';

export default defineComponent({
  components: {
    NavDropdown,
    Hamburger,
    NavDropdownLink,
    RoleSwitcher,
    MultiSchoolRoleIcon,
    NotificationsNav,
  },
  props: {},
  setup() {
    const store = useStore();

    const { platform } = usePlatform();
    const { logout, isMultiSchoolUser, user, isTeacher } = useAuth();

    const sidebarExpanded = computed({
      get: () => store.state.ui.sidebarExpanded,
      set: (val) => {
        store.commit('ui/setSidebarExpanded', val);
      },
    });

    const toggleSidebar = () => {
      sidebarExpanded.value = !sidebarExpanded.value;
    };

    const currentRole = computed(() => {
      return user.value.schoolRoles.find((r) => r.id === user.value.currentOrgRole.id);
    });

    const supportUrlMap = {
      [PLATFORM_CAREERPASS]: 'https://support.careerpass.com',
      [PLATFORM_CAREERTOOLS]: 'https://careertools.zendesk.com',
    };

    const platformSupportUrl = supportUrlMap[platform.value];

    return {
      toggleSidebar,
      sidebarExpanded,
      logout,
      isMultiSchoolUser,
      currentRole,
      isTeacher,
      platformSupportUrl,
    };
  },
});
</script>

<style lang="scss" scoped>
.top-nav {
  pointer-events: none;
  position: relative;
  width: 100%;

  .nav-dropdowns {
    pointer-events: auto;
  }

  .top-nav-impersonate {
    pointer-events: auto;
  }

  .mobile-sidebar-toggle {
    color: $primary-1;
    display: block;
    pointer-events: auto;

    @include m-medium {
      display: none;
    }
  }

  .top-nav-menu {
    position: relative;
    width: 100%;
    padding: 10px 10px 10px 10px;
    @include m-medium {
      padding: 30px 0px 0px 0px;
      position: absolute;
    }
  }

  .top-nav-impersonate {
    position: relative;
    width: 100%;

    @include m-medium {
      position: absolute;
    }

    .impersonate-container {
      background-color: $toast-success;
      color: white;
      border-radius: 0px 0px 10px 10px;
      width: 100%;

      @include m-medium {
        width: auto;
      }

      .impersonate-button {
        border: 2px solid white;
        border-radius: 10px;

        &:hover {
          background-color: lighten($toast-success, 10%);
        }
      }
    }
  }
}

.menu-items {
  width: 100%;
  border-radius: 6px;
}

li {
  &:not(:last-of-type) {
    border-bottom: 1px solid rgba(231, 231, 231, 1);
  }

  &:first-of-type {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }

  &:last-of-type {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  color: $primary;
  background-color: white;
}
</style>
