<template>
  <div>
    <div
      class="modal fade show"
      tabindex="-1"
      aria-labelledby="modalLabel"
      aria-hidden="false"
      aria-modal="true"
      role="dialog"
      style="display: block"
      ref="modalRef"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        :class="[`modal-${size}`, { 'modal-dialog-scrollable': scrollable }]"
      >
        <div class="modal-content" :style="cssVars">
          <div v-if="title" class="modal-header p-0">
            <h3 class="text-royal-blue mb-3">{{ title }}</h3>
          </div>
          <button
            v-if="closeButtonStyle !== 'none'"
            class="close"
            :class="[`close-${closeButtonStyle}`, { 'has-title': title }]"
            @click="close"
            type="button"
            data-bs-dismiss="modal"
            aria-label="close"
          >
            <IconDefault
              :width="23"
              :height="23"
              path="modal-close-default"
              v-if="closeButtonStyle === 'default'"
            />
            <IconDefault
              :width="23"
              :height="23"
              path="modal-close-default-white"
              v-else-if="closeButtonStyle === 'default-white'"
            />
            <IconDefault :width="50" :height="50" path="modal-close-outside" v-else />
          </button>
          <div class="modal-body">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted, onUnmounted } from '@nuxtjs/composition-api';
import IconDefault from '@/components/icon/Default';
import { getFocusableElements } from '@/utils/accessibility';

export default defineComponent({
  components: {
    IconDefault,
  },
  props: {
    size: {
      type: String,
      required: false,
      default: 'md',
      validator: (v) => ['sm', 'md', 'lg', 'xl'].indexOf(v) !== -1,
    },
    title: {
      type: String,
      default: null,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    closeButtonStyle: {
      type: String,
      required: false,
      default: 'default',
      validator: (v) => ['default', 'default-white', 'outside', 'none'].indexOf(v) !== -1,
    },
    modalPadding: {
      type: String,
      default: '20px',
      required: false,
    },
    modalHeight: {
      type: String,
      default: 'auto',
      required: false,
    },
    backgroundColor: {
      type: String,
      default: '#fff',
      required: false,
    },
  },
  setup(props, { emit }) {
    const modalRef = ref(null);

    const cssVars = computed(() => ({
      '--modal-padding': props.modalPadding,
      '--background-color': props.backgroundColor,
      '--modal-height': props.modalHeight,
    }));

    const close = () => {
      emit('close');
    };

    const returnFocusElement = ref(null);
    const firstFocusableEl = ref(null);
    const lastFocusableEl = ref(null);

    const handleKeyboard = (e) => {
      if (e.key === 'Tab' || e.keyCode === 9) {
        if (e.shiftKey) {
          /* shift + tab */ if (document.activeElement === firstFocusableEl.value) {
            lastFocusableEl.value.focus();
            e.preventDefault();
          }
        } /* tab */ else if (document.activeElement === lastFocusableEl.value) {
          firstFocusableEl.value.focus();
          e.preventDefault();
        }
      }
      /* esc */
      if (e.keyCode === 27) {
        close();
      }
    };

    onMounted(() => {
      // Get the last active/focused element to return to after modal closed
      const { activeElement } = document;
      if (activeElement) {
        // Check if the element is inside a dropdown that wont be visible/allow focus
        const parent = activeElement.closest('.dropstart');
        if (parent) {
          const triggerElement = parent.querySelector('button');
          if (triggerElement) {
            returnFocusElement.value = triggerElement;
          }
        }
        if (returnFocusElement.value === null) {
          returnFocusElement.value = activeElement;
        }
      }

      const focusableEls = getFocusableElements(null, modalRef.value);

      [firstFocusableEl.value] = focusableEls;
      lastFocusableEl.value = focusableEls[focusableEls.length - 1];

      modalRef.value.addEventListener('keydown', handleKeyboard);

      modalRef.value.focus();
    });

    onUnmounted(() => {
      // Return focus to the initiating element if set
      if (returnFocusElement && returnFocusElement.value) {
        returnFocusElement.value.focus();
        returnFocusElement.value = null;
      }
      // Remove event listeners
      modalRef.value.removeEventListener('keydown', handleKeyboard);
    });

    return {
      modalRef,
      cssVars,
      close,
    };
  },
});
</script>

<style scoped lang="scss">
.modal-body {
  padding: var(--modal-padding);
}

.modal-dialog-scrollable {
  .modal-body {
    overflow: auto;
  }
}

.modal-content {
  padding: var(--modal-padding);
  background-color: var(--background-color);
  height: var(--modal-height);
  overflow: initial;
  border-radius: 16px;

  .modal-header {
    border: 0;
  }

  .btn {
    width: fit-content;
  }
}

.close {
  position: absolute;
  border: none;
  z-index: 999;
}

.close-default,
.close-default-white {
  right: 8px;
  top: 10px;
  background: var(--background-color);

  &.has-title {
    right: 15px;
    top: 20px;
  }
}
.close-default-white {
  background: transparent;
}

.close-outside {
  right: -30px;
  top: -30px;
  background: none;
  box-shadow: none;
}

::v-deep {
  .block {
    background-color: darken($cloud-blue, 5%);
    padding: 15px 15px 5px;
    border-radius: 10px;
    margin-bottom: 10px;
    height: 100%;
  }
}
</style>
