<template>
  <GlobalLayoutWrapper class="business-page">
    <div
      v-if="$store.state.ui.showSidebar"
      class="backdrop"
      :class="userType"
      @click="toggleSideNav"
    ></div>

    <Navigation />

    <main role="main" tabindex="-1" id="main-content">
      <Nuxt />
    </main>

    <Footer role="contentinfo"></Footer>
  </GlobalLayoutWrapper>
</template>

<script>
import { defineComponent, useStore, computed } from '@nuxtjs/composition-api';
import Navigation from '@/components/root/Navigation';
import Footer from '@/components/root/Footer';
import GlobalLayoutWrapper from '@/components/layout/GlobalLayoutWrapper';
import useAuth from '@/hooks/auth';

export default defineComponent({
  components: {
    Navigation,
    Footer,
    GlobalLayoutWrapper,
  },
  setup() {
    const store = useStore();
    const { user } = useAuth();

    const userType = computed(() => user.value.role_type || null);

    const toggleSideNav = () => {
      store.dispatch('ui/toggleSidebar');
    };

    return { toggleSideNav, userType };
  },
});
</script>

<style lang="scss" scoped>
main {
  min-height: calc(100vh - 149px);
}

.navigation {
  position: relative;
  z-index: 1000;
}

.backdrop {
  opacity: 0.5;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &.student {
    background-color: #000;
  }
  &.teacher {
    background-color: transparent;
  }
}
</style>
