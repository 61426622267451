<template>
  <GlobalLayoutWrapper>
    <div class="website-dark">
      <transition name="fade" mode="out-in">
        <main tabindex="-1" id="main-content">
          <Nuxt />
        </main>
      </transition>
    </div>
  </GlobalLayoutWrapper>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import GlobalLayoutWrapper from '@/components/layout/GlobalLayoutWrapper';

export default defineComponent({
  components: {
    GlobalLayoutWrapper,
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
body {
  .website-dark {
    overflow: hidden;
    position: relative;
    background: linear-gradient(291.35deg, #01223a 64.05%, #0075ff 163.44%) !important;
    min-height: calc(100vh);
    ::v-deep div {
      @include website-dark;
    }
  }
}
</style>
