import { onGlobalSetup, useContext, useStore, onBeforeMount } from '@nuxtjs/composition-api';
import setupLocalizationForSchool from '@/utils/setupLocalizationForSchool';

export default () => {
  onGlobalSetup(() => {
    const { app } = useContext();
    const store = useStore();

    onBeforeMount(() => {
      if (!store.state.school) {
        return;
      }

      setupLocalizationForSchool(app, store.state.school);
    });
  });
};
