<template>
  <footer>
    <div class="footer d-flex container-fluid px-0">
      <div class="container align-self-center">
        <div class="row mx-n1 align-items-center">
          <div class="col-4">
            <NuxtLink v-if="userType === 'student'" to="/sitemap"> Sitemap </NuxtLink>
          </div>
          <div class="col-4 text-center">
            <div v-if="clusterLogoPath" class="d-flex justify-content-center">
              <div class="cluster-logo-container">
                <img :src="clusterLogoPath" class="img-fluid m-h" alt="" role="presentation" />
              </div>
            </div>
            <p v-else class="mb-0">{{ year }} &copy; {{ tPlatform('name') }}</p>
          </div>
          <div class="col-4 text-end d-none d-lg-block">
            <p v-if="userType === 'student'" class="mb-0">
              "A happy life is just a series of happy days"
            </p>
            <NuxtLink v-else :to="{ name: 'terms-policy-website' }" class="text-decoration-none"
              >Terms & Privacy Policy</NuxtLink
            >
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { defineComponent, useStore, computed } from '@nuxtjs/composition-api';
import useI18n from '@/hooks/i18n';

export default defineComponent({
  name: 'footer-component',
  props: {
    userType: {
      type: String,
      required: false,
    },
  },
  setup() {
    const year = new Date().getFullYear();
    const store = useStore();

    const { tPlatform } = useI18n();

    const clusterLogoPath = computed(
      () => store.getters?.school?.school_cluster?.logo_footer_file_path || false,
    );

    return { year, clusterLogoPath, tPlatform };
  },
});
</script>

<style lang="scss" scoped>
.footer {
  height: 56px;
  background: $primary-1;

  p,
  a {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
  }

  .cluster-logo-container {
    border-radius: 12px;

    img {
      max-height: 55px;
    }
  }
}
</style>
