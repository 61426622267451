import set from 'lodash/set';

export default (app, school) => {
  if (school?.country?.locale) {
    app.i18n.setLocale(school?.country?.locale);
  }

  if (school?.localization_overrides) {
    school?.localization_overrides.forEach((override) => {
      set(app.i18n._vm.messages, `${override.locale}.${override.key}`, override.value);
    });
  }
};
