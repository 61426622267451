<template>
  <div class="mobile-nav d-flex d-lg-none flex-column">
    <MobileNavLeaf @click="hideMobileNav()" :menuItem="loginMenuItem" />
    <div class="divider"></div>
    <MobileNavMenu
      :menuItem="rootMenuItem"
      v-for="rootMenuItem in menuItems"
      @click="toggleMenuItemState(rootMenuItem.slug, 0)"
      :isActive="isMenuItemActive(rootMenuItem.slug, 0)"
      :key="`mobile-root-menu-item-${rootMenuItem.id}`"
    >
      <div class="ps-3" v-if="isMenuItemActive(rootMenuItem.slug, 0)">
        <div
          :key="`mobile-level-one-nav-${levelOneMenuItem.id}`"
          class="nav-item"
          v-for="levelOneMenuItem in rootMenuItem.children"
        >
          <MobileNavMenu
            @click="toggleMenuItemState(levelOneMenuItem.slug, 1)"
            :isActive="isMenuItemActive(levelOneMenuItem.slug, 1)"
            v-if="levelOneMenuItem.instance_type === 'sub-menu'"
            :menuItem="levelOneMenuItem"
          >
            <div class="ps-3" v-if="isMenuItemActive(levelOneMenuItem.slug, 1)">
              <div
                :key="`mobile-level-two-nav-${levelTwoMenuItem.id}`"
                class="nav-item"
                v-for="levelTwoMenuItem in levelOneMenuItem.children"
              >
                <MobileNavMenu
                  @click="toggleMenuItemState(levelTwoMenuItem.slug, 2)"
                  :isActive="isMenuItemActive(levelTwoMenuItem.slug, 2)"
                  v-if="levelTwoMenuItem.instance_type === 'sub-menu'"
                  :menuItem="levelTwoMenuItem"
                >
                  <div class="ps-3" v-if="isMenuItemActive(levelTwoMenuItem.slug, 2)">
                    <div
                      :key="`mobile-level-three-nav-${levelThreeMenuItem.id}`"
                      class="nav-item"
                      v-for="levelThreeMenuItem in levelTwoMenuItem.children"
                    >
                      <MobileNavLeaf @click="hideMobileNav()" :menuItem="levelThreeMenuItem" />
                    </div>
                  </div>
                </MobileNavMenu>
                <MobileNavLeaf @click="hideMobileNav()" v-else :menuItem="levelTwoMenuItem" />
              </div>
            </div>
          </MobileNavMenu>
          <MobileNavLeaf @click="hideMobileNav()" v-else :menuItem="levelOneMenuItem" />
        </div>
      </div>
      <div class="divider"></div>
    </MobileNavMenu>
  </div>
</template>

<script>
import { defineComponent, useContext, computed } from '@nuxtjs/composition-api';
import useSchoolWebsiteNavigation from '@/hooks/schoolWebsiteNavigation';
import MobileNavMenu from '@/components/schoolWebsite/layout/navigation/mobile/NavMenu';
import MobileNavLeaf from '@/components/schoolWebsite/layout/navigation/mobile/NavLeaf';

export default defineComponent({
  components: {
    MobileNavMenu,
    MobileNavLeaf,
  },
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $auth } = useContext();

    const {
      schoolWebsiteActiveNavSlugs,
      isMenuItemActive,
      updateMenuItemState,
      toggleMenuItemState,
      clearActiveNavSlugs,
      selectedLevelZeroItem,
      selectedLevelOneItem,
      selectedLevelTwoItem,
      activeLevel,
    } = useSchoolWebsiteNavigation();

    const hideMobileNav = () => {
      clearActiveNavSlugs();
      emit('hide-mobile-nav');
    };

    /* eslint-disable no-confusing-arrow,indent */
    const loginMenuItem = computed(() =>
      $auth.user
        ? {
            fullSlug: 'dashboard',
            instance_type: 'fake-menu-item',
            text: 'Secure Area',
            instance: {
              url: '/dashboard',
            },
          }
        : {
            fullSlug: 'login',
            instance_type: 'fake-menu-item',
            text: 'Login',
            instance: {
              url: '/login',
            },
          },
    );
    /* eslint-enable no-confusing-arrow,indent */

    return {
      loginMenuItem,
      isMenuItemActive,
      updateMenuItemState,
      schoolWebsiteActiveNavSlugs,
      toggleMenuItemState,
      clearActiveNavSlugs,
      selectedLevelZeroItem,
      selectedLevelOneItem,
      selectedLevelTwoItem,
      activeLevel,
      hideMobileNav,
    };
  },
});
</script>

<style lang="scss" scoped>
.divider {
  opacity: 0.5;
  height: 1px;
  background-color: white;
}
.mobile-nav {
  padding: 30px 20px;
  background-color: $school-theme-color;
  color: white;
  border-bottom: 1px solid white;
}
</style>
