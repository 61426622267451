<template>
  <div class="footer d-flex container-fluid px-0 py-3">
    <div class="container align-self-center">
      <div class="row">
        <p class="lh-1 px-1 text-justify">
          <small>
            We acknowledge the Traditional Owners and Custodians of the land on which we live and
            work, and we pay our deepest respects to Elders past, present and emerging. We recognise
            the diverse skills and talents of First Nations peoples and aspire to create an
            inclusive career development platform that celebrates cultural wisdom, nurtures
            aspirations, and supports the journey towards fulfilling careers.
          </small>
        </p>
      </div>
      <div class="row flex-sm-row-reverse mx-n1 align-items-center">
        <div class="col-sm-6 text-center text-sm-end px-1">
          <p class="mb-0">
            <NuxtLink to="/terms-policy" class="text-white text-decoration-none"
              >Terms & Privacy Policy</NuxtLink
            >
          </p>
        </div>
        <div class="col-sm-6 text-center text-sm-start px-1">
          <p class="mb-0">{{ year }} &copy; Career Tools</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'footer-component',
  setup() {
    const year = new Date().getFullYear();
    return { year };
  },
});
</script>

<style lang="scss" scoped>
.footer {
  background: $business;

  p {
    color: #fff !important;
    font-size: 14px;
  }
}
.text-justify {
  text-align: justify;
}
@media (max-width: 767px) {
  small {
    font-size: 11px;
  }
}
</style>
