/* eslint-disable import/no-extraneous-dependencies */
import 'cross-fetch/polyfill';
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client/core';
import { getStates } from '@vue/apollo-ssr';
import { createUploadLink } from 'apollo-upload-client';
import { from } from 'apollo-link';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { logErrorMessages } from '@vue/apollo-util';
import VueApollo from 'vue-apollo';
import buildInfo from '@/config/buildInfo';
import dayjs from '@/utils/dayjs';

export default ({ app, $config, nuxtState: currentNuxtState, beforeSerialize, isDev, req }) => {
  const checkResponseBuildHeader = (responseBuildHeader) => {
    if (!responseBuildHeader || !buildInfo.build) {
      return;
    }

    if (responseBuildHeader !== buildInfo.build) {
      app.store.commit('setBuildMismatch', true);
    }
  };

  const checkBuild = new ApolloLink((operation, forward) =>
    forward(operation).map((data) => {
      const buildHeader = operation.getContext().response.headers.get('ctx-build');
      checkResponseBuildHeader(buildHeader);
      return data;
    }),
  );

  /* eslint-disable indent */
  const authLink = setContext(async (request, { headers }) => {
    const sessionToken = app.$cookies.get('careertools_session');

    const userAgent = process.server ? req.headers['user-agent'] : navigator.userAgent;

    return {
      headers: {
        ...headers,
        'X-Forwarded-For': app.store.state.clientIp,
        'User-Agent': userAgent,
        ...(process.server &&
          sessionToken && {
            cookie: app.$cookies.nodeCookie.serialize('careertools_session', sessionToken),
          }),
        origin: $config.originDomain,
      },
    };
  });
  /* eslint-enable indent */

  const impersonateLink = setContext(async (request, { headers }) => {
    const impersonateUserId = app.$cookies.get('auth.impersonate.user_id');
    const impersonateRoleType = app.$cookies.get('auth.impersonate.role_type');
    return {
      headers: {
        ...headers,
        ...(impersonateUserId && { 'Impersonate-User-Id': impersonateUserId }),
        ...(impersonateRoleType && { 'Impersonate-Role-Type': impersonateRoleType }),
      },
    };
  });

  const refreshAuthExpiresLink = setContext(async () => {
    const expires = app.$cookies.get('auth.expires');
    const roleType = app.$cookies.get('auth.role_type');

    if (!expires || !roleType) {
      return;
    }

    const newExpires =
      roleType === 'teacher'
        ? dayjs().unix() + app.store.state.expireTimeTeacher
        : dayjs().unix() + app.store.state.expireTimeStudent;
    app.$cookies.set('auth.expires', newExpires, { domain: $config.cookieDomain, path: '/' });
  });

  const errorLink = onError((errors) => {
    if (errors?.networkError?.statusCode === 429) {
      app.store.commit('setRateLimited', true);
    }

    if (errors?.graphQLErrors?.[0]?.message === 'Unauthenticated.' && app.$auth.user) {
      app.store.commit('setLoggingOut', true);
      app.$auth.reset();
    }

    if (isDev) {
      logErrorMessages(errors);
    }
  });

  // Details here for createUploadLink that allows GraphQL multipart requests
  // https://github.com/jaydenseric/apollo-upload-client#function-createuploadlink
  const httpLink = createUploadLink({
    uri: `${$config.laravelApiUrl}/graphql`,
  });

  const cache = new InMemoryCache({
    typePolicies: {
      CustomEmailList: {
        keyFields: ['id', 'type'],
      },
      Post: {
        fields: {
          author: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  });

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
    query: {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  };

  const apolloClient = new ApolloClient({
    link: from([
      authLink,
      impersonateLink,
      refreshAuthExpiresLink,
      checkBuild,
      errorLink,
      httpLink,
    ]),
    cache,
    connectToDevTools: process.env.NODE_ENV !== 'production',
    ...(process.server ? { ssrMode: true, ssrForceFetchDelay: 100 } : {}),
    defaultOptions,
  });

  if (process.client && currentNuxtState.apollo) {
    cache.restore(currentNuxtState.apollo.default);
  }

  const apolloProvider = new VueApollo({ defaultClient: apolloClient });

  apolloProvider.defaultClient.disableNetworkFetches = true;

  // eslint-disable-next-line no-param-reassign
  app.apolloProvider = apolloProvider;

  if (!process.server) {
    return;
  }

  beforeSerialize((nuxtState) => {
    const states = getStates({
      default: apolloClient,
    });

    // eslint-disable-next-line no-param-reassign
    nuxtState.apollo = states;
  });
};
