<template>
  <component
    :is="to ? 'NuxtLink' : 'button'"
    :to="to"
    class="nav-button no-style shadow-none d-flex align-items-center py-2 px-4"
    :class="`nav-button-${color} ${buttonClasses}`"
    type="button"
    :id="name"
    @click="$emit('click', $event)"
  >
    <div
      class="d-flex align-items-center justify-content-between w-100"
      :class="showRedDot && 'red-dot'"
    >
      <div class="d-flex align-items-center">
        <slot name="icon">
          <IconDefault class="me-3" :width="30" :height="30" :path="iconPath" />
        </slot>
        <div class="button-text text-end" :class="!alwaysShowText && 'd-none d-lg-block'">
          <slot></slot>
        </div>
      </div>
      <div class="d-flex align-items-center pe-2" v-if="isToggle">
        <ChevronDownIcon :width="15" :height="15" v-if="!toggled" />
        <ChevronUpIcon :width="15" :height="15" v-else />
      </div>
    </div>
  </component>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import IconDefault from '@/components/icon/Default';
import ChevronDownIcon from '@/components/icon/inline/ChevronDown';
import ChevronUpIcon from '@/components/icon/inline/ChevronUp';

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: false,
    },
    iconPath: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'light',
      validator: (v) => ['light', 'dark'].indexOf(v) !== -1,
    },
    buttonClasses: {
      type: String,
      required: false,
      default: '',
    },
    alwaysShowText: {
      type: Boolean,
      default: false,
    },
    isToggle: {
      type: Boolean,
      default: false,
    },
    toggled: {
      type: Boolean,
      required: false,
    },
    showRedDot: {
      type: Boolean,
      default: false,
    },
  },
  components: { IconDefault, ChevronDownIcon, ChevronUpIcon },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.nav-button-light {
  color: white;
}
.nav-button-dark {
  color: $primary-1;
}

.button-text {
  @include font-primary(500);
  font-size: 14px;
}
.nav-button {
  min-height: 44px;
  min-width: 44px;
  padding: 0px;
}

.red-dot::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $cerise;
  position: absolute;
  top: 5px;
  left: 40px;
}
</style>
