<template>
  <header>
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid nav-container">
        <NuxtLink to="/">
          <img class="logo" :src="`${$config.publicObjectStorageUrl}/${school.logo_full}`" />
        </NuxtLink>
        <button class="navbar-toggler me-3" type="button" @click="mobileToggle = !mobileToggle">
          <div class="navbar-toggler-icon menu-icon" :class="{ active: mobileToggle }">
            <span class="line-1"></span>
            <span class="line-2"></span>
            <span class="line-3"></span>
          </div>
        </button>
        <DesktopNav
          v-if="!currentBreakpoint || ['xxl', 'xl', 'lg'].includes(currentBreakpoint)"
          :menuItems="navMenuItems"
        />
      </div>
    </nav>
    <client-only>
      <MobileNav
        v-if="
          !currentBreakpoint || (['md', 'sm', 'xs'].includes(currentBreakpoint) && mobileToggle)
        "
        @hide-mobile-nav="mobileToggle = false"
        :menuItems="navMenuItems"
      />
    </client-only>
  </header>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import useSchool from '@/hooks/school';
import DesktopNav from '@/components/schoolWebsite/layout/navigation/desktop/Nav';
import MobileNav from '@/components/schoolWebsite/layout/navigation/mobile/Nav';
import useBreakpoint from '@/hooks/breakpoint';

export default defineComponent({
  components: { DesktopNav, MobileNav },
  setup() {
    const mobileToggle = ref(false);
    const { school, navMenuItems } = useSchool();

    const currentBreakpoint = process.client ? useBreakpoint() : null;
    return { mobileToggle, school, navMenuItems, currentBreakpoint };
  },
});
</script>

<style lang="scss" scoped>
.nav-container {
  @include m-xl {
    padding-left: 5%;
    padding-right: 5%;
  }
  @include m-xxl {
    padding-left: 10%;
    padding-right: 10%;
  }
}
header {
  background-color: white;
  .logo {
    height: 70px;

    @include m-large {
      height: 105px;
    }
  }
}

.navbar-collapse {
  background-color: $school-theme-color;
}

@include m-large {
  .navbar-collapse {
    background-color: $white;
  }
}

// Mobile nav toggle
.navbar-toggler {
  border: solid 1px #ddd;
  border-radius: 14px;
  padding: 8px 12px;
  background: $school-theme-color;

  &:focus {
    box-shadow: none;
  }

  .menu-icon {
    display: grid;
    place-items: center;
    height: 25px;
    width: 23px;
    position: relative;
    cursor: pointer;

    & > span {
      width: 23px;
      height: 4px;
      background: $white;
      display: block;
      transition: all 0.3s ease-in-out;
      border-radius: 4px;
    }

    &.active span:nth-child(1) {
      transform-origin: center center;
      transform: rotate(-45deg) translate(-8px, 4px);
      width: 28px;
    }

    &.active span:nth-child(2) {
      transform: translateX(10px);
      opacity: 0;
    }

    &.active span:nth-child(3) {
      transform-origin: center center;
      transform: rotate(45deg) translate(-7px, -4px);
      width: 28px;
    }

    .line-1 {
      width: 23px;
      justify-self: end;
    }

    .line-2 {
      width: 23px;
      justify-self: end;
    }
  }
}

// Desktop navigation
.navbar {
  padding-top: 0;
  @include m-large {
    padding-top: 0.5rem;
  }
}
</style>
