import { computed, useStore } from '@nuxtjs/composition-api';
import useI18n from '@/hooks/i18n';
import useSchool from '@/hooks/school';

export default function usePageTitle() {
  const store = useStore();
  const { tPlatform } = useI18n();
  const { school } = useSchool();

  const pageTitle = computed(() => {
    const storePageTitle = store.state.pageTitle;

    let _pageTitle = '';

    if (storePageTitle) {
      _pageTitle = `${storePageTitle} - `;
    }

    _pageTitle += school.value ? school.value.name : tPlatform('name');

    return _pageTitle;
  });

  const setPageTitle = (newPageTitle) => {
    store.commit('setPageTitle', newPageTitle);
  };

  return { pageTitle, setPageTitle };
}
