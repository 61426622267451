import { useContext } from '@nuxtjs/composition-api';
import usePlatform from '@/hooks/platform';

export default function useI18n() {
  const { store } = useContext();
  const { $i18n } = store;
  const { platform } = usePlatform();

  const t = (key, value) => {
    return $i18n.t(key, value);
  };

  const tc = (key, choice) => {
    return $i18n.tc(key, choice);
  };

  const tWithDefault = (key, fallback) => {
    const localeString = t(key);

    if (localeString && localeString !== key) {
      return localeString;
    }

    return fallback;
  };

  const tPlatform = (key, params) => {
    return $i18n.t(`platform.${platform.value}.${key}`, params);
  };

  return { t, tc, tWithDefault, tPlatform };
}

export function getLocalisedStringWithFallback(key, fallback) {
  const { t } = useI18n();

  const localeString = t(key);

  if (localeString && localeString !== key) {
    return localeString;
  }

  return fallback;
}

export function getLocalisedCareerPassField(field, fieldName, fieldParent = '') {
  const { t } = useI18n();

  // If there is a localised string, use it
  const key = `careerPass.${field.code}.${fieldName}`;

  const localeString = t(key);

  if (localeString && localeString !== key) {
    return localeString;
  }

  // If not: if there is a field parent, append it to look for the default string
  if (fieldParent) {
    return field[fieldParent][fieldName];
  }

  // If not: return the default string from the root of the field
  return field[fieldName];
}
