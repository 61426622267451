import useBraze from '@/hooks/braze';
import useMixpanel from '@/hooks/mixpanel';
import { onGlobalSetup, provide } from '@nuxtjs/composition-api';

const getApplicationSource = ($config) => {
  const source = $config.appName;

  if ($config.env_real === 'production') {
    return source;
  }

  return `${source}-${$config.env_real}`;
};

export default ({ $config, route, app, $cookies }) => {
  const applicationSource = getApplicationSource($config);
  const isAdminImpersonating = $cookies.get('auth.is_impersonating');

  const sanitizeData = (data) => {
    const sanitized = data;

    Object.keys(sanitized).map((k) => {
      if (sanitized[k] === null) {
        sanitized[k] = '';
      } else if (['number', 'boolean'].includes(typeof sanitized[k])) {
        sanitized[k] = sanitized[k].toString();
      }
      return true;
    });

    return sanitized;
  };

  const setDefaultData = (user, data) => {
    const baseData = data || {};

    if (!user) return { page_name: route.name, ...baseData };

    if (baseData?.label && typeof data.label === 'string') {
      baseData.label = data.label.toLowerCase();
    }

    if (!baseData.user_email) {
      baseData.user_email = user.email;
    }

    baseData.platform_source = applicationSource;

    return sanitizeData({
      ...baseData,
      page_name: route.name,
      user_id: user.id,
      guid: user.guid,
      authuser_type: user?.currentOrgRole?.role_type || null,
      authuser_sub_type: user?.currentOrgRole?.role || null,
      authuser_school_id: user?.currentOrgRole?.org_id || null,
      authuser_year: user?.currentOrgRole?.year || null,
    });
  };

  const initGTagManager = () => {
    window.platform_source = applicationSource;

    const GTM_CODE = $config.gtmId;

    /* eslint-disable */
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', GTM_CODE);
  }

  const analyticsProviders = [];

  const analyticsService = {
    init: () => {
      const braze = useBraze();
      const mixpanel = useMixpanel();

      analyticsProviders.push(braze);
      analyticsProviders.push(mixpanel);
    },
    track: (eventName, properties = {}) => {
      if (isAdminImpersonating) {
        return;
      }

      const { user } = app.$auth;

      const eventData = setDefaultData(user, properties);

      analyticsProviders.forEach((provider) => {
        if (provider.getSubscribedEvents().includes(eventName)) {
          try {
            provider.track(eventName, eventData);
          } catch (error) {
            console.error(error);
          }
        }
      });
    },
  };

  onGlobalSetup(() => {
    initGTagManager();
    analyticsService.init();
    provide('analyticsService', analyticsService);
  });
};
