export const findMenuItemByFullSlug = (fullSlug, menuItems) => {
  for (let index = 0; index < menuItems.length; index++) {
    const menuItem = menuItems[index];

    if (menuItem.instance_type === 'sub-menu') {
      const childItem = findMenuItemByFullSlug(fullSlug, menuItem.children);
      if (childItem) {
        return childItem;
      }
    }

    if (menuItem.fullSlug === fullSlug) {
      return menuItem;
    }
  }

  return null;
};

export const findMenuItemByComponentName = (name, menuItems) => {
  for (let index = 0; index < menuItems.length; index++) {
    const menuItem = menuItems[index];
    if (menuItem.instance_type === 'sub-menu') {
      const childItem = findMenuItemByComponentName(name, menuItem.children);
      if (childItem) {
        return childItem;
      }
    }
    if (menuItem?.instance?.component_name === name) {
      return menuItem;
    }
  }

  return null;
};

export const removeDuplicateMenuItems = (menuItems) => {
  const check = {};
  return menuItems
    .reduce((carry, mi) => {
      if (mi.slug) {
        const prevMi = carry[check[mi.slug]];
        if (prevMi) {
          const i1 = prevMi.instance;
          const i2 = mi.instance;
          // prioritise school content > cluster content > state content | component | sub-menu
          const prevInstancePri = !!i1.school_cluster_id + !!i1.school_id * 2;
          const nextInstancePri = !!i2.school_cluster_id + !!i2.school_id * 2;
          if (prevInstancePri >= nextInstancePri) {
            // keep the previous instance discard that latest one
            return carry;
          }
          // remove the previous instance with same slug
          carry[check[mi.slug]] = null;
        }
        check[mi.slug] = carry.length;
      }
      carry.push(mi);
      return carry;
    }, [])
    .filter((mi) => mi);
};

export const recursiveConstructMenuItems = (
  config,
  allItems,
  menuItem,
  parentFullSlug,
  parentBreadcrumbs,
) => {
  const fullSlug = parentFullSlug ? `${parentFullSlug}/${menuItem.slug}` : menuItem.slug;
  const breadcrumbs = parentBreadcrumbs ? [...parentBreadcrumbs, menuItem.text] : [menuItem.text];

  const children = removeDuplicateMenuItems(
    allItems
      .filter((mi) => mi.parent_id === menuItem.id)
      .map((mi) => recursiveConstructMenuItems(config, allItems, mi, fullSlug, breadcrumbs)),
  );

  return {
    ...menuItem,
    fullSlug: menuItem.instance_type !== 'external-link' ? fullSlug : null,
    slug: menuItem.instance_type !== 'external-link' ? menuItem.slug : null,
    breadcrumbs: menuItem.instance_type !== 'external-link' ? breadcrumbs : null,
    ...(menuItem.instance_type === 'sub-menu' && {
      children,
    }),
  };
};
