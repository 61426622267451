import range from 'lodash/range';

export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const capitalizeName = (name) => name.replace(/\b(\w)/g, (s) => s.toUpperCase());

export const formatStringToTitle = (string) =>
  capitalizeName(string.replace(/\.[\w]+/g, '').replace(/[-_]/g, ' '));

/**
 * Strip HTML tags, divide the text into words, return the first 'x' number of words
 */
export const truncateHtml = (input, count, useCharacters = false) => {
  if (!input || !count) {
    return null;
  }

  const strippedInput = input.replace(/<[^>]+>/g, ' ');

  if (useCharacters) {
    return strippedInput.slice(0, count);
  }

  const words = strippedInput.split(' ');
  return words.slice(0, count).join(' ');
};

export const getDestinationSurveyYearOptions = () => {
  const firstYear = 2021;
  const currentYear = new Date().getFullYear();
  return range(firstYear, currentYear + 1)
    .map((y) => ({
      value: y,
      label: `Graduated ${y}`,
    }))
    .reverse();
};

export const replaceLineBreaks = (value) => value.replaceAll('\n', '<br />');

export const getDestinationSurveyNotificationStatusDisplay = (status) =>
  ({
    'not-sent': 'Not sent',
    sending: 'Sending',
    sent: 'Sent',
    failed: 'Failed',
  }[status]);

/**
 * Return formatted string
 * Example: 'my-string' = My String
 * @param subType
 * @returns {string|*}
 */
export const formatRole = (subType) => {
  if (!subType) return '';

  // Uppercase first letter and replace - with space
  const label = subType.split('-');
  label.forEach((str, key) => {
    label[key] = str.charAt(0).toUpperCase() + str.slice(1);
  });

  return label.join(' ');
};

// Sub types can be found in the User model, must be kept in sync
export const getStudentRoles = (value) => {
  const subTypes = [
    { value: 'current', label: 'Current' },
    { value: 'exited', label: 'Exited' },
    { value: 'parent', label: 'Parent' },
    { value: 'other', label: 'Other' },
  ];

  const search = (text) => subTypes.filter((x) => x.value.includes(text));

  if (value) {
    const result = search(value);
    if (result.length) {
      return search(value)[0];
    }
  }

  return subTypes;
};

export const getTeacherRoles = (value) => {
  const subTypes = [
    { value: 'primary', label: 'Super administrator' },
    { value: 'careers-advisor', label: 'Careers advisor' },
    { value: 'teacher', label: 'Teacher' },
    { value: 'school-admin', label: 'School administrator' },
    { value: 'school-leader', label: 'School leader' },
    { value: 'vje-coordinator', label: 'VJE coordinator' },
    { value: 'secondary', label: 'Custom' },
  ];

  const search = (text) => subTypes.filter((x) => x.value.includes(text));

  if (value) {
    const result = search(value);
    if (result.length) {
      return search(value)[0];
    }
  }

  return subTypes;
};

export const getTeacherRoleName = (value) => {
  const subType = getTeacherRoles(value);
  return subType.label;
};

export const encodeObjectAsString = (obj) => {
  // btoa doesnt work on SSR
  if (process.server) {
    return Buffer.from(JSON.stringify(obj)).toString('base64');
  }

  return btoa(JSON.stringify(obj));
};
// export const decodeStringToObject = (str) => JSON.parse(atob(str));

export const base64Decode = (str) =>
  process.server ? Buffer.from(str, 'base64').toString() : atob(str);

export const decodeStringToObject = (str) => JSON.parse(base64Decode(str));

export const stateOptions = [
  { value: 'NSW', label: 'NSW' },
  { value: 'VIC', label: 'VIC' },
  { value: 'QLD', label: 'QLD' },
  { value: 'SA', label: 'SA' },
  { value: 'WA', label: 'WA' },
  { value: 'NT', label: 'NT' },
  { value: 'ACT', label: 'ACT' },
  { value: 'TAS', label: 'TAS' },
];

export const getHelpLink = (value) => {
  const helpLinks = {
    students: 'https://careertools.zendesk.com/hc/en-us/articles/4914254678681',
    'students-groups': 'https://careertools.zendesk.com/hc/en-us/articles/4415105962137',
    'students-add': 'https://careertools.zendesk.com/hc/en-us/articles/4588777625625',
    'students-edit-details': 'https://careertools.zendesk.com/hc/en-us/articles/4547372082585',
    'students-edit-password': 'https://careertools.zendesk.com/hc/en-us/articles/4737624059929',
    'students-edit-bulk': 'https://careertools.zendesk.com/hc/en-us/articles/16026925072409',
    'students-verifying': 'https://careertools.zendesk.com/hc/en-us/articles/12080124601625',
    'students-interactions-create':
      'https://careertools.zendesk.com/hc/en-us/articles/4415976370713',
    'students-delete': 'https://careertools.zendesk.com/hc/en-us/articles/5458196423193',
    'students-impersonate': 'https://careertools.zendesk.com/hc/en-us/articles/4737719921177',
    'students-management':
      'https://careertools.zendesk.com/hc/en-us/sections/4415010747929-Student-Management',
    'teachers-add': 'https://careertools.zendesk.com/hc/en-us/articles/4421900151705',
    'teachers-edit': 'https://careertools.zendesk.com/hc/en-us/articles/4556978872089',
    'teachers-account-roles': 'https://careertools.zendesk.com/hc/en-us/articles/13153899922841',
    'career-journeys-lesson-outlines':
      'https://careertools.zendesk.com/hc/en-us/articles/4414938522137',
    'career-journeys-manage': 'https://careertools.zendesk.com/hc/en-us/articles/4419387846553',
    'life-ready': 'https://careertools.zendesk.com/hc/en-us/articles/11621384678169',
    'all-my-own-work': 'https://careertools.zendesk.com/hc/en-us/articles/11597547471385',
    'career-plans': 'https://careertools.zendesk.com/hc/en-us/articles/4737779734425',
    'career-plans-search': 'https://careertools.zendesk.com/hc/en-us/articles/4419400268953',
    'career-plans-manage': 'https://careertools.zendesk.com/hc/en-us/articles/4419393788953',
    appointments: 'https://careertools.zendesk.com/hc/en-us/sections/4415005225113-Appointments',
    'appointments-blockouts': 'https://careertools.zendesk.com/hc/en-us/articles/4419372916633',
    'appointments-settings': 'https://careertools.zendesk.com/hc/en-us/articles/4419364774937',
    'appointments-calander-integration':
      'https://careertools.zendesk.com/hc/en-us/articles/12621366933785',
    'website-events-calander': 'https://careertools.zendesk.com/hc/en-us/articles/4573950591641',
    'website-newsletters': 'https://careertools.zendesk.com/hc/en-us/articles/6702386679193',
    'website-message-board': 'https://careertools.zendesk.com/hc/en-us/articles/4544913815193',
    'website-newsfeed': 'https://careertools.zendesk.com/hc/en-us/articles/4544932122521',
    'website-current-opportunities':
      'https://careertools.zendesk.com/hc/en-us/articles/4544835733401',
    'website-custom-pages': 'https://careertools.zendesk.com/hc/en-us/articles/4419368274713',
    'website-settings-widgets': 'https://careertools.zendesk.com/hc/en-us/articles/4419368379033',
    'email-sending': 'https://careertools.zendesk.com/hc/en-us/articles/4421864308505',
    'email-create-lists': 'https://careertools.zendesk.com/hc/en-us/articles/4737813141913',
    'destination-survey': 'https://careertools.zendesk.com/hc/en-us/articles/4416132466969',
    'destination-survey-sending-viewing':
      'https://careertools.zendesk.com/hc/en-us/articles/4694674705305',
    reporting: 'https://careertools.zendesk.com/hc/en-us/articles/4419368527513',
    'help-center': 'https://careertools.zendesk.com/hc/en-us/articles/4415342499481',
    resources: 'https://careertools.zendesk.com/hc/en-us/articles/13043523888665',
  };

  if (Object.prototype.hasOwnProperty.call(helpLinks, value)) {
    return helpLinks[value];
  }

  return false;
};

export const findOptionLabel = (options, optionValue) => {
  const option = options.find((o) => o.value === optionValue);

  if (!option) {
    return null;
  }

  return option.label;
};

export const orderFavouriteGroups = (groups, favouriteGroups) => {
  const favouriteIds = favouriteGroups.map((g) => g.id);
  const favourites = groups.filter((g) => favouriteIds.includes(g.id));
  const nonFavourites = groups.filter((g) => !favouriteIds.includes(g.id));

  return [...favourites, ...nonFavourites];
};
