<template>
  <li class="text-center mb-2">
    <NuxtLink :to="to" class="link">
      {{ text }}
    </NuxtLink>
  </li>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  components: {},
  props: {
    to: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
  },
  setup() {},
});
</script>

<style lang="scss" scoped>
.link {
  color: white;
  font-size: 12px;
}
</style>
