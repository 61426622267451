import useI18n from '@/hooks/i18n';

const useRecommendations = () => {
  const { t } = useI18n();

  const rolesRecommendations = {
    teacher: [
      {
        key: 'set-up-careers-website',
        title: 'Set up school careers website',
        description:
          "Select your website's theme colour, banner background image, banner message and banner video. You can also link any social media accounts and add your footer details.",
        action: {
          route: '/website/settings/general',
          text: 'Go to website settings',
        },
        isSelfMarked: true,
        requiredPermission: 'website',
        requiredSchoolPermission: 'website',
        primaryOnly: false,
      },
      {
        key: 'bulk-upload-students',
        title: 'Bulk upload students using CSV',
        description:
          'Add each year group easily using the bulk upload csv. Your students will receive a verification email and login prompt so they are ready to use Career Tools.',
        action: {
          route: '/students/bulk-manage/add',
          text: 'Upload Students',
        },
        isSelfMarked: false,
        requiredSchoolPermission: 'manual-accounts',
        requiredPermission: 'students-manage',
        primaryOnly: false,
      },
      {
        key: 'manage-career-journeys',
        title: 'Manage career journeys',
        description:
          'Toggle career journeys and even individual lessons within each journey on/off for different cohorts depending on what you would like students to access.',
        action: {
          route: '/program/career-journeys/manage',
          text: 'Update career journeys',
        },
        isSelfMarked: true,
        requiredPermission: 'career-journeys-manage',
        primaryOnly: false,
      },
      {
        key: 'add-teacher',
        title: 'Set-up another teacher',
        description:
          'Add your colleagues to Career Tools and assign the features you want them to access.',
        action: {
          route: '/teachers',
          text: 'Set-up teacher',
        },
        isSelfMarked: false,
        requiredSchoolPermission: 'manual-accounts',
        requiredPermission: null,
        primaryOnly: true,
      },
      {
        key: 'assign-activity',
        title: 'Assign an activity',
        description:
          'Activities you assign to students appear on their dashboard to help you and them get started on tasks quickly. Try it out!',
        action: {
          route: '/assignments/create',
          text: 'Assign activity',
        },
        isSelfMarked: false,
        requiredPermission: 'career-journeys-manage',
        primaryOnly: false,
      },
    ],

    student: [
      {
        key: 'complete-psp',
        title: t('psp.recommendation_title'),
        description:
          "Complete the personality and skills quiz, explore jobs and activate your action plan! Having a plan is an important part of career and life success. Your plan will continue to change and evolve but that's part of the process!",

        action: {
          route: '/copilot-quiz',
          text: `Start ${t('psp.title')}`,
        },
        isSelfMarked: false,
        requiredPermission: null,
        primaryOnly: false,
        requiredCopilotActivated: true,
      },
      {
        key: 'build-resume',
        title: 'Build your resume',
        description:
          'A resume is a a document summing up your skills and experiences in the workplace. Resumes are used when you are applying for jobs, courses or scholarships. You will need to tailor your resume to suit different needs. Give it a go!',
        action: {
          route: '/portfolio?new=resume',
          text: 'Start resume',
        },
        isSelfMarked: false,
        requiredPermission: null,
        primaryOnly: false,
      },
      {
        key: 'investigate-career',
        title: 'Investigate a career',
        description:
          "Are you feeling lost about you future goals and don't know where to begin? Maybe you want to know more about particular careers, such as the kinds of skills you will require for a job, or the amount of money you can earn. The career investigator is your best friend.",
        action: {
          route: '/career-investigator',
          text: 'Investigate career',
        },
        isSelfMarked: false,
        requiredPermission: null,
        primaryOnly: false,
      },
      {
        key: 'course-search',
        title: 'Course search',
        description:
          'Want to explore future course options? Go ahead, use the filters to find courses you might be interested in. When you find courses you like the sound of, shortlist them so they save to your profile.',
        action: {
          route: '/course-search',
          text: 'Shortlist a course',
        },
        isSelfMarked: false,
        requiredPermission: null,
        primaryOnly: false,
      },
      {
        key: 'update-wallet',
        title: `Update ${t('careerPass.title').toLowerCase()}`,
        description:
          "We live in a digital world. What's better than showcasing your learning and skills digitally? Nothing! Collate your evidence in this career wallet to help you get into your desired course or job.",
        action: {
          route: '/career-pass',
          text: `Start ${t('careerPass.title')}`,
        },
        isSelfMarked: false,
        requiredPermission: null,
        primaryOnly: false,
        requiredWalletActivated: true,
      },
    ],
  };

  /**
   * @param {*} role
   * @returns
   */
  const getRecommendations = (role) => {
    return rolesRecommendations[role] || [];
  };

  return { getRecommendations };
};

export default useRecommendations;
