<template>
  <div class="dropdown">
    <NavButton
      :name="name"
      :iconPath="buttonIconPath"
      class="dropdown-toggle"
      :class="buttonClasses"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      :showRedDot="showRedDot"
    >
      <template v-slot:icon>
        <slot name="icon"></slot>
      </template>
      {{ buttonText }}
    </NavButton>
    <div
      class="py-0 dropdown-menu dropdown-menu-end"
      :style="minWidth && { 'min-width': `${minWidth}px` }"
      :aria-labelledby="name"
    >
      <div class="caret"></div>
      <div class="d-flex">
        <div class="w-100">
          <slot name="dropdown-content"></slot>
        </div>
        <div v-if="imagePath" class="d-none d-lg-flex justify-content-center p-4">
          <IconDefault :path="imagePath" :width="imageWidth" :height="imageHeight" /> -
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import NavButton from '@/components/layout/navigation/NavButton';
import IconDefault from '@/components/icon/Default';

export default defineComponent({
  components: {
    NavButton,
    IconDefault,
  },
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    buttonIconPath: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    buttonClasses: {
      type: String,
      required: false,
    },
    imagePath: {
      type: String,
      required: false,
    },
    imageWidth: {
      type: Number,
      required: false,
    },
    imageHeight: {
      type: Number,
      required: false,
    },
    minWidth: {
      type: Number,
      required: false,
    },
    showRedDot: {
      type: Boolean,
      default: false,
    },
  },
  setup(...[, { emit }]) {
    const click = () => {
      emit('click');
    };
    return { click };
  },
});
</script>

<style lang="scss" scoped>
.dropdown-toggle::after {
  // Remove default bootstrap caret
  display: none;
}

.dropdown-menu {
  border-radius: 10px;
}

.caret {
  width: 0;
  height: 0;
  display: inline-block;
  border: toRem(5) solid transparent;
  border-bottom-color: $white;
  position: absolute;
  top: -10px;
  right: 23px;
}
</style>
