export const ROLE_STUDENT_CURRENT = 'current';
export const ROLE_STUDENT_EXITED = 'exited';
export const ROLE_STUDENT_PARENT = 'parent';
export const ROLE_STUDENT_OTHER = 'other';

export const ROLE_TEACHER_PRIMARY = 'primary'; // admin
export const ROLE_TEACHER_SECONDARY = 'secondary'; // custom
export const ROLE_TEACHER_CAREERS_ADVISOR = 'careers-advisor';
export const ROLE_TEACHER_TEACHER = 'teacher';
export const ROLE_TEACHER_SCHOOL_ADMIN = 'school-admin';
export const ROLE_TEACHER_SCHOOL_LEADER = 'school-leader';
export const ROLE_TEACHER_VJE_COORDINATOR = 'vje-coordinator';
