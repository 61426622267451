<template>
  <NavigationDropdownListMenu
    :highlightOnHover="highlightOnHover"
    name="role-switcher"
    iconPath="default/switch-school"
  >
    <template v-slot:button-text>Switch School</template>
    <template v-slot:menu>
      <li v-for="(role, index) in schoolRoles" :key="`role-switch-${role.id}`">
        <RoleSwitcherRole
          :index="index"
          :role="role"
          :isActive="role.id === user.currentOrgRole.id"
          :highlightOnHover="highlightOnHover"
        />
      </li>
    </template>
  </NavigationDropdownListMenu>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import NavigationDropdownListMenu from '@/components/layout/navigation/DropdownListMenu';
import RoleSwitcherRole from '@/components/layout/navigation/RoleSwitcherRole';
import useAuth from '@/hooks/auth';

export default defineComponent({
  props: {
    highlightOnHover: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NavigationDropdownListMenu,
    RoleSwitcherRole,
  },
  setup() {
    const { user } = useAuth();
    const schoolRoles = computed(() => {
      return user.value.schoolRoles;
    });
    return { user, schoolRoles };
  },
});
</script>

<style lang="scss" scoped></style>
