<template>
  <GlobalLayoutWrapper>
    <SchoolWebsiteNavigationTop />
    <div class="container-fluid px-0 school-website">
      <main tabindex="-1" id="main-content">
        <Nuxt />
      </main>
    </div>
    <div class="footer">
      <SchoolWebsiteFooter />
    </div>
  </GlobalLayoutWrapper>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import SchoolWebsiteNavigationTop from '@/components/schoolWebsite/layout/navigation/Top';
import SchoolWebsiteFooter from '@/components/schoolWebsite/layout/Footer';
import GlobalLayoutWrapper from '@/components/layout/GlobalLayoutWrapper';

export default defineComponent({
  components: {
    SchoolWebsiteNavigationTop,
    SchoolWebsiteFooter,
    GlobalLayoutWrapper,
  },
  head: {},
  setup() {
    return {};
  },
});
</script>
<style lang="scss" scoped>
.school-website {
  min-height: 65vh;
  background-color: white;
  padding-bottom: 3rem;
  ::v-deep {
    color: $school-website-text-color;
    p {
      color: $school-website-text-color;
    }
  }
}
</style>
