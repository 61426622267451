import { capitalizeFirstLetter } from '@/utils/helpers';

export default {
  platform: {
    careerpass: {
      name: 'CareerPass',
      schoolDisabledTitle: 'This Website has been disabled by @:(platform.careerpass.name).',
      schoolDisabledSubTitle: 'Please contact support if you have any questions.',
    },
    careertools: {
      name: 'Career Tools',
      schoolDisabledTitle:
        'This Website has been disabled by @:(platform.careertools.name), either at the request of the school or, an account has not been paid.',
      schoolDisabledSubTitle:
        "Please contact @:(platform.careertools.name) via <a href='https://www.careertools.com.au/contact'>www.careertools.com.au/contact</a> if you have any questions.",
    },
  },
  general: {
    classYear: 'grade', // As in "Grade 13"
    'primary-school': 'Middle School',
    'secondary-school': 'High School',
    tafe: 'Community College',
    customise: 'customize',
    favourite: {
      label: 'favorite | favorites',
      pastTenseLabel: 'favorited',
      verb: 'favoriting',
    },
    personalise: {
      label: 'personalize',
      pastTenseLabel: 'personalized',
    },
    job: {
      title: 'job | jobs',
      titleWithPrefix: 'a job',
    },
    searchSubText: 'Search students by name',
    whsJourney: 'WHS Journey',
  },
  yearGroupShort: 'Grade',
  yearGroupShortPlural: 'Grades',
  yearGroupLong: 'Grade',
  classYear: 'grade', // As in "Grade 13"
  toCapital: ({ named }) =>
    typeof named('text') === 'string' ? capitalizeFirstLetter(named('text')) : '',
  careerPass: {
    title: 'Wallet',
    'year-group': {
      title: 'Grade',
    },
    'address-postcode': {
      title: 'Zip Code',
    },
    'my-bio': {
      title:
        'I am a highly motivated and results-driven person, known for creative thinking and effective problem-solving skills. I have a strong sense of emotional intelligence, ...',
    },
    'education-details': {
      tooltip_content: 'Fill in your school information, such as your school name and grade level.',
    },
    'reflect-education-summary': {
      placeholder:
        'Strong interests in Science and Mathematics, actively participating in extracurricular activities including the Science Club and Math Olympiad.',
      tooltip_content:
        'Take a moment to think about what you’ve learned in school. Summarize your educational journey here.',
    },
    'reflect-work-experience-summary': {
      placeholder:
        'Earning the “Advanced Leadership” badge has sharpened my teamwork and decision-making abilities. It included challenging assignments that required strategic thinking, and this experience has improved my capacity to lead teams efficiently in my current position.',
    },
    'reflect-badges-summary': {
      placeholder:
        'I am a certified Collaborative Member, skilled in teamwork and project management, and I’m recognized for improving teamwork and communication within diverse groups.',
    },
    'reflect-achievement-summary': {
      placeholder:
        'Awarded ‘Best Science Project’ at the annual school science fair for my innovative and well-researched project on renewable energy solutions.',
    },
    'evidence-supporting-link': {
      tooltip_content:
        'Here you can upload or link to any documents, photos, or videos that showcase your work',
    },
  },
  psp: {
    title: 'CareerPass Copilot',
    short_title: 'Copilot',
    tableHeaderLabel: 'Active Career Action Plans',
    actionPlanSteps: {
      ACTION_STEP_1: {
        title: 'Explore & Favorite the jobs you like',
        description:
          'Browse careers on the explore careers page and click the ‘Favorite’ button for the jobs you find interesting.',
      },
      ACTION_STEP_2: {
        description:
          'If you have favorites, start an action plan for a job you love; if not, no worries – you can still begin a plan for any career that catches your eye.',
      },
      EXPLORE_WALLET: {
        description:
          "Discover your Career Wallet! It's a personal space designed just for you to showcase your academic achievements, work experiences, and more.",
      },
      DISCUSS_WITH_CA: {
        description:
          'Talk about your Career Action Plan with your career counselor, teacher, or guardian.',
      },
      CUSTOMIZE_CAP: {
        description:
          "Your career journey is uniquely yours. Add custom tasks based on suggestions from your career counselor, or guardian. You can even add goals you've set for yourself!",
      },
      DIVE_DEEP_JOB_SUMMARY: {
        description:
          "Take a closer look at your Job Summary and explore key aspects of your chosen occupation. Make sure you review your job's outlook, potential education pathways, salary, and options like taking a gap year.",
      },
    },
    quizQuestionsOptions: {
      'Organised and structured': 'Organized and structured',
    },
    recommendation_title: 'Activate @:psp.title',
  },
  academy: {
    title: 'Academy',
    learn_life_skills: 'Learn Life Skills',
    explore_careers: 'Explore Careers',
    virtual_work_experiences: 'Virtual Work Experiences',
  },
  skillTooltip: {
    'Active Learning': 'Applying new information to both current and future problems and tasks',
    'Active Listening':
      'Paying full attention when someone is speaking, asking questions, and not interrupting',
    'Complex Problem Solving':
      'Figuring out how to fix difficult problems by looking at all the details and coming up with a plan',
    Coordination: 'Working smoothly with others by adjusting what you do based on their actions',
    'Critical Thinking':
      'Using logic to figure out the strengths and weaknesses of different solutions',
    'Equipment Maintenance': 'Keeping machines working well through regular care',
    'Equipment Selection': 'Determining the kind of tools and equipment needed to do a job',
    Installation: 'Installing equipment, machines, wiring, or programs to meet specifications',
    Instructing: 'Teaching or guiding others on how to do something',
    'Judgment and Decision Making':
      'Thinking carefully about the possible outcomes before making a choice',
    'Learning Strategies:': 'Finding and using the best ways to learn or teach something new',
    'Management of Financial Resources': 'Using money wisely in different projects',
    'Management of Personnel Resources':
      'Motivating, developing, and directing people as they work, identifying the best people for the job',
    'Material Resource Management': 'Getting and managing the tools and equipment needed for a job',
    Mathematics: 'Using math to figure out and solve problems',
    Monitoring: 'Keeping an eye on how well things are going and making changes if needed',
    Negotiation: 'Trying to find a middle ground in disagreements',
    'Operation and Control': 'Running machines or systems smoothly.',
    'Operations Analysis': 'Examining requirements and needs to design solutions.',
    'Operations Monitoring': "Watching and checking equipment to ensure it's working right",
    Persuasion: 'Convincing others to see things your way or change their behavior',
    Programming: 'Writing code to create software or applications',
    'Quality Control Analysis': 'Making sure systems and products meet quality standards',
    'Reading Comprehension':
      'Understanding and interpreting what you read in books, emails, and documents',
    Repairing: 'Fixing machines or systems using the right tools',
    Science: 'Applying scientific methods to solve problems.',
    'Service Orientation:': 'Eagerly looking for ways to help or serve others',
    'Social Perceptiveness': "Noticing and understanding other people's reactions and feelings",
    Speaking: 'Talking clearly and effectively to share information with others',
    'Systems Analysis': 'Studying how systems work and how changes can affect them',
    'Systems Evaluation:': 'Judging how well a system is working and how to improve it',
    'Technology Design': "Creating or adapting equipment and technology to meet people's needs",
    'Time Management': "Organizing your time and others' to get things done",
    Troubleshooting: "Figuring out what's wrong when there's a problem and how to fix it",
    Writing: 'Expressing ideas clearly in writing, tailored for who will be reading it',
  },
};
