// adapted from https://github.com/nuxt-community/auth-module/blob/dev/src/core/middleware.ts
function getMatchedComponents(route, matches = []) {
  return [].concat(
    ...[],
    ...route.matched.map((m, index) =>
      Object.keys(m.components).map((key) => {
        matches.push(index);
        return m.components[key];
      }),
    ),
  );
}

const UNVERIFIED_ROUTE = '/unverified';
const VERIFY_ROUTE = '/verify';

export default async ({ $auth, route, redirect, $cookies }) => {
  if (!$auth.user) {
    return;
  }

  // If no compoents matched we're getting a 404 / error, we let them through
  const components = getMatchedComponents(route);
  if (!components.length) {
    return;
  }

  // If auth disabled for route, let them through
  if (typeof components[0].options.auth !== 'undefined' && !components[0].options.auth) {
    return;
  }

  // user verified or impersonating, all good
  if (
    $auth.user.inUseAuthMethod.verified ||
    $auth.isImpersonating ||
    $cookies.get('auth.is_impersonating')
  ) {
    return;
  }

  // already redirected
  if (route.path === UNVERIFIED_ROUTE || route.path === VERIFY_ROUTE) {
    return;
  }

  redirect(UNVERIFIED_ROUTE);
};
