// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue';
import VTooltipPlugin from 'v-tooltip';
import 'v-tooltip/dist/v-tooltip.css';

Vue.use(VTooltipPlugin, {
  themes: {
    'white-tooltip': {
      $extend: 'tooltip',
      // $resetCss: true,
    },
    'map-tooltip': {
      $extend: 'tooltip',
    },
    'primary-tooltip': {
      $extend: 'tooltip',
    },
    'filter-datepicker': {
      $extend: 'dropdown',
    },
    'nav-tooltip': {
      $extend: 'tooltip',
    },
  },
});
