<template>
  <div class="notifications-main">
    <header>
      <div class="row">
        <div class="col-6">
          <h4 v-if="!paginatorInfo">Notifications</h4>
        </div>
        <div class="col-6">
          <div class="float-end">
            <label for="show-unread-toggle">Only show unread</label>
            <FormInputSwitch
              id="show-unread-toggle"
              :value="showOnlyUnread"
              @input="$emit('update:showOnlyUnread', $event)"
            />
          </div>
        </div>
      </div>
    </header>

    <!-- <div role="tablist" class="tab-list">
      <div
        role="tab"
        aria-selected="true"
        tabindex="0"
        data-type="recent"
        @click="tabListClick"
        @keydown="tabListKeyDown"
      >
        {{ pagination ? 'All' : 'Recent' }}
      </div>
      <div
        v-for="notificationType in notificationTypes"
        role="tab"
        :key="notificationType"
        tabindex="-1"
        aria-selected="false"
        :data-type="notificationType"
        @click="tabListClick"
        @keydown="tabListKeyDown"
      >
        {{ capitalizeFirstLetter(notificationType) }}s
      </div>
    </div> -->

    <template v-if="loading">
      <div class="d-flex justify-content-center pt-6"><Loader :width="200" :height="200" /></div>
    </template>
    <template v-else-if="notifications && !notifications.length">
      <div class="row no-notifications">
        <div class="col-12">
          <img
            src="/icons/smiley-face.svg"
            width="25"
            height="24"
            class="img-fluid"
            alt="smiley-face"
          />
          <h6>You have no new notifications</h6>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row sub-header">
        <div class="col-6">
          <h6>{{ paginatorInfo ? 'ALL' : 'LATEST' }}</h6>
        </div>
        <div class="col-6">
          <button type="button" class="no-style mark-as-read" @click.stop="markAllAsRead">
            Mark all as read
          </button>
        </div>
      </div>

      <div class="row notification-list">
        <div v-for="notification in notifications" :key="notification.id" class="col-12">
          <component :is="componentMap[notification.type]" :notification="notification" />
        </div>
      </div>

      <div v-if="paginatorInfo" class="row text-center">
        <div class="col-12">
          <Pagination :paginatorInfo="paginatorInfo" :centerAlign="true" @updatePage="updatePage" />
        </div>
      </div>
    </template>

    <div v-if="!paginatorInfo" class="mt-2 row text-center view-all">
      <div class="col-12">
        <NuxtLink id="view-all" tabindex="0" to="/notifications" aria-label="Notifications">
          View all
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, provide } from '@nuxtjs/composition-api';
import FormInputSwitch from '@/components/form/input/Switch';
import Pagination from '@/components/table/Pagination';
import Loader from '@/components/loaders/Loader';

export default defineComponent({
  components: {
    Pagination,
    FormInputSwitch,
    Loader,
  },
  props: {
    paginatorInfo: {
      type: Object,
      required: false,
    },
    notifications: {
      type: Array,
      required: false,
    },
    showOnlyUnread: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const markAllAsRead = (e) => {
      emit('mark-all-as-read', e);
    };

    const markNotificationAsRead = (id, read) => {
      emit('mark-notification-as-read', {
        id,
        read,
      });
    };

    provide('markNotificationAsRead', markNotificationAsRead);

    const componentMap = {
      upload: () => import('@/components/notifications/type/Upload'),
    };

    const updatePage = async (newPage) => {
      emit('update-page', { newPage });
    };

    provide('tableId', 'notification-header');

    return {
      updatePage,
      componentMap,
      markAllAsRead,
    };
  },
});
</script>

<style lang="scss" scoped>
.notifications-main {
  font-size: 14px;

  header {
    margin-bottom: 10px;

    label {
      float: left;
      margin-top: 7px;
      margin-right: 10px;
      @media (max-width: 430px) {
        font-size: 11px;
      }
    }

    .form-switch {
      float: left;
      margin-top: 0 !important;
    }
  }

  .tab-list {
    display: flex;
    position: relative;
    margin-bottom: 15px;

    div[role='tab'] {
      padding: 10px 2px;
      margin-right: 12px;
      cursor: pointer;
    }

    div[role='tab'][aria-selected='true'] {
      border-bottom: 2px solid $primary;
    }
  }

  .tab-list::before {
    content: '';
    border-radius: 2px;
    bottom: 0px;
    margin: 0px;
    position: absolute;
    width: inherit;
    left: 0;
    right: 0;
    height: 1px;
    background-color: $bg-grey;
  }

  .sub-header {
    margin-bottom: 5px;

    h6 {
      color: $grey;
      font-weight: 100;
    }

    .mark-as-read {
      float: right;
    }

    .mark-as-read:hover {
      text-decoration: underline;
    }
  }

  .notification-list {
    padding-top: 8px;
  }

  .no-notifications {
    margin: 25px 0 15px 0;

    .col-12 {
      text-align: center;
      padding-top: 50px;
      padding-bottom: 50px;
      border-radius: 7px;
      border: 1px solid $bg-grey;
    }

    img {
      position: absolute;
      left: 22%;
      @media (max-width: 490px) {
        left: 15%;
      }
    }

    h6 {
      color: $primary;
      @media (max-width: 490px) {
        font-size: 12px;
      }
    }
  }
}
</style>
