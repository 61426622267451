<template>
  <div
    class="d-flex nav-leaf-div"
    :class="!levelIsActive && 'level-not-active'"
    @click="$emit('click', $event)"
  >
    <a
      v-if="menuItem.instance_type === 'external-link'"
      :href="menuItem.instance.url"
      class="nav-leaf"
      target="_blank"
      >{{ menuItem.text }}</a
    >
    <NuxtLink v-else :to="`/${menuItem.fullSlug}`" class="nav-leaf">{{ menuItem.text }}</NuxtLink>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  components: {},
  props: {
    menuItem: {
      type: Object,
      required: true,
    },
    levelIsActive: {
      type: Boolean,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.nav-leaf-div {
  width: 100%;

  &.level-not-active {
    opacity: 0.8;
  }
}
.nav-leaf {
  font-size: toRem(16);
  font-weight: 700;
  text-decoration: none;
  border-radius: 8px;
  width: 100%;

  color: $school-theme-color;
  background-color: white;
  padding: 12px 8px;
  margin-bottom: 4px;

  &:hover {
    color: white;
    background-color: $school-theme-color;
  }
}
</style>
