import { getDomainConfig, isHealthCheckRequest } from '@/utils/contextHelpers';

export default (context) => {
  const { $config } = context;

  const domainConfig = getDomainConfig(context, $config);

  /* eslint-disable no-param-reassign */
  $config.careertoolsDomain = domainConfig.careertoolsDomain;
  $config.laravelAdminUrl = `${$config.useHttps ? 'https://' : 'http://'}admin.${
    domainConfig.careertoolsDomain
  }`;

  $config.laravelApiUrl = `${$config.useHttps ? 'https://' : 'http://'}${
    domainConfig.schoolDomain ? domainConfig.schoolDomain : domainConfig.careertoolsDomain
  }/api`;

  $config.originDomain = domainConfig.schoolDomain
    ? domainConfig.schoolDomain
    : domainConfig.careertoolsDomain;

  $config.cookieDomain = `.${$config.originDomain.replace('www.', '')}`;
  $config.rootWebsite = `${$config.useHttps ? 'https://' : 'http://'}${
    domainConfig.careertoolsDomain
  }`;
  $config.isRootDomain = domainConfig.isRootDomain;
  $config.schoolDomain = domainConfig.schoolDomain;
  $config.isSchoolSubdomain = domainConfig.isSchoolSubdomain;
  $config.schoolSubDomainSlug = domainConfig.schoolSubDomainSlug;
  $config.isHealthCheckRequest = isHealthCheckRequest(context);
  /* eslint-enable no-param-reassign */
};
