// eslint-disable-next-line import/no-unresolved
import { datadogLogs } from '@datadog/browser-logs';
import { onGlobalSetup } from '@nuxtjs/composition-api';
import useLoggerContext from '@/hooks/loggerContext';
import buildInfo from '@/config/buildInfo';

export default ({ $config }, inject) => {
  onGlobalSetup(() => {
    const loggerContext = useLoggerContext();
    if ($config.datadogLoggingEnabled) {
      datadogLogs.init({
        clientToken: $config.datadogClientToken,
        site: 'datadoghq.com',
        forwardErrorsToLogs: false,
        service: $config.datadogService,
        version: buildInfo.build,
        env: $config.env_real,
      });

      const logger = {
        error: (e) => {
          if (typeof e === 'string' || e instanceof String) {
            datadogLogs.logger.error(e, {
              ...loggerContext.value,
              error: {
                kind: 'LoggedError',
                message: e,
              },
            });
          } else {
            datadogLogs.logger.error(e.message, loggerContext.value, e);
          }
        },
      };
      inject('logger', logger);
    } else {
      const logger = {
        error: (e) => {
          console.log(e, loggerContext.value);
        },
      };
      inject('logger', logger);
    }
  });
};
