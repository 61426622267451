<template>
  <div>
    <NavButton
      class="nav-button w-100"
      :class="highlightOnHover && 'hover-highlight'"
      color="dark"
      :iconPath="iconPath"
      @click.stop="expanded = !expanded"
      :alwaysShowText="true"
      :name="name"
      :isToggle="true"
      :toggled="expanded"
    >
      <slot name="button-text"></slot>
    </NavButton>
    <ul v-if="expanded" class="no-style">
      <slot name="menu"></slot>
    </ul>
  </div>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import NavButton from '@/components/layout/navigation/NavButton';

export default defineComponent({
  components: { NavButton },
  props: {
    iconPath: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    highlightOnHover: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const expanded = ref(false);
    return { expanded };
  },
});
</script>

<style lang="scss" scoped>
.hover-highlight:hover {
  background-color: $cloud-blue;
}
</style>
