<template>
  <div
    role="group"
    aria-label="pagination"
    class="pagination d-flex align-items-center"
    :class="{
      [`pagination-${variant}`]: true,
      'justify-content-center': centerAlign,
    }"
  >
    <Button
      variant="simple"
      v-if="paginatorInfo.currentPage > 1"
      class="item chev me-2 border-0"
      @click="updatePage(paginatorInfo.currentPage - 1)"
      aria-label="previous page"
    >
      <IconDefault
        :width="variant === 'transparent' ? 20 : 6"
        :path="variant === 'transparent' ? 'chevron-left-primary-bold' : 'chevron-left-primary'"
      />
    </Button>
    <div role="text">
      <span class="visually-hidden">page</span>
      <span class="item me-2">{{ paginatorInfo.currentPage }}</span>
      <span class="visually-hidden">of</span>
      <span class="me-2" aria-hidden="true">/</span>
      <span>{{ paginatorInfo.lastPage }}</span>
    </div>
    <Button
      variant="simple"
      v-if="paginatorInfo.currentPage < paginatorInfo.lastPage"
      class="item chev border-0 ms-2"
      @click="updatePage(paginatorInfo.currentPage + 1)"
      aria-label="next page"
    >
      <IconDefault
        :width="variant === 'transparent' ? 20 : 6"
        :path="variant === 'transparent' ? 'chevron-right-primary-bold' : 'chevron-right-primary'"
      />
    </Button>
  </div>
</template>

<script>
import { defineComponent, inject } from '@nuxtjs/composition-api';
import Button from '@/components/Button';
import IconDefault from '@/components/icon/Default';

export default defineComponent({
  components: { Button, IconDefault },
  props: {
    paginatorInfo: {
      type: Object,
      required: true,
    },
    centerAlign: {
      type: Boolean,
      required: false,
      default: false,
    },
    variant: {
      type: String,
      default: 'default',
      required: false,
      validator: (v) => ['default', 'transparent'].indexOf(v) !== -1,
    },
  },
  setup(props, { emit }) {
    const tableId = inject('tableId');

    const updatePage = (page) => {
      if (page < 1) {
        return;
      }

      if (page > props.paginatorInfo.lastPage) {
        return;
      }

      emit('updatePage', page);

      const el = document.getElementById(tableId);

      if (!el) {
        return;
      }

      el.focus();
    };

    return { updatePage };
  },
});
</script>

<style lang="scss" scoped>
.pagination {
  height: 56px;
  padding: 10px 40px;
  background: #f3f4f6;
  border-radius: 0 0 8px 8px;

  color: $primary-1;
  font-size: 14px;
  font-weight: 400;
  .item {
    display: inline-block;
    padding: 5px 12px;
    border-radius: 4px;
    background-color: #fff;
    border: 0;

    &.chev {
      &:hover {
        cursor: pointer;
      }
    }

    &.text {
      background-color: transparent;
    }

    &.page-info {
      line-height: 21px;
    }
  }
  &.pagination-transparent {
    background: transparent;

    span {
      font-weight: 600;
    }
  }
}
::v-deep {
  .btn-simple {
    .btn-inner {
      padding: 0;
    }
  }
}
</style>
