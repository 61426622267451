<template>
  <div :class="{ 'fixed-nav-spacing': !hasTransparentNavbar }">
    <nav
      class="navbar navbar-expand-lg navbar-dark bg-business fixed-top"
      :class="{ 'navbar-transparent': hasTransparentNavbar && !hasScrolled, scrolled: hasScrolled }"
    >
      <div class="container">
        <button class="navbar-toggler" type="button" @click.prevent="toggleOffcanvasMenu()">
          <span class="navbar-toggler-icon"></span>
        </button>
        <NuxtLink to="/" class="navbar-brand">
          <img src="/logo/white.svg" height="34px" alt="Careertools" class="d-none d-lg-block" />
          <img src="/logo/icon-white.svg" height="34px" alt="Careertools" class="d-lg-none" />
        </NuxtLink>
        <div class="navbar-toggler"></div>
        <div class="collapse navbar-collapse justify-content-end navbar-nav" id="navbarNavDropdown">
          <div
            class="navbar-nav"
            role="list"
            @keydown.right="focusTab('next')"
            @keydown.left="focusTab('previous')"
          >
            <div
              v-for="(item, i) in navItems"
              :key="`nav-item-${i}`"
              role="listitem"
              class="nav-item"
              :class="{ dropdown: item.hasDropdown }"
            >
              <a
                ref="itemRef"
                class="cursor-pointer nav-link"
                :data-bs-toggle="item.hasDropdown ? 'dropdown' : false"
                :href="!item.hasDropdown ? `${item.link}` : '#'"
              >
                {{ item.label }}
              </a>
              <ul v-if="item.hasDropdown" class="dropdown-menu">
                <li v-for="(dropdownItem, i) in item.dropdownItems" :key="`dropdownItem-${i}`">
                  <NuxtLink :to="dropdownItem.link" class="dropdown-item">
                    {{ dropdownItem.label }}
                  </NuxtLink>
                </li>
              </ul>
            </div>
          </div>
          <NuxtLink to="/pricing" class="btn btn-business ms-3">Get Started </NuxtLink>
        </div>
      </div>
    </nav>
    <!-- mobile -->
    <div
      class="offcanvas offcanvas-start bg-business text-white"
      :class="{ show: Boolean(showOffcanvas && offCanvasVisible) }"
      tabindex="-1"
      id="offcanvasExample"
      aria-labelledby="offcanvasExampleLabel"
      :style="{ visibility: Boolean(showOffcanvas && offCanvasVisible) ? 'visible' : 'hidden' }"
    >
      <div class="offcanvas-header">
        <button
          type="button"
          class="btn-close text-reset"
          @click.prevent="toggleOffcanvasMenu()"
        ></button>
        <img src="/logo/icon-white.svg" height="34px" />
        <div></div>
      </div>
      <div class="offcanvas-body">
        <div
          role="list"
          class="nav flex-column mb-4"
          @keydown.down="focusTab('next')"
          @keydown.up="focusTab('previous')"
        >
          <div
            class="nav-item"
            role="listitem"
            v-for="(item, i) in navItems"
            :key="`mobile-nav-item-${i}`"
          >
            <a
              ref="itemRef"
              class="cursor-pointer nav-link"
              :href="!item.hasDropdown ? `${item.link}` : '#'"
              @click="item.hasDropdown && toggleMobileDropdownItem(i)"
            >
              {{ item.label }}
              <IconDefault
                v-if="item.hasDropdown"
                class="ms-2"
                :width="16"
                :path="dropdownExpandedIndex === i ? 'chevron-up' : 'chevron-down'"
              />
            </a>
            <ul v-if="item.hasDropdown && dropdownExpandedIndex === i" class="mobile dropdown-menu">
              <li v-for="(dropdownItem, i) in item.dropdownItems" :key="`dropdownItem-${i}`">
                <NuxtLink :to="dropdownItem.link" class="dropdown-item">
                  {{ dropdownItem.label }}
                </NuxtLink>
              </li>
            </ul>
          </div>
        </div>
        <NuxtLink to="/pricing" class="btn btn-business" @click.native="toggleOffcanvasMenu()"
          >Get Started
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useRoute,
  watch,
} from '@nuxtjs/composition-api';
import IconDefault from '@/components/icon/Default';
import useBreakpoint from '@/hooks/breakpoint';

export default defineComponent({
  components: { IconDefault },
  setup() {
    const navItems = ref([
      { label: 'Home', link: '/' },
      { label: 'Community', link: '/community' },
      {
        label: 'Products',
        link: '#',
        hasDropdown: true,
        dropdownItems: [
          { label: 'Your Careers Website', link: '/your-custom-website' },
          { label: 'Achieveworks', link: '/achieve-works' },
          { label: 'All My Own Work', link: '/all-my-own-work' },
          { label: 'Life Ready', link: '/life-ready' },
        ],
      },
      {
        label: 'About',
        link: '#',
        hasDropdown: true,
        dropdownItems: [
          { label: 'FAQs', link: '/faq' },
          { label: 'Our Story', link: '/our-story' },
        ],
      },
      { label: 'Contact', link: '/contact' },
    ]);

    const route = useRoute();

    const itemRef = ref([]);
    const hasScrolled = ref(false);
    const showOffcanvas = ref(false);
    const currentPage = ref(null);
    const dropdownExpandedIndex = ref(null);
    const currentFocusedIndex = ref(null);

    const hasTransparentNavbar = computed(() => {
      const pages = ['root-index'];
      currentPage.value = route.value.name;
      return pages.includes(currentPage.value);
    });

    const onScroll = () => {
      const windowTop = window.top.scrollY; /* or: e.target.documentElement.scrollTop */
      hasScrolled.value = windowTop > 30;
    };

    const currentBreakpoint = useBreakpoint();
    const offCanvasVisible = computed(() => ['xs', 'sm', 'md'].includes(currentBreakpoint.value));

    const toggleOffcanvasMenu = () => {
      showOffcanvas.value = !showOffcanvas.value;
    };

    const toggleMobileDropdownItem = (index) => {
      if (index === dropdownExpandedIndex.value) {
        dropdownExpandedIndex.value = null;
      } else {
        dropdownExpandedIndex.value = index;
      }
    };

    watch(currentPage, () => {
      showOffcanvas.value = false;
    });

    const focusTab = (type) => {
      currentFocusedIndex.value = document.activeElement;
      const activeIndex = itemRef.value.indexOf(currentFocusedIndex.value);
      const lastIndex = itemRef.value.length - 1;
      if (type === 'next') {
        // eslint-disable-next-line no-unused-expressions
        activeIndex === lastIndex
          ? itemRef.value[0].focus()
          : itemRef.value[activeIndex + 1].focus();
      }
      if (type === 'previous') {
        // eslint-disable-next-line no-unused-expressions
        activeIndex === 0
          ? itemRef.value[lastIndex].focus()
          : itemRef.value[activeIndex - 1].focus();
      }
    };

    onMounted(() => {
      window.addEventListener('scroll', onScroll);
    });

    return {
      toggleMobileDropdownItem,
      navItems,
      showOffcanvas,
      hasScrolled,
      hasTransparentNavbar,
      toggleOffcanvasMenu,
      onScroll,
      dropdownExpandedIndex,
      offCanvasVisible,
      itemRef,
      focusTab,
      currentFocusedIndex,
    };
  },
});
</script>

<style lang="scss" scoped>
.offcanvas {
  .btn-business {
    background: #fff;
    border-color: #fff;
    color: #095df6;
  }

  .nav-link {
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    padding: 1rem 0.5rem;
  }

  .nav.subnav .nav-link {
    font-weight: 700;
    font-size: 16px;
    padding: 0.5rem 0.5rem;
  }
}

.btn-close {
  background: transparent
    escape-svg(
      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#fff'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")
    )
    center/1em auto no-repeat;
  opacity: 1;
}

.navbar-toggler {
  padding: 0;
}

.navbar-dark .navbar-toggler-icon {
  background-image: escape-svg(
    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>")
  );
}

.offcanvas-header {
  height: toRem(70);
}

.offcanvas-start {
  border: 0;
}

.navbar {
  transition: background 0.5s;

  .navbar-brand {
    margin-right: 0;
  }
}

.btn-business {
  background: #095df6;
  border-color: #095df6;
  color: #fff;
  border-radius: 50px;
  font-weight: 700;
  font-size: toRem(14);
  transition: background 0.5s, border 0.5s, color 0.5s, font-weight 0.5s;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  border-color: transparent;
}

.navbar-transparent {
  background: transparent !important;

  &.navbar-dark .navbar-nav .nav-link {
    font-weight: 700;
  }

  .btn-business {
    background: #fff;
    border-color: #fff;
    color: #095df6;
  }
}

.school-link {
  text-decoration: none;
}

.navbar-expand-lg .navbar-nav .nav-link {
  font-weight: 700;
  padding-left: toRem(25);
  padding-right: toRem(25);
}

.fixed-nav-spacing {
  height: toRem(70);
  @include m-large {
    height: toRem(96);
  }
}

.dropdown-menu {
  background: #ffffff;
  border: 1px solid #eceef1;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.35);
  border-radius: 8px;
  padding: 0.8rem 0;

  .dropdown-item {
    padding: 0.25rem toRem(23);

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  &.mobile {
    display: contents;
    border: none;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid #fff;
    li {
      a {
        color: #fff;
      }
    }
  }
}

nav {
  .careertools-icon {
    max-width: 54px;
  }

  height: toRem(70);
  @include m-large {
    height: toRem(96);
  }

  .student {
    .menu-icons-right {
      margin-right: -10px;

      @include m-small {
        margin-right: 0;
      }
    }

    .img-icon.profile {
      font-size: toRem(10);
    }
  }

  .section {
    display: flex;
    flex-flow: row nowrap;
    flex: 1;
    min-width: 0;

    .large-screen {
      display: block;
    }

    .large-screen.d-flex {
      display: flex !important;
    }

    &.center {
      flex: 0.4;
    }

    .child {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      flex: 1;
      min-width: 0;
    }
  }

  .search-container {
    ::v-deep {
      input::placeholder {
        color: $white;
      }

      .search-dropdown {
        .form-control:focus {
          border: none;

          &::placeholder {
            color: $athens-gray;
          }
        }

        .form-control:focus + .input-group-addon img {
          opacity: 0;
        }

        .form-control:not(:focus) {
          background: transparent;
        }

        .form-control:focus + .input-group-addon {
          background-color: $athens-gray;
          color: $athens-gray;
        }

        .form-control:not(:focus) + .input-group-addon {
          background: transparent;
          border: solid 1px #ced4da;
          border-left: none;
        }

        .form-control {
          height: 40px;
        }

        input {
          color: $white;
        }

        input:focus {
          color: $business;
          background-color: $athens-gray;
        }

        .input-group-addon {
          border-left: none;
        }
      }
    }

    display: none;
    left: 150px;

    @include m-medium {
      display: block;
    }
  }

  ::v-deep {
    .student {
      .logout-text {
        display: none;

        @include m-small {
          display: inline;
        }
      }
    }

    .dropdown-item {
      &:hover {
        background-color: transparent;
      }
    }
  }
}
.bg-business {
  background-color: $business;
}
</style>
