<template>
  <div class="d-inline-block">
    <template v-if="isFreeTier">
      <NavDropdown
        iconPath="free-tier/notification"
        :showRedDot="Boolean(unreadRecentNotifications.length)"
      >
        <div class="notifications-dropdown">
          <div class="p-4">
            <Notifications
              @mark-notification-as-read="markNotificationAsRead"
              @mark-all-as-read="markAllAsRead"
              :showOnlyUnread.sync="filters.showOnlyUnread"
              :notifications="filteredNotifications"
            />
          </div>
        </div>
      </NavDropdown>
    </template>
    <template v-else>
      <NavigationDropdown
        buttonText="Notifications"
        buttonIconPath="notification"
        name="notifications"
        :showRedDot="Boolean(unreadRecentNotifications.length)"
      >
        <template v-slot:dropdown-content>
          <div class="notifications-dropdown">
            <div class="p-4">
              <Notifications
                @mark-notification-as-read="markNotificationAsRead"
                @mark-all-as-read="markAllAsRead"
                :showOnlyUnread.sync="filters.showOnlyUnread"
                :notifications="filteredNotifications"
              />
            </div>
          </div>
        </template>
      </NavigationDropdown>
    </template>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import useNotifications from '@/hooks/notifications';
import Notifications from '@/components/notifications/Notifications';
import NavDropdown from '@/components/layout/freeTier/NavDropdown';
import NavigationDropdown from '@/components/layout/navigation/Dropdown';
import useMarkNotificationAsRead from '@/graphql/hooks/mutation/markNotificationAsRead';

export default defineComponent({
  components: {
    Notifications,
    NavDropdown,
    NavigationDropdown,
  },
  props: {
    isFreeTier: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      recentNotifications,
      unreadRecentNotifications,
      markNotificationAsReadStore,
      markAllNotificationsAsReadStore,
    } = useNotifications();

    const { mutate: _markNotificationAsRead } = useMarkNotificationAsRead();

    const filters = ref({
      showOnlyUnread: false,
    });

    const filteredNotifications = computed(() => {
      return filters.value.showOnlyUnread
        ? unreadRecentNotifications.value
        : recentNotifications.value;
    });

    const markNotificationAsRead = ({ id, read }) => {
      _markNotificationAsRead({
        id,
        read,
      });
      markNotificationAsReadStore(id, read);
    };

    const markAllAsRead = () => {
      const notificationIds = recentNotifications.value.map((n) => n.id);
      _markNotificationAsRead({
        id: notificationIds,
        read: true,
      });
      markAllNotificationsAsReadStore(notificationIds);
    };

    return {
      filteredNotifications,
      unreadRecentNotifications,
      filters,
      markNotificationAsRead,
      markAllAsRead,
    };
  },
});
</script>

<style lang="scss" scoped>
.notifications-dropdown {
  width: 540px;

  @media (max-width: 767px) {
    width: 100%;
  }
}

::v-deep {
  .dropdown-menu {
    @media (max-width: 767px) {
      left: -1px;
      top: -1px;
      position: fixed !important;
      width: 101%;
      bottom: 0 !important;
      overflow-y: scroll;
      overflow-x: hidden;
      border-radius: 0;
      margin: 0;
      transform: none !important;
    }
  }
  .notification-list {
    max-height: 500px;
    overflow-y: scroll;
  }
}
</style>
