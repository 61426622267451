import cloneDeep from 'lodash/cloneDeep';

export const state = () => ({
  filters: {
    keyword: '',
    featured: true,
  },
  currentPage: 1,
  response: [],
});

export const mutations = {
  // eslint-disable-next-line no-shadow
  setFilters: (state, filters) => {
    state.filters = filters;
  },
  // eslint-disable-next-line no-shadow
  setCurrentPage: (state, page) => {
    state.currentPage = page;
  },
  // eslint-disable-next-line no-shadow
  setDefaultFilter: (state) => {
    state.filters = {
      keyword: '',
      featured: true,
    };
    state.currentPage = 1;
  },

  // eslint-disable-next-line no-shadow
  setResponse: (state, val) => {
    const index = state.response.findIndex((obj) => obj.name === val.name);
    if (index !== -1) {
      state.response.splice(index, 1, val);
    } else {
      state.response.push(val);
    }
  },
  // eslint-disable-next-line no-shadow
  resetResponse: (state) => {
    state.response = [];
  },
};

export const actions = {
  resetPspState: (ctx) => {
    ctx.commit('setDefaultFilter');
    ctx.commit('resetResponse');
  },
};

export const getters = {
  // eslint-disable-next-line no-shadow
  getPspResponse: (state) => () => {
    return cloneDeep(state.response);
  },
};
