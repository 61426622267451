<template>
  <img
    :class="alignClass"
    class="icon"
    @click="click"
    :width="width"
    :height="height"
    :src="`/icons/${path}.svg`"
    :alt="ariaLabel ? ariaLabel : ''"
    :role="ariaLabel ? 'img' : 'presentation'"
  />
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    path: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: false,
    },
    height: {
      type: Number,
      required: false,
    },
    ariaLabel: {
      type: String,
      required: false,
    },
    vAlign: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props, { emit }) {
    const click = () => {
      emit('click');
    };

    const alignClass = computed(() => {
      return props.vAlign ? `align-${props.vAlign}` : null;
    });

    return { click, alignClass };
  },
});
</script>

<style lang="scss" scoped></style>
