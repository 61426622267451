import { provideToast } from 'vue-toastification/composition';
import { onGlobalSetup } from '@nuxtjs/composition-api';
import { POSITION } from 'vue-toastification';

import 'vue-toastification/dist/index.css';

export default () => {
  onGlobalSetup(() => {
    provideToast({
      position: POSITION.BOTTOM_CENTER,
      toastClassName: 'custom-toast',
    });
  });
};
