import gql from 'graphql-tag';
import { useMutation } from '@vue/apollo-composable';

const MARK_NOTIFICATION_AS_READ = gql`
  mutation markNotificationAsRead($id: [ID]!, $read: Boolean!) {
    markNotificationAsRead(id: $id, read: $read)
  }
`;

const markAsRead = () => useMutation(MARK_NOTIFICATION_AS_READ);

export default markAsRead;
