<template>
  <Dropdown class="ms-3">
    <template v-slot:trigger="{ contentId, isOpen }">
      <div class="button-container position-relative" :class="showRedDot && 'red-dot'">
        <div class="button-badge-container">
          <slot name="button-badge"></slot>
        </div>
        <button
          :aria-describedby="contentId"
          :aria-expanded="isOpen"
          aria-haspopup="true"
          @click="openLink"
          class="no-style"
        >
          <IconDefault :width="24" :height="24" :path="iconPath" />
        </button>
      </div>
    </template>
    <template v-slot:content>
      <div :style="minWidth && { 'min-width': `${minWidth}px` }">
        <slot></slot>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import IconDefault from '@/components/icon/Default';
import Dropdown from '@/components/floating/Dropdown';

export default defineComponent({
  components: {
    IconDefault,
    Dropdown,
  },
  props: {
    iconPath: {
      type: String,
      required: true,
    },
    minWidth: {
      type: Number,
      required: false,
    },
    showRedDot: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      required: false,
    },
    openInNewTab: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const openLink = (e) => {
      if (!props.href) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      if (props.openInNewTab) {
        window.open(props.href);
      } else {
        window.location.href = props.href;
      }
    };

    return { openLink };
  },
});
</script>

<style lang="scss" scoped>
button {
  width: 50px;
  height: 50px;

  border-radius: 50%;
  background: rgba(255, 255, 255, 0.56);
  &:not(:hover) {
    box-shadow: 0px 2px 1px 0px rgba(255, 255, 255, 0.6) inset,
      0px -1px 1px 0px rgba(0, 0, 0, 0.06) inset, 10px 16px 32px 0px rgba(2, 0, 98, 0.14),
      0px 1.5999999046325684px 3.1999998092651367px 0px rgba(0, 0, 0, 0.04) inset;
  }
}

.button-badge-container {
  position: absolute;
  overflow: hidden;
  top: -20%;
  right: -20%;
}

.red-dot::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $cerise;
  position: absolute;
  top: 10px;
  left: 26px;
}
</style>
