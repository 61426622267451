import { onGlobalSetup, provide } from '@nuxtjs/composition-api';
import * as EVENT from '@/constants/analyticsEvent';
import mixpanel from 'mixpanel-browser';

export default ({ $config }) => {
  const subscribedEvents = [
    EVENT.CTX_USER_LOGGED_IN,
    EVENT.CTX_ADDED_STUDENTS,
    EVENT.CTX_ADDED_TEACHERS,
    EVENT.CTX_EDITED_CUSTOM_PAGE,
    EVENT.CTX_ADDED_CUSTOM_PAGE,
    EVENT.CTX_SAVED_COMMUNICATION,
    EVENT.CTX_EDITED_EXISTING_PAGE,
    EVENT.CTX_TAB_CLICKED,
    EVENT.SAVED_CALENDAR_BLOCKOUT,
    EVENT.CTX_EDITED_CLUSTER_PAGE,
    EVENT.CTX_ADDED_CLUSTER_PAGE,
    EVENT.CTX_LINK_CLICKED,
    EVENT.CTX_CAREER_CARD_CTA_CLICKED,
    EVENT.CTX_CLICKED_UPCOMING_EVENT,
    EVENT.CTX_PSP_RETAKE_QUIZ_BTN,
    EVENT.CTX_DASHBOARD_TILE_CLICKED,
    EVENT.CTX_DASHBOARD_PANELS,
    EVENT.CTX_DESTINATION_SURVEY_SENT,
    EVENT.CTX_STUDENT_PROFILE_COPILOT_VIEW_ACTION_PLAN,
    EVENT.CTX_STUDENTS_QUIZ_EXPORT,
    EVENT.CTX_STUDENTS_TABLE_CAP_EXPORT,
    EVENT.CTX_LESSON_DOWNLOADED,
    EVENT.CTX_RESOURCE_DOWNLOADED,
    EVENT.CTX_CREATED_CALENDAR_EVENT,
    EVENT.CTX_PSP_COURSE_CAMPUS_MODAL_CLICKED,
    EVENT.CTX_PSP_COURSE_FAVE_BTN,
    EVENT.CTX_PSP_COURSE_EXPRESS_INTEREST_CLICKED,
    EVENT.CTX_PSP_COURSE_SEARCH_FILTERS,
    EVENT.CTX_PSP_CONFIDENCE_COURSE_SEARCH,
    EVENT.CTX_CS_SEARCH_PAGE_LOADED,
    EVENT.CTX_CS_SEARCH_KEYWORD_ENTERED,
    EVENT.CTX_CS_SHORTLIST_MAXED_OUT,
    EVENT.CTX_CS_COURSE_SHORTLISTED,
    EVENT.CTX_CS_COURSE_SEE_MORE,
    EVENT.CTX_IMPERSONATED_USER,
    EVENT.CTX_LEAVE_IMPERSONATED_USER,
    EVENT.WAL_SIDEBAR_ICON_CLICKED,
    EVENT.CTX_ACTIVITY_PILL_CLICKED,
    EVENT.CTX_PSP_MBTI_PROFILE_NEXT_CLICKED,
    EVENT.CTX_PSP_STARTED_QUIZ,
    EVENT.CTX_PSP_POST_SCHOOL_QUESTION_CLICKED,
    EVENT.CTX_PSP_LETS_BEGIN,
    EVENT.CTX_PSP_KEY_STRENGTHS_NEXT_CLICKED,
    EVENT.CTX_PSP_SKILLS_FORECAST_NEXT_CLICKED,
    EVENT.CTX_PSP_INTERESTS_SUBMIT,
    EVENT.CTX_PSP_VALUES_SUBMIT,
    EVENT.CTX_PSP_SECTORS_SUBMIT,
    EVENT.CTX_PSP_SELECT_CAREER_SUBMIT,
    EVENT.CTX_PSP_CAP_STEP_DELETED,
    EVENT.CTX_PSP_COURSE_LEARN_MORE_CLICKED,
    EVENT.CTX_PSP_CONFIDENCE_COURSE_RECOMMENDATIONS,
    EVENT.CTX_PSP_CAP_STEP_UPSERTED,
    EVENT.CTX_PSP_OCC_AI_RETRY_BTN,
    EVENT.CTX_PSP_CAP_STEP_STATUS_CHANGED,
    EVENT.CTX_PSP_CONFIRM_CAP_REPLACE_SEEN,
    EVENT.CTX_PSP_CAP_REPLACE_YES,
    EVENT.CTX_PSP_CAP_ACTIVATED,
    EVENT.CTX_PSP_CAP_REPLACE_NO,
    EVENT.CTX_PSP_ENTRY_START_BTN,
    EVENT.CTX_PSP_VIEW_ACTION_PLAN_BTN,
    EVENT.CTX_PSP_RESUME_BTN,
    EVENT.CTX_PSP_OCC_FAVE_BTN,
    EVENT.CTX_PSP_OCC_CARD_LEARN_MORE,
    EVENT.CTX_PSP_OCC_CARD_ACTIVE_ACTION_PLAN,
    EVENT.CTX_PSP_CONFIRMED_QUIT,
    EVENT.CTX_PSP_CONFIDENCE_ACTION_PLAN_SUBMITTED,
    EVENT.CTX_PSP_SIDEBAR_LINK_CLICKED,
    EVENT.CTX_REPORT_STUDENT_SEARCH,
    EVENT.WAL_ITEM_STARTED,
    EVENT.WAL_ITEM_SAVED,
    EVENT.CTX_PASS_PROFILE_UPDATED,
    EVENT.CTX_PASS_SHARE_LINK_CREATED,
    EVENT.DOWNLOADED_COVERLETTER,
    EVENT.DOWNLOADED_RESUME,
    EVENT.DOWNLOADED_RESUME_WORD,
    EVENT.DOWNLOADED_CUSTOMRESUME,
    EVENT.DOWNLOADED_CUSTOMRESUME_WORD,
    EVENT.CTX_DOCUMENT_UPLOADED,
    EVENT.CTX_CLICKED_REPORT_EXPORT,
    EVENT.CTX_PSP_CONFIDENCE_RESPONSE_SUBMITTED,
    EVENT.CTX_PSP_OCC_SEARCH_BUTTON,
    EVENT.CTX_PSP_OCC_KEYWORD_SEARCH,
    EVENT.CTX_PSP_EXPLORE_PAGINATION_BTN,
    EVENT.CTX_PSP_OCC_SEE_MORE,
    EVENT.CTX_PSP_OCC_SEE_ALL_FAVE,
    EVENT.CTX_VIEWED_PORTFOLIO,
    EVENT.CTX_REPORTING_STUDENT_CAP_EXPORT,
    EVENT.CTX_CREATED_CUSTOM_GROUP,
    EVENT.CTX_EMAIL_FEATURE_SENT,
  ];

  const mixpanelClient = {
    initialize: () => {
      if ($config.mixpanelToken) {
        mixpanel.init($config.mixpanelToken);
      }
    },
    track: (eventName, eventData = {}) => {
      mixpanel.track(eventName, eventData);
    },
    login: (guid) => {
      mixpanel.identify(guid);
    },
    logout: (guid) => {
      mixpanel.reset(guid);
    },
    getSubscribedEvents: () => {
      return subscribedEvents;
    },
  };

  const logMockCall = (functionName, params) => {
    console.log(['MOCK MIXPANEL CALLED', functionName, params]);
  };

  const mockClient = {
    initialize: (...args) => {
      logMockCall('initialize', args);
    },
    track: (...args) => {
      logMockCall('track', args);
    },
    login: (...args) => {
      logMockCall('login', args);
    },
    logout: (...args) => {
      logMockCall('logout', args);
    },
    getSubscribedEvents: () => {
      return subscribedEvents;
    },
  };

  onGlobalSetup(async () => {
    const mixpanelObj = $config.analyticsEnabled ? mixpanelClient : mockClient;
    mixpanelObj.initialize($config);
    provide('mixpanel', mixpanelObj);
  });
};
