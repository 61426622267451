const middleware = {}

middleware['check-achieveworks-is-enabled'] = require('../middleware/check-achieveworks-is-enabled.js')
middleware['check-achieveworks-is-enabled'] = middleware['check-achieveworks-is-enabled'].default || middleware['check-achieveworks-is-enabled']

middleware['check-non-existant-school-page'] = require('../middleware/check-non-existant-school-page.js')
middleware['check-non-existant-school-page'] = middleware['check-non-existant-school-page'].default || middleware['check-non-existant-school-page']

middleware['form-redirects'] = require('../middleware/form-redirects.js')
middleware['form-redirects'] = middleware['form-redirects'].default || middleware['form-redirects']

middleware['onboarded'] = require('../middleware/onboarded.js')
middleware['onboarded'] = middleware['onboarded'].default || middleware['onboarded']

middleware['permissions'] = require('../middleware/permissions.js')
middleware['permissions'] = middleware['permissions'].default || middleware['permissions']

middleware['psp-completed'] = require('../middleware/psp-completed.js')
middleware['psp-completed'] = middleware['psp-completed'].default || middleware['psp-completed']

middleware['public-registration'] = require('../middleware/public-registration.js')
middleware['public-registration'] = middleware['public-registration'].default || middleware['public-registration']

middleware['set-free-tier-layout'] = require('../middleware/set-free-tier-layout.js')
middleware['set-free-tier-layout'] = middleware['set-free-tier-layout'].default || middleware['set-free-tier-layout']

middleware['verified'] = require('../middleware/verified.js')
middleware['verified'] = middleware['verified'].default || middleware['verified']

middleware['website-enabled'] = require('../middleware/website-enabled.js')
middleware['website-enabled'] = middleware['website-enabled'].default || middleware['website-enabled']

export default middleware
