<template>
  <FullPageError
    :title="tPlatform('schoolDisabledTitle')"
    :subTitle="tPlatform('schoolDisabledSubTitle')"
  />
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import FullPageError from '@/components/layout/FullPageError';
import useI18n from '@/hooks/i18n';

export default defineComponent({
  components: {
    FullPageError,
  },
  props: {},
  setup() {
    const { tPlatform } = useI18n();
    return { tPlatform };
  },
});
</script>
