<template>
  <div
    class="form-switch d-flex align-items-center"
    :class="[
      { 'my-3': includeVerticalMargin && spacing === 'my-3' },
      `form-switch-${size}`,
      spacing,
      styleType,
    ]"
  >
    <label
      v-if="hasLabel && labelLeft"
      class="form-check-label ms-2"
      :class="[{ 'me-3': hasLabelSlot }]"
      :aria-labelledby="id"
      :for="id"
    >
      <template v-if="hasLabelSlot">
        <slot name="label"></slot>
      </template>
      <template v-else>
        {{ label }}
      </template>
    </label>
    <input
      :disabled="disabled"
      :checked="value"
      @click="updateValue"
      class="form-check-input cursor-pointer focusable"
      :class="styleType"
      type="checkbox"
      role="switch"
      :id="id"
      :aria-label="!!ariaLabel && ariaLabel"
    />
    <label
      v-if="hasLabel && !labelLeft"
      :aria-labelledby="id"
      class="form-check-label ms-2"
      :for="id"
    >
      <template v-if="hasLabelSlot">
        <slot name="label"></slot>
      </template>
      <template v-else>
        {{ label }}
      </template>
    </label>
    <template>
      <slot name="description"></slot>
    </template>
  </div>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    includeVerticalMargin: {
      type: Boolean,
      default: true,
    },
    size: {
      required: false,
      default: 'md',
      validator: (v) => ['md'].indexOf(v) !== -1,
    },
    label: {
      type: String,
      required: false,
    },
    ariaLabel: {
      type: String,
      required: false,
    },
    labelLeft: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: false,
    },
    value: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    spacing: {
      type: String,
      default: 'my-3',
    },
    styleType: {
      validator: (v) => ['default', 'white'].indexOf(v) !== -1,
    },
  },
  setup(props, { emit, slots }) {
    const updateValue = () => {
      emit('input', !props.value);
      emit('change', !props.value);
      emit('toggle', !props.value);
    };

    const hasLabelSlot = !!slots.label;

    const hasLabel = computed(() => !!props.label || hasLabelSlot);

    return { updateValue, hasLabelSlot, hasLabel };
  },
});
</script>

<style scoped lang="scss">
.form-switch-md {
  .form-check-input {
    font-size: 22px;
  }
}

.white {
  .form-check-label {
    color: #fff;
    margin-right: 10px;
  }
}

.form-check-label {
  margin-top: 3px;
}

.form-switch {
  padding-left: 0;
}

.form-check-input {
  margin-left: 0;

  &.white {
    &:checked {
      background-color: $primary-purple;
      border-color: #fff;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF' /%3e%3c/svg%3e");
    }

    &:not(:checked) {
      background-color: #fff;
      border-color: #fff;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%236260F7' /%3e%3c/svg%3e");
    }
  }

  &:checked {
    background-color: #052a6f;
    border-color: #052a6f;
  }

  &:not(:checked) {
    background-color: #c7c7c7;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff' /%3e%3c/svg%3e");
    border: 1px solid #c7c7c7;
  }

  &:focus {
    box-shadow: none;
  }
}
</style>
