import { PRICING_TIER_FREE } from '@/constants/pricingTiers';
import freeTierLayoutRoutes from '@/config/freeTierLayoutRoutes';

// adapted from https://github.com/nuxt-community/auth-module/blob/dev/src/core/middleware.ts
function getMatchedComponents(route, matches = []) {
  return [].concat(
    ...[],
    ...route.matched.map((m, index) =>
      Object.keys(m.components).map((key) => {
        matches.push(index);
        return m.components[key];
      }),
    ),
  );
}

export default async ({ $auth, store, route }) => {
  const components = getMatchedComponents(route);

  if (!components.length) {
    return;
  }

  const pageComponent = components[0];

  if (!freeTierLayoutRoutes.includes(route.name)) {
    return;
  }

  const { school } = store.getters;

  if (school?.pricing_tier !== PRICING_TIER_FREE) {
    return;
  }

  if ($auth.user) {
    pageComponent.options.layout = 'freeTier/authenticated';
  } else {
    pageComponent.options.layout = 'freeTier/unauthenticated';
  }
};
