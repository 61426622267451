export const CTX_USER_LOGGED_IN = 'ctx_user_logged_in';
export const CTX_ADDED_STUDENTS = 'ctx_added_students';
export const CTX_ADDED_TEACHERS = 'ctx_added_teachers';
export const CTX_EDITED_CUSTOM_PAGE = 'ctx_edited_custom_page';
export const CTX_ADDED_CUSTOM_PAGE = 'ctx_added_custom_page';
export const CTX_SAVED_COMMUNICATION = 'ctx_saved_communication';
export const CTX_EDITED_EXISTING_PAGE = 'ctx_edited_existing_page';
export const CTX_TAB_CLICKED = 'ctx_tab_clicked';
export const SAVED_CALENDAR_BLOCKOUT = 'saved_calendar_blockout';
export const CTX_EDITED_CLUSTER_PAGE = 'ctx_edited_cluster_page';
export const CTX_ADDED_CLUSTER_PAGE = 'ctx_added_cluster_page';
export const CTX_LINK_CLICKED = 'ctx_link_clicked';
export const CTX_CAREER_CARD_CTA_CLICKED = 'ctx_career_card_cta_clicked';
export const CTX_CLICKED_UPCOMING_EVENT = 'ctx_clicked_upcoming_event';
export const CTX_PSP_RETAKE_QUIZ_BTN = 'ctx_psp_retake_quiz_btn';
export const CTX_DASHBOARD_TILE_CLICKED = 'ctx_dashboard_tile_clicked';
export const CTX_DASHBOARD_PANELS = 'ctx_dashboard_panels';
export const CTX_DESTINATION_SURVEY_SENT = 'ctx_destination_survey_sent';
export const CTX_STUDENT_PROFILE_COPILOT_VIEW_ACTION_PLAN =
  'ctx_student_profile_copilot_view_action_plan';
export const CTX_STUDENTS_QUIZ_EXPORT = 'ctx_students_quiz_export';
export const CTX_STUDENTS_TABLE_CAP_EXPORT = 'ctx_students_table_cap_export';
export const CTX_LESSON_DOWNLOADED = 'ctx_lesson_downloaded';
export const CTX_RESOURCE_DOWNLOADED = 'ctx_resource_downloaded';
export const CTX_CREATED_CALENDAR_EVENT = 'ctx_created_calendar_event';
export const CTX_PSP_COURSE_CAMPUS_MODAL_CLICKED = 'ctx_psp_course_campus_modal_clicked';
export const CTX_PSP_COURSE_FAVE_BTN = 'ctx_psp_course_fave_btn';
export const CTX_PSP_COURSE_EXPRESS_INTEREST_CLICKED = 'ctx_psp_course_express_interest_clicked';
export const CTX_PSP_COURSE_SEARCH_FILTERS = 'ctx_psp_course_search_filters';
export const CTX_PSP_CONFIDENCE_COURSE_SEARCH = 'ctx_psp_confidence_course_search';
export const CTX_CS_SEARCH_PAGE_LOADED = 'ctx_cs_search_page_loaded';
export const CTX_CS_SEARCH_KEYWORD_ENTERED = 'ctx_cs_search_keyword_entered';
export const CTX_CS_SHORTLIST_MAXED_OUT = 'ctx_cs_shortlist_maxed_out';
export const CTX_CS_COURSE_SHORTLISTED = 'ctx_cs_course_shortlisted';
export const CTX_CS_COURSE_SEE_MORE = 'ctx_cs_course_see_more';
export const CTX_IMPERSONATED_USER = 'ctx_impersonated_user';
export const CTX_LEAVE_IMPERSONATED_USER = 'ctx_leave_impersonated_user';
export const WAL_SIDEBAR_ICON_CLICKED = 'wal_sidebar_icon_clicked';
export const CTX_ACTIVITY_PILL_CLICKED = 'ctx_activity_pill_clicked';
export const CTX_PSP_MBTI_PROFILE_NEXT_CLICKED = 'ctx_psp_mbti_profile_next_clicked';
export const CTX_PSP_STARTED_QUIZ = 'ctx_psp_started_quiz';
export const CTX_PSP_POST_SCHOOL_QUESTION_CLICKED = 'ctx_psp_post_school_question_clicked';
export const CTX_PSP_LETS_BEGIN = 'ctx_psp_lets_begin';
export const CTX_PSP_KEY_STRENGTHS_NEXT_CLICKED = 'ctx_psp_key_strengths_next_clicked';
export const CTX_PSP_SKILLS_FORECAST_NEXT_CLICKED = 'ctx_psp_skills_forecast_next_clicked';
export const CTX_PSP_INTERESTS_SUBMIT = 'ctx_psp_interests_submit';
export const CTX_PSP_VALUES_SUBMIT = 'ctx_psp_values_submit';
export const CTX_PSP_SECTORS_SUBMIT = 'ctx_psp_sectors_submit';
export const CTX_PSP_SELECT_CAREER_SUBMIT = 'ctx_psp_select_career_submit';
export const CTX_PSP_CAP_STEP_DELETED = 'ctx_psp_cap_step_deleted';
export const CTX_PSP_COURSE_LEARN_MORE_CLICKED = 'ctx_psp_course_learn_more_clicked';
export const CTX_PSP_CONFIDENCE_COURSE_RECOMMENDATIONS =
  'ctx_psp_confidence_course_recommendations';
export const CTX_PSP_CAP_STEP_UPSERTED = 'ctx_psp_cap_step_upserted';
export const CTX_PSP_OCC_AI_RETRY_BTN = 'ctx_psp_occ_ai_retry_btn';
export const CTX_PSP_CAP_STEP_STATUS_CHANGED = 'ctx_psp_cap_step_status_changed';
export const CTX_PSP_CAP_REPLACE_YES = 'ctx_psp_cap_replace_yes';
export const CTX_PSP_CAP_ACTIVATED = 'ctx_psp_cap_activated';
export const CTX_PSP_CONFIRM_CAP_REPLACE_SEEN = 'ctx_psp_confirm_cap_replace_seen';
export const CTX_PSP_CAP_REPLACE_NO = 'ctx_psp_cap_replace_no';
export const CTX_PSP_ENTRY_START_BTN = 'ctx_psp_entry_start_btn';
export const CTX_PSP_VIEW_ACTION_PLAN_BTN = 'ctx_psp_view_action_plan_btn';
export const CTX_PSP_RESUME_BTN = 'ctx_psp_resume_btn';
export const CTX_PSP_OCC_FAVE_BTN = 'ctx_psp_occ_fave_btn';
export const CTX_PSP_OCC_CARD_LEARN_MORE = 'ctx_psp_occ_card_learn_more';
export const CTX_PSP_OCC_CARD_ACTIVE_ACTION_PLAN = 'ctx_psp_occ_card_active_action_plan';
export const CTX_PSP_CONFIRMED_QUIT = 'ctx_psp_confirmed_quit';
export const CTX_PSP_CONFIDENCE_ACTION_PLAN_SUBMITTED = 'ctx_psp_confidence_action_plan_submitted';
export const CTX_PSP_SIDEBAR_LINK_CLICKED = 'ctx_psp_sidebar_link_clicked';
export const CTX_REPORT_STUDENT_SEARCH = 'ctx_report_student_search';
export const WAL_ITEM_STARTED = 'wal_item_started';
export const WAL_ITEM_SAVED = 'wal_item_saved';
export const CTX_PASS_PROFILE_UPDATED = 'ctx_pass_profile_updated';
export const CTX_PASS_SHARE_LINK_CREATED = 'ctx_pass_share_link_created';
export const DOWNLOADED_COVERLETTER = 'downloaded_coverletter';
export const DOWNLOADED_RESUME = 'downloaded_resume';
export const DOWNLOADED_RESUME_WORD = 'downloaded_resume_word';
export const DOWNLOADED_CUSTOMRESUME = 'downloaded_customresume';
export const DOWNLOADED_CUSTOMRESUME_WORD = 'downloaded_customresume_word';
export const CTX_DOCUMENT_UPLOADED = 'ctx_document_uploaded';
export const CTX_CLICKED_REPORT_EXPORT = 'ctx_clicked_report_export';
export const CTX_PSP_OCC_SEARCH_BUTTON = 'ctx_psp_occ_search_button';
export const CTX_PSP_OCC_KEYWORD_SEARCH = 'ctx_psp_occ_keyword_search';
export const CTX_PSP_EXPLORE_PAGINATION_BTN = 'ctx_psp_explore_pagination_btn';
export const CTX_PSP_OCC_SEE_MORE = 'ctx_psp_occ_see_more';
export const CTX_PSP_OCC_SEE_ALL_FAVE = 'ctx_psp_occ_see_all_fave';
export const CTX_PSP_CONFIDENCE_RESPONSE_SUBMITTED = 'ctx_psp_confidence_response_submitted';
export const CTX_VIEWED_PORTFOLIO = 'ctx_viewed_portfolio';
export const CTX_REPORTING_STUDENT_CAP_EXPORT = 'ctx_reporting_student_cap_export';
export const CTX_CREATED_CUSTOM_GROUP = 'ctx_created_custom_group';
export const CTX_EMAIL_FEATURE_SENT = 'ctx_email_feature_sent';
