/* eslint-disable no-shadow */

export const state = () => ({
  content_cards: [],
});

export const mutations = {
  setContentCards: (state, cards) => {
    state.content_cards = cards;
  },
};
