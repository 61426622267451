import { computed, useStore } from '@nuxtjs/composition-api';

export default function useOverlayLoader() {
  const store = useStore();

  const overlayLoaderVisible = computed(() => store.state.overlayLoaderVisible);

  const showOverlayLoader = () => {
    store.commit('showOverlayLoader');
  };
  const hideOverlayLoader = () => {
    store.commit('hideOverlayLoader');
  };

  return { overlayLoaderVisible, showOverlayLoader, hideOverlayLoader };
}
