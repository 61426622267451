import { useContext, ref, onMounted, useStore } from '@nuxtjs/composition-api';

export default function useAuthCheck() {
  const { $cookies, $auth } = useContext();
  const store = useStore();
  const isImpersonatingInNewTab = ref(false);
  const hasAuthChanged = ref(false);

  const setupImpersonateCheck = () => {
    setInterval(() => {
      const authImpersonateUserId = $cookies.get('auth.impersonate.user_id');

      if (
        store.state.impersonate.enteringImpersonate ||
        store.state.impersonate.leavingImpersonate ||
        !$auth.$state?.user?.id
      ) {
        isImpersonatingInNewTab.value = false;
        return;
      }

      if (!authImpersonateUserId) {
        isImpersonatingInNewTab.value = false;
        return;
      }

      if (authImpersonateUserId === parseInt($auth.$state.user.id, 10)) {
        isImpersonatingInNewTab.value = false;
        return;
      }

      isImpersonatingInNewTab.value = true;
    }, 2000);
  };

  const setupAuthChangedCheck = () => {
    setInterval(() => {
      const authCookieId = $cookies.get('auth.user_id');
      const authImpersonateUserId = $cookies.get('auth.impersonate.user_id');

      if (
        store.state.impersonate.enteringImpersonate ||
        store.state.impersonate.leavingImpersonate ||
        !$auth.$state?.user?.id ||
        !authCookieId
      ) {
        hasAuthChanged.value = false;
        return;
      }

      if (authImpersonateUserId) {
        hasAuthChanged.value = false;
        return;
      }

      if (authCookieId === parseInt($auth.$state.user.id, 10)) {
        hasAuthChanged.value = false;
        return;
      }

      hasAuthChanged.value = true;
    }, 2000);
  };

  onMounted(() => {
    if (process.client) {
      setupImpersonateCheck();
      setupAuthChangedCheck();
    }
  });

  return { isImpersonatingInNewTab, hasAuthChanged };
}
