import { ref, useContext, onMounted, onUnmounted, useStore } from '@nuxtjs/composition-api';
import dayjs from '@/utils/dayjs';
import useCountdownTimer from './countdown';

export default function useSessionTimeoutModal() {
  const { $cookies, $config, $auth } = useContext();

  const showModal = ref(false);
  const userType = $cookies.get('auth.user_type');
  const { formattedCountdown, startTimer, clearTimer, onCountdownDone } = useCountdownTimer();

  let checkToShowModalInterval;
  const checkToShowModal = () => {
    checkToShowModalInterval = setInterval(() => {
      if ($cookies.get('auth.expires')) {
        const getExpiryAuthTime = $cookies.get('auth.expires');
        const getCurrentTime = dayjs().unix();
        const remainingTimeToExpire = getExpiryAuthTime - getCurrentTime;

        if (remainingTimeToExpire <= 300) {
          startTimer(remainingTimeToExpire);
          clearInterval(checkToShowModalInterval);
          showModal.value = true;
        }
      }
    }, 30 * 1000);
  };

  const { state } = useStore();

  const keepMeLoggedIn = () => {
    const newExpires =
      userType === 'teacher'
        ? dayjs().unix() + state.expireTimeTeacher
        : dayjs().unix() + state.expireTimeStudent;
    $cookies.set('auth.expires', newExpires, { domain: $config.cookieDomain, path: '/' });
    clearTimer();
    clearInterval(checkToShowModalInterval);
    checkToShowModal();
    showModal.value = false;
  };

  const logMeOut = async () => {
    clearInterval(checkToShowModalInterval);
    clearTimer();
    showModal.value = false;
    await $auth.safeLogout();
  };

  onCountdownDone(() => {
    logMeOut();
  });

  onMounted(() => {
    checkToShowModal();
  });

  onUnmounted(() => {
    if (checkToShowModalInterval) {
      clearInterval(checkToShowModalInterval);
    }
    clearTimer();
  });

  return {
    checkToShowModal,
    formattedCountdown,
    showModal,
    keepMeLoggedIn,
    logMeOut,
  };
}
