<template>
  <component
    :is="to ? 'NuxtLink' : 'button'"
    :to="to"
    type="button"
    @click="$emit('click')"
    class="link no-style"
    :class="{ 'is-first': isFirst, 'is-last': isLast }"
  >
    <div class="py-2 px-4 d-flex align-items-center">
      <IconDefault class="me-3" :width="30" :height="30" :path="iconPath" />
      <div>{{ text }}</div>
    </div>
  </component>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import IconDefault from '@/components/icon/Default';

export default defineComponent({
  components: {
    IconDefault,
  },
  props: {
    to: {
      type: String,
      required: false,
    },
    iconPath: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.link {
  width: 100%;
  text-decoration: none;
  font-weight: 500;
  color: $primary;
  outline-offset: -2px !important;

  &.is-first {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &.is-last {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &:hover {
    background-color: $cloud-blue;
  }
}
</style>
