<template>
  <li
    :class="{
      active: isActive,
    }"
  >
    <component
      :is="to ? 'NuxtLink' : 'button'"
      :to="to"
      type="button"
      @click.native="$emit('click')"
      class="link w-100"
    >
      <div class="link-contents d-flex align-items-center" :class="{ expanded }">
        <component :is="iconComponent" :class="expanded && 'me-2'" :width="30" />
        <Transition name="fade">
          <div v-show="!transitioning && expanded">{{ text }}</div>
        </Transition>
      </div>
    </component>
  </li>
</template>

<script>
import { defineComponent, computed, useRoute } from '@nuxtjs/composition-api';
import IconDefault from '@/components/icon/Default';

export default defineComponent({
  components: {
    IconDefault,
  },
  props: {
    to: {
      required: false,
    },
    iconComponent: {
      type: Function,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    activeRoutes: {
      type: Array,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
    transitioning: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();

    const isActive = computed(() => {
      return props.activeRoutes.includes(route.value.name);
    });

    return { isActive };
  },
});
</script>

<style lang="scss" scoped>
.link {
  text-decoration: none;
  font-weight: 700;
  color: inherit;

  outline-offset: -2px !important;

  .link-contents {
    justify-content: center;
    padding: 15px 0px;

    &.expanded {
      @include m-medium {
        padding: 15px 20px;
        justify-content: left;
      }
    }
  }
}

li {
  &:hover {
    background-color: darken($primary, 10%);
  }

  color: white;

  &.active {
    background-color: white;
    color: $primary;
  }
}

.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-enter {
  opacity: 0;
}
</style>
