export const getHost = (context) => {
  if (process.server) {
    return context?.req?.headers?.host;
  }

  if (process.client) {
    return window?.location?.host;
  }

  return null;
};

export const getUserAgent = (context) => {
  if (process.server) {
    return context?.req?.headers?.['user-agent'];
  }

  if (process.client) {
    return window?.navigator?.userAgent;
  }

  return null;
};

export const getPath = (context) => {
  if (process.server) {
    return context?.req?.url;
  }

  if (process.client) {
    return window?.location?.pathname;
  }

  return null;
};

export const isHealthCheckRequest = (context) =>
  ['Edge Health Probe', 'HealthCheck/1.0'].includes(getUserAgent(context)) ||
  getPath(context).startsWith('/healthcheck');

export const getDomainConfig = (context, config) => {
  const { careertoolsDomain } = config;

  const host = getHost(context) || careertoolsDomain;

  const domainHostIndex = host.indexOf(careertoolsDomain);
  const wwwDomainHostIndex = host.indexOf(`www.${careertoolsDomain}`);

  const isRootDomain = domainHostIndex === 0 || wwwDomainHostIndex === 0;

  const schoolDomain = isRootDomain ? null : host;

  const isSchoolSubdomain = !isRootDomain && host.indexOf(`.${careertoolsDomain}`) !== -1;

  // slug is everything before .{careertoolsDomain}
  const schoolSubDomainSlug = isSchoolSubdomain ? host.substr(0, domainHostIndex - 1) : null;

  return {
    careertoolsDomain,
    isRootDomain,
    schoolDomain,
    isSchoolSubdomain,
    schoolSubDomainSlug,
  };
};
