<template>
  <div>
    <div class="bg-top-wrapper">
      <div class="bg-top"></div>
    </div>
    <div v-if="includeBottomBlob" class="bg-bottom-wrapper"><div class="bg-bottom"></div></div>
  </div>
</template>

<script>
export default {
  props: {
    includeBottomBlob: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-top-wrapper {
  position: absolute;
  width: calc(100vw - (100vw - 100%));
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
}

.bg-top {
  position: absolute;
  width: 50%;
  height: 50%;
  top: -30%;
  right: -25%;
  border-radius: 50%;
  background: linear-gradient(134deg, rgba(97, 0, 255, 0.85) 0%, #00d5fc 100%);
  filter: blur(7vw);
  z-index: -1;
}

.bg-bottom-wrapper {
  position: absolute;
  width: calc(100vw - (100vw - 100%));
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.bg-bottom {
  position: absolute;
  width: 50%;
  height: 50%;
  top: 95%;
  left: -25%;
  border-radius: 50%;
  background: linear-gradient(0deg, rgba(97, 0, 255, 0.85) 0%, #00d5fc 100%);
  filter: blur(7vw);
  z-index: -1;
}
</style>
