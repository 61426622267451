<template>
  <div>
    <button
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
      @click="$emit('click', $event)"
      class="nav-menu-button d-flex align-items-center"
      :class="{ 'is-active': isActive }"
    >
      <div class="me-3">{{ menuItem.text }}</div>
      <RightChevronIcon
        class="chevron"
        :class="isActive && 'rotate'"
        :width="8"
        :height="10"
        color="white"
      />
    </button>
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import RightChevronIcon from '@/components/icon/inline/ChevronRightAlternate';
import useSchool from '@/hooks/school';

export default defineComponent({
  components: { RightChevronIcon },
  props: {
    menuItem: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
    },
  },
  setup() {
    const { websiteThemeColor } = useSchool();
    const hovered = ref(false);

    return { websiteThemeColor, hovered };
  },
});
</script>

<style lang="scss" scoped>
.chevron {
  &.rotate {
    transform: rotate(90deg);
  }
}

.nav-menu-button {
  background: none;
  border: none;
  font: inherit;
  outline: inherit;
  font-size: toRem(16);
  font-weight: 700;
  border-radius: 8px;
  text-align: left;
  width: 100%;

  color: white;
  background-color: $school-theme-color;
  padding: 12px 8px;

  &:hover {
    opacity: 0.8;
    color: white;
    background-color: $school-theme-color;
  }
}
</style>
