import { ROLE_TYPE_TEACHER } from '@/constants/role_types';
import { ROLE_TEACHER_PRIMARY } from '@/constants/roles';

// adapted from https://github.com/nuxt-community/auth-module/blob/dev/src/core/middleware.ts
function getMatchedComponents(route, matches = []) {
  return [].concat(
    ...[],
    ...route.matched.map((m, index) =>
      Object.keys(m.components).map((key) => {
        matches.push(index);
        return m.components[key];
      }),
    ),
  );
}

const ONBOARDING_ROUTE = '/onboarding';

export default async ({ $auth, route, redirect, $cookies, store }) => {
  if (!$auth.user) {
    return;
  }

  // If no components matched we're getting a 404 / error, we let them through
  const components = getMatchedComponents(route);
  if (!components.length) {
    return;
  }

  // If auth disabled for route, let them through
  if (typeof components[0].options.auth !== 'undefined' && !components[0].options.auth) {
    return;
  }

  // if the school is free tier, don't need to onboard
  if (store.getters.school.pricing_tier === 'free') {
    return;
  }

  // user onboarded or impersonating, all good
  if ($auth.user.onboarded || $auth.isImpersonating || $cookies.get('auth.is_impersonating')) {
    return;
  }

  // Dont redirect if unverified as the verified middleware will be in effect
  if (!$auth.user.inUseAuthMethod.verified) {
    return;
  }

  // Only force onboarding for verified students/primary teachers
  if (!$auth.user?.currentOrgRole?.role_type) {
    return;
  }

  if (
    $auth.user.currentOrgRole.role_type === ROLE_TYPE_TEACHER &&
    $auth.user.currentOrgRole.role !== ROLE_TEACHER_PRIMARY
  ) {
    return;
  }

  // teachers with a school w/ no manual accounts + no school website have no reason to do onboarding
  if (
    $auth.user?.currentOrgRole?.role_type === ROLE_TYPE_TEACHER &&
    !store.getters.schoolPermissions['manual-accounts'] &&
    !store.getters.schoolPermissions.website
  ) {
    return;
  }

  // already redirected
  if (route.path === ONBOARDING_ROUTE) {
    return;
  }

  redirect(ONBOARDING_ROUTE);
};
