import { computed, useRoute, useContext } from '@nuxtjs/composition-api';
import useAuth from '@/hooks/auth';

export default function useloggerContext() {
  const { user } = useAuth();
  const route = useRoute();
  const { $config } = useContext();
  return computed(() => {
    return {
      appContext: {
        userId: user.value?.id || '',
        orgId: user.value?.currentOrgRole?.org_id || '',
        orgRoleId: user.value?.currentOrgRole?.id || '',
        path: route.value.fullPath,
        origin: $config.originDomain,
      },
    };
  });
}
