<template>
  <div class="d-none d-lg-flex justify-content-end align-items-center">
    <Dropdown
      :shown="isMenuItemActive(rootMenuItem.slug, 0)"
      v-on:update:shown="updateMenuItemState(rootMenuItem.slug, 0, $event)"
      class="dropdown-wrapper"
      popperClass="d-none d-lg-flex"
      v-for="rootMenuItem in menuItems"
      :key="`root-menu-item-${rootMenuItem.id}`"
    >
      <button class="root-nav-menu-button">
        {{ rootMenuItem.text }}
      </button>
      <template #popper>
        <div class="dropdown-content p-3">
          <div class="nav-level-column level-one">
            <div
              :key="`level-one-nav-${levelOneMenuItem.id}`"
              class="nav-item"
              v-for="levelOneMenuItem in rootMenuItem.children"
            >
              <DesktopNavMenu
                @click="toggleMenuItemState(levelOneMenuItem.slug, 1)"
                :isActive="isMenuItemActive(levelOneMenuItem.slug, 1)"
                :levelIsActive="activeLevel === 1"
                v-if="levelOneMenuItem.instance_type === 'sub-menu'"
                :menuItem="levelOneMenuItem"
              />
              <DesktopNavLeaf
                :levelIsActive="activeLevel === 1"
                @click.capture="clearActiveNavSlugs"
                v-else
                :menuItem="levelOneMenuItem"
              />
            </div>
          </div>
          <div class="divider show-divider"></div>
          <template v-if="activeLevel === 1">
            <div class="level-one-info-container d-flex justify-content-center align-items-center">
              <div class="level-one-info">
                <img
                  class="img-responsive level-one-info-img mb-4"
                  :src="`${$config.publicObjectStorageUrl}/${rootMenuItem.instance.image_url}`"
                />
                <div class="level-one-info-title mb-4">{{ rootMenuItem.text }}</div>
                <div v-dompurify-html="rootMenuItem.instance.description"></div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="nav-level-column level-two">
              <template v-if="selectedLevelOneItem">
                <div class="column-title">{{ selectedLevelOneItem.text }}</div>
                <div
                  :key="`level-two-nav-${levelTwoMenuItem.id}`"
                  class="nav-item"
                  v-for="levelTwoMenuItem in selectedLevelOneItem.children"
                >
                  <DesktopNavMenu
                    :levelIsActive="activeLevel === 2"
                    @click="toggleMenuItemState(levelTwoMenuItem.slug, 2)"
                    :isActive="isMenuItemActive(levelTwoMenuItem.slug, 2)"
                    v-if="levelTwoMenuItem.instance_type === 'sub-menu'"
                    :menuItem="levelTwoMenuItem"
                  />
                  <DesktopNavLeaf
                    :levelIsActive="activeLevel === 2"
                    @click.capture="clearActiveNavSlugs"
                    v-else
                    :menuItem="levelTwoMenuItem"
                  />
                </div>
              </template>
            </div>
            <div class="divider" :class="activeLevel === 3 && 'show-divider'"></div>
            <div class="nav-level-column level-three">
              <template v-if="selectedLevelTwoItem">
                <div class="column-title">{{ selectedLevelTwoItem.text }}</div>
                <div
                  :key="`level-three-nav-${selectedLevelThreeItem.id}`"
                  class="nav-item"
                  v-for="selectedLevelThreeItem in selectedLevelTwoItem.children"
                >
                  <DesktopNavLeaf
                    :levelIsActive="activeLevel === 3"
                    @click.capture="clearActiveNavSlugs"
                    :menuItem="selectedLevelThreeItem"
                  />
                </div>
              </template>
            </div>
          </template>
        </div>
      </template>
    </Dropdown>
    <Button
      class="ms-3"
      size="md"
      variant="filled"
      userType="school-theme"
      :to="$auth.user ? '/login' : '/dashboard'"
    >
      {{ $auth.user ? 'Secure Area' : 'Login' }}
    </Button>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import Button from '@/components/Button';
import { Dropdown } from 'v-tooltip';
import DesktopNavMenu from '@/components/schoolWebsite/layout/navigation/desktop/NavMenu';
import DesktopNavLeaf from '@/components/schoolWebsite/layout/navigation/desktop/NavLeaf';
import useSchoolWebsiteNavigation from '@/hooks/schoolWebsiteNavigation';

export default defineComponent({
  components: {
    Button,
    Dropdown,
    DesktopNavMenu,
    DesktopNavLeaf,
  },
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const {
      schoolWebsiteActiveNavSlugs,
      isMenuItemActive,
      updateMenuItemState,
      toggleMenuItemState,
      clearActiveNavSlugs,
      selectedLevelZeroItem,
      selectedLevelOneItem,
      selectedLevelTwoItem,
      activeLevel,
    } = useSchoolWebsiteNavigation();

    return {
      isMenuItemActive,
      updateMenuItemState,
      schoolWebsiteActiveNavSlugs,
      toggleMenuItemState,
      clearActiveNavSlugs,
      selectedLevelZeroItem,
      selectedLevelOneItem,
      selectedLevelTwoItem,
      activeLevel,
    };
  },
});
</script>

<style lang="scss" scoped>
.level-one-info-container {
  width: 661px;
}

.level-one-info {
  width: 400px;
  margin: 50px 0px;
  .level-one-info-title {
    font-size: 20px;
    color: $school-theme-color;
    font-weight: 700;
  }

  .level-one-info-img {
    width: 100%;
    border-radius: 16px;
  }
}

.column-title {
  font-size: 18px;
  color: #9d9d9d;
  padding: 0px 8px;
  margin-bottom: 1rem;
  font-weight: 700;
}

.divider {
  width: 1px;
  height: auto;
  margin: 0px 30px;

  &.show-divider {
    background-color: #dadada;
  }
}

.dropdown-content {
  display: flex;
  padding: 10px 0px;
}

.dropdown-wrapper {
  width: auto;
}

.nav-item {
  display: flex;
}

.root-nav-menu-button {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  outline: inherit;
  padding: 10px 20px;
  font-weight: 700;
  overflow-wrap: break-word;

  color: $school-theme-color;

  &:hover {
    color: $school-theme-color-darker;
  }
}

.nav-level-column {
  display: flex;
  flex-direction: column;
  width: 300px;
}
</style>
