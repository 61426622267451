<template>
  <component :is="size" :class="{ [styleSize || size]: true, 'visually-hidden': visuallyHidden }">
    <slot></slot>
  </component>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    size: {
      type: String,
      required: true,
      validator: (v) => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].indexOf(v) !== -1,
    },
    styleSize: {
      type: String,
      required: false,
      validator: (v) => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].indexOf(v) !== -1,
    },
    visuallyHidden: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped></style>
