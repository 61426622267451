import { computed, useContext, useStore } from '@nuxtjs/composition-api';
import gql from 'graphql-tag';
import { CONFIG_SCHOOL_FIELDS } from '@/graphql/fragments/school';
import { PRICING_TIER_FREE } from '@/constants/pricingTiers';
import { COUNTRY_CODE_USA } from '@/constants/countries';

const GET_SCHOOL_BY_ID = gql`
  ${CONFIG_SCHOOL_FIELDS}
  query school($id: ID!) {
    school(id: $id) {
      ...ConfigSchoolFields
    }
  }
`;

export default function useSchool() {
  const store = useStore();
  const { app } = useContext();

  const school = computed(() => {
    if (!store.getters.school) {
      return null;
    }

    return {
      ...store.getters.school,
      settings: store.getters.school.settings ? JSON.parse(store.getters.school.settings) : {},
      enabled_test_features: store.getters.schoolTestPermissions,
    };
  });

  const websiteThemeColor = computed(() => school.value?.website_theme_color ?? '#332e2e');

  const navMenuItems = computed(() => store.getters.schoolNavMenuItems);
  const allMenuItems = computed(() => store.state.school.menu.menuItems);

  const hasSchoolPermission = (key) => store.getters.schoolPermissions[key] || false;

  const hasFeaturePermission = (featureGroup, metaKey, key) => {
    const meta = store.getters.schoolFeaturePermissionMeta[featureGroup] || null;

    if (!meta) {
      return true;
    }

    if (featureGroup === 'advanced_widgets') {
      return meta[metaKey]?.includes(key) ? hasSchoolPermission(featureGroup) : true;
    }

    if (featureGroup === 'advanced_website_communications') {
      return meta[metaKey]?.includes(key) ? hasSchoolPermission(featureGroup) : true;
    }

    if (featureGroup === 'download_doc') {
      return meta[metaKey]?.includes(key) ? true : hasSchoolPermission(featureGroup);
    }

    return true;
  };

  const hasTestFeatureEnabled = (key) => {
    if (key === 'course_search_v2') {
      // Course Search v2 can only be enabled if both course_search_v2 and cap_and_wallet are enabled
      // Course Search v2 is disabled for the US (i.e. free tier)
      return (
        Boolean(school.value.enabled_test_features.course_search_v2) &&
        Boolean(school.value.enabled_test_features.cap_and_wallet) &&
        school.value.country.code !== COUNTRY_CODE_USA
      );
    }

    return Boolean(school.value.enabled_test_features[key]);
  };

  const schoolCountryLocale = computed(() => {
    return store.state.school?.country?.locale || 'en-AU';
  });

  const refreshSchoolConfig = async () => {
    const res = await app.apolloProvider.defaultClient.query({
      query: GET_SCHOOL_BY_ID,
      variables: {
        id: school.value.id,
      },
    });

    store.commit('setSchool', res.data.school);
  };

  const isClusterSchool = computed(() => {
    if (!school.value) {
      return null;
    }

    return Boolean(school.value.school_cluster_id);
  });

  const isFreeTierSchool = computed(() => {
    if (!school.value) {
      return false;
    }

    return school.value.pricing_tier === PRICING_TIER_FREE;
  });

  const isCareerPassSchool = computed(() => {
    if (!school.value) {
      return false;
    }

    return hasTestFeatureEnabled('cap_and_wallet');
  });

  return {
    school,
    websiteThemeColor,
    navMenuItems,
    hasSchoolPermission,
    hasFeaturePermission,
    refreshSchoolConfig,
    hasTestFeatureEnabled,
    allMenuItems,
    isCareerPassSchool,
    isClusterSchool,
    isFreeTierSchool,
    schoolCountryLocale,
  };
}
