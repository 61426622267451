/* eslint-disable no-shadow */

export const state = () => ({
  credentialTemplates: null,
});

export const mutations = {
  setCredentialTemplates: (state, credentialTemplates) => {
    state.credentialTemplates = credentialTemplates;
  },
};

export const getters = {
  getCredentialTemplates: (state) => state.credentialTemplates,
  getCredentialTemplate: (state, id) => {
    return state.credentialTemplates ? state.credentialTemplates.find((c) => c.id === id) : null;
  },
};
