import { useStore, computed } from '@nuxtjs/composition-api';
import { findMenuItemByFullSlug } from '@/utils/navMenu';

export default function useSchool() {
  const store = useStore();

  const schoolWebsiteActiveNavSlugs = computed(() => store.state.ui.schoolWebsiteActiveNavSlugs);

  const isMenuItemActive = (slug, level) => schoolWebsiteActiveNavSlugs.value[level] === slug;

  const updateMenuItemState = (slug, level, visible) => {
    if (visible) {
      const newActiveSlugs = schoolWebsiteActiveNavSlugs.value.slice(0, level + 1);
      newActiveSlugs[level] = slug;
      store.commit('ui/setSchoolWebsiteActiveNavSlugs', newActiveSlugs);
      return;
    }

    if (!isMenuItemActive(slug, level)) {
      return; // already disabled
    }

    const newActiveSlugs = schoolWebsiteActiveNavSlugs.value.slice(0, level);
    store.commit('ui/setSchoolWebsiteActiveNavSlugs', newActiveSlugs);
  };

  const toggleMenuItemState = (slug, level) => {
    if (isMenuItemActive(slug, level)) {
      updateMenuItemState(slug, level, false);
      return;
    }
    updateMenuItemState(slug, level, true);
  };

  const clearActiveNavSlugs = () => {
    store.commit('ui/setSchoolWebsiteActiveNavSlugs', []);
  };

  const selectedLevelZeroItem = computed(() => {
    if (schoolWebsiteActiveNavSlugs.value.length < 1) {
      return null;
    }
    const fullSlug = schoolWebsiteActiveNavSlugs.value.slice(0, 1).join('/');
    return findMenuItemByFullSlug(fullSlug, store.getters.schoolNavMenuItems);
  });

  const selectedLevelOneItem = computed(() => {
    if (schoolWebsiteActiveNavSlugs.value.length < 2) {
      return null;
    }
    const fullSlug = schoolWebsiteActiveNavSlugs.value.slice(0, 2).join('/');
    return findMenuItemByFullSlug(fullSlug, store.getters.schoolNavMenuItems);
  });

  const selectedLevelTwoItem = computed(() => {
    if (schoolWebsiteActiveNavSlugs.value.length < 3) {
      return null;
    }
    const fullSlug = schoolWebsiteActiveNavSlugs.value.slice(0, 3).join('/');
    return findMenuItemByFullSlug(fullSlug, store.getters.schoolNavMenuItems);
  });

  const activeLevel = computed(() => schoolWebsiteActiveNavSlugs.value.length);

  return {
    schoolWebsiteActiveNavSlugs,
    isMenuItemActive,
    updateMenuItemState,
    toggleMenuItemState,
    clearActiveNavSlugs,
    selectedLevelZeroItem,
    selectedLevelOneItem,
    selectedLevelTwoItem,
    activeLevel,
  };
}
