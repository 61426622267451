<template>
  <Loader class="overlay-loader" :width="75" :height="75" />
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import Loader from '@/components/loaders/Loader';

export default defineComponent({
  components: {
    Loader,
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.overlay-loader {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}
</style>
