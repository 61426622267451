import { ROLE_TYPE_TEACHER, ROLE_TYPE_STUDENT } from '@/constants/role_types';
import { ROLE_TEACHER_PRIMARY } from '@/constants/roles';

/* eslint-disable no-param-reassign */
export default ({ $auth, $cookies, store, $config, redirect }) => {
  const impersonate = async (roleType, userId, currentPath) => {
    store.commit('impersonate/setEnteringImpersonate', true);
    $cookies.set('auth.impersonate.impersonator_id', $auth.user.id, {
      domain: $config.cookieDomain,
      path: '/',
    });
    $cookies.set('auth.impersonate.user_id', userId, { domain: $config.cookieDomain, path: '/' });
    $cookies.set('auth.impersonate.role_type', roleType, {
      domain: $config.cookieDomain,
      path: '/',
    });
    $cookies.set('auth.impersonate.return_path', currentPath, {
      domain: $config.cookieDomain,
      path: '/',
    });
    $cookies.set('auth.is_impersonating', true, { domain: $config.cookieDomain, path: '/' });

    $auth.isImpersonating = true;

    await $auth.fetchUser();

    if (currentPath === '/dashboard') {
      store.commit('impersonate/setEnteringImpersonate', false);
    } else {
      // We set setEnteringImpersonate to false in a middleware after redirect
      redirect('/dashboard');
    }
  };

  $auth.impersonateStudent = async (userId, currentPath) => {
    store.dispatch('psp/resetPspState');
    await impersonate('student', userId, currentPath);
  };

  $auth.isImpersonating = Boolean($cookies.get('auth.impersonate.user_id'));

  $auth.onRedirect((to, from) => {
    if (to === '/' && store.state.school.sso_provider === 'sa-dfe-okta') {
      return 'https://studentpathways.sa.edu.au';
    }

    if (from === '/login') {
      // don't trigger any when impersonating
      if ($auth.isImpersonating || $cookies.get('auth.is_impersonating')) {
        return to;
      }

      if ($auth.user && !$auth.user.inUseAuthMethod.verified) {
        return '/unverified';
      }

      if ($auth.user && !$auth.user.onboarded && !store.getters.school.pricing_tier === 'free') {
        if (
          ($auth.user.currentOrgRole.role_type === ROLE_TYPE_TEACHER &&
            $auth.user.currentOrgRole.role_type === ROLE_TEACHER_PRIMARY) ||
          $auth.user.currentOrgRole.role_type === ROLE_TYPE_STUDENT
        ) {
          store.commit('setPostOnboardingRedirect', to);
          return '/onboarding';
        }
      }
    }
    return to;
  });

  $auth.leaveImpersonate = async (currentPath) => {
    store.commit('impersonate/setLeavingImpersonate', true);
    store.dispatch('psp/resetPspState');
    let redirectPath = $cookies.get('auth.impersonate.return_path');
    await $cookies.remove('auth.impersonate.impersonator_id', {
      domain: $config.cookieDomain,
      path: '/',
    });
    await $cookies.remove('auth.impersonate.user_id', { domain: $config.cookieDomain, path: '/' });
    await $cookies.remove('auth.impersonate.return_path', {
      domain: $config.cookieDomain,
      path: '/',
    });
    await $cookies.remove('auth.is_impersonating', { domain: $config.cookieDomain, path: '/' });
    await $cookies.remove('auth.impersonate.role_type', {
      domain: $config.cookieDomain,
      path: '/',
    });

    $auth.isImpersonating = false;

    await $auth.fetchUser();

    if (!redirectPath) {
      redirectPath = store.getters.school.pricing_tier === 'free' ? '/dashboard' : '/students';
    }

    if (currentPath === redirectPath) {
      store.commit('impersonate/setLeavingImpersonate', false);
    } else {
      redirect(redirectPath);
      // We set setLeavingImpersonate to false in a middleware after redirect
    }
  };

  $auth.safeLogout = async () => {
    store.commit('setLoggingOut', true);
    await $auth.logout();
  };
};
