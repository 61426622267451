import { onMounted, onBeforeUnmount } from '@nuxtjs/composition-api';
import useEventCallback from '@/hooks/eventCallback';

export default function useOutsideClick(elRef) {
  const { register, trigger } = useEventCallback();

  const handleClickOutside = (event) => {
    if (!elRef.value) {
      return;
    }

    if (elRef.value.contains(event.target)) {
      return;
    }

    trigger();
  };

  onMounted(() => {
    document.addEventListener('click', handleClickOutside);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
  });

  return {
    onOutsideClick: register,
  };
}
