<template>
  <FullPageError
    title="There was a problem loading this website"
    subTitle="Please try again later. If this problem persists, please contact support."
  />
</template>

<script>
import { defineComponent, onMounted, useContext } from '@nuxtjs/composition-api';
import FullPageError from '@/components/layout/FullPageError';

export default defineComponent({
  components: {
    FullPageError,
  },
  props: {
    error: {
      required: true,
    },
  },
  setup(props) {
    const { $logger } = useContext();
    onMounted(() => {
      $logger.error(`SCHOOL FETCH ERROR: ${props.error}`);
    });
  },
});
</script>
