<template>
  <footer>
    <div class="upper-footer py-5">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 d-flex flex-column align-items-start mb-5 mb-xl-0">
            <h4 class="mb-3 text-white">Contact Details</h4>
            <div class="footer-contact-details" v-dompurify-html="school.website_footer_text"></div>
          </div>
          <div class="col-xl-6 d-flex flex-column align-items-start align-items-xl-end">
            <div
              v-if="school.website_facebook || school.website_twitter || school.website_instagram"
            >
              <h4 class="mb-3 text-white">Connect With Us</h4>
              <div class="social-icons d-flex justify-content-lg-end">
                <IconButton
                  v-if="school.website_facebook"
                  :to="school.website_facebook"
                  :isExternal="true"
                  path="social-facebook"
                  :width="30"
                />
                <IconButton
                  v-if="school.website_twitter"
                  :to="school.website_twitter"
                  :isExternal="true"
                  path="social-twitter"
                  :width="30"
                />
                <IconButton
                  v-if="school.website_instagram"
                  :to="school.website_instagram"
                  :isExternal="true"
                  path="social-instagram"
                  :width="30"
                />
              </div>
            </div>
            <div
              class="text-start text-lg-end"
              :class="{
                'mt-3':
                  school.website_facebook || school.website_twitter || school.website_instagram,
              }"
            >
              <h5 class="text-white">Powered by</h5>
              <Button
                :isExternal="true"
                :openNewTab="true"
                :to="`https://${$config.careertoolsDomain}`"
                variant="link"
                userType="business"
                aria-label="click to visit careertools platform"
                class="border-bottom-0"
              >
                <IconDefault :width="200" path="logo-white" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lower-footer py-3">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 d-flex text-center justify-content-center justify-content-xl-start">
            Copyright &copy; {{ year }} {{ school.name }}. All rights reserved.
          </div>
          <div
            class="col-xl-6 order-first order-xl-last d-flex justify-content-center justify-content-xl-end mb-2 mb-xl-0"
          >
            <NuxtLink :to="{ name: 'terms-policy-website' }">Terms & Privacy Policy</NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { defineComponent, useContext } from '@nuxtjs/composition-api';
import useSchool from '@/hooks/school';
import IconButton from '@/components/icon/Button';
import IconDefault from '@/components/icon/Default';
import Button from '@/components/Button';

export default defineComponent({
  components: {
    Button,
    IconButton,
    IconDefault,
  },
  name: 'footer-component',
  setup() {
    const { school } = useSchool();
    const year = new Date().getFullYear();
    const { $config } = useContext();

    const toRoot = () => {
      window.open(`https://${$config.careertoolsDomain}`, '_blank');
    };

    return { year, school, toRoot };
  },
});
</script>

<style lang="scss" scoped>
footer {
  .upper-footer {
    color: white;
    background-color: #3b3b3b;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .footer-contact-details {
      color: white;

      ::v-deep p {
        color: white;
      }
    }

    .social-icons {
      :not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  .lower-footer {
    background-color: $school-theme-color;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    a {
      color: $white;
      text-decoration: none;
    }
  }
}
</style>
