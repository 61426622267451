<template>
  <IconDefault :path="logoPath" :width="width" :height="height" />
</template>

<script>
import IconDefault from '@/components/icon/Default';
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { PLATFORM_CAREERPASS, PLATFORM_CAREERTOOLS } from '@/constants/platforms';
import usePlatform from '@/hooks/platform';

const logoPaths = {
  [PLATFORM_CAREERTOOLS]: {
    'iconmark-light': 'platform/careertools/iconmark-light',
    'iconmark-dark': 'platform/careertools/iconmark-dark',
    'iconmark-mixed': 'platform/careertools/iconmark-mixed',
    'lockup-primary-light': 'platform/careertools/lockup-primary-light',
    'lockup-primary-dark': 'platform/careertools/lockup-primary-dark',
    'lockup-secondary-light': 'platform/careertools/lockup-secondary-light',
    'lockup-secondary-dark': 'platform/careertools/lockup-secondary-dark',
  },
  [PLATFORM_CAREERPASS]: {
    'iconmark-light': 'platform/careerpass/iconmark-light',
    'iconmark-dark': 'platform/careerpass/iconmark-dark',
    'iconmark-mixed': 'platform/careerpass/iconmark-mixed',
    'lockup-primary-light': 'platform/careerpass/lockup-primary-light',
    'lockup-primary-dark': 'platform/careerpass/lockup-primary-dark',
    'lockup-secondary-light': 'platform/careerpass/lockup-secondary-light',
    'lockup-secondary-dark': 'platform/careerpass/lockup-secondary-dark',
  },
};

export default defineComponent({
  components: { IconDefault },
  props: {
    width: {
      type: Number,
      required: false,
    },
    height: {
      type: Number,
      required: false,
    },
    variant: {
      type: String,
      required: true,
      validator: (v) =>
        [
          'iconmark-light',
          'iconmark-dark',
          'iconmark-mixed',
          'lockup-primary-light',
          'lockup-primary-dark',
          'lockup-secondary-light',
          'lockup-secondary-dark',
        ].indexOf(v) !== -1,
    },
  },
  setup(props) {
    const { platform } = usePlatform();

    const logoPath = computed(() => {
      return logoPaths[platform.value][props.variant];
    });

    return { logoPath };
  },
});
</script>

<style scoped lang="scss"></style>
