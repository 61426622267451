import { computed, useContext } from '@nuxtjs/composition-api';
import { PLATFORM_CAREERPASS, PLATFORM_CAREERTOOLS } from '@/constants/platforms';

export default function usePlatofrm() {
  const { $config } = useContext();

  const platform = computed(() => {
    if ($config.platform === PLATFORM_CAREERPASS) {
      return PLATFORM_CAREERPASS;
    }

    return PLATFORM_CAREERTOOLS;
  });

  return { platform };
}
