import locale5ef7c915 from '../../config/locales/en-AU.js'
import locale6010b66b from '../../config/locales/en-US.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en-AU","legacy":false},
  vueI18nLoader: false,
  locales: [{"code":"en-US","iso":"en-US","file":"en-US.js"},{"code":"en-AU","iso":"en-AU","file":"en-AU.js"}],
  defaultLocale: "en-AU",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: false,
  langDir: "~/config/locales/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en-US","iso":"en-US","file":"en-US.js"},{"code":"en-AU","iso":"en-AU","file":"en-AU.js"}],
  localeCodes: ["en-US","en-AU"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: "-",
  5: "U",
  6: "S",
  7: ".",
  8: "j",
  9: "s",
  10: "\"",
  11: ":",
  12: "\"",
  13: ".",
  14: ".",
  15: "/",
  16: "c",
  17: "o",
  18: "n",
  19: "f",
  20: "i",
  21: "g",
  22: "/",
  23: "l",
  24: "o",
  25: "c",
  26: "a",
  27: "l",
  28: "e",
  29: "s",
  30: "/",
  31: "e",
  32: "n",
  33: "-",
  34: "U",
  35: "S",
  36: ".",
  37: "j",
  38: "s",
  39: "\"",
  40: ",",
  41: "\"",
  42: "e",
  43: "n",
  44: "-",
  45: "A",
  46: "U",
  47: ".",
  48: "j",
  49: "s",
  50: "\"",
  51: ":",
  52: "\"",
  53: ".",
  54: ".",
  55: "/",
  56: "c",
  57: "o",
  58: "n",
  59: "f",
  60: "i",
  61: "g",
  62: "/",
  63: "l",
  64: "o",
  65: "c",
  66: "a",
  67: "l",
  68: "e",
  69: "s",
  70: "/",
  71: "e",
  72: "n",
  73: "-",
  74: "A",
  75: "U",
  76: ".",
  77: "j",
  78: "s",
  79: "\"",
  80: "}",
}

export const localeMessages = {
  'en-AU.js': () => Promise.resolve(locale5ef7c915),
  'en-US.js': () => Promise.resolve(locale6010b66b),
}
