import { onGlobalSetup, provide } from '@nuxtjs/composition-api';
import {
  initialize,
  subscribeToContentCardsUpdates,
  requestContentCardsRefresh,
  logCardImpressions,
  logCustomEvent,
  changeUser,
  logCardClick,
  ClassicCard,
  automaticallyShowInAppMessages,
  openSession,
} from '@braze/web-sdk';
import * as EVENT from '@/constants/analyticsEvent';

export default ({ $config, store }) => {
  const subscribedEvents = [
    EVENT.CTX_USER_LOGGED_IN,
    EVENT.CTX_ADDED_STUDENTS,
    EVENT.CTX_ADDED_TEACHERS,
    EVENT.CTX_EDITED_CUSTOM_PAGE,
    EVENT.CTX_ADDED_CUSTOM_PAGE,
    EVENT.CTX_SAVED_COMMUNICATION,
    EVENT.CTX_EDITED_EXISTING_PAGE,
  ];

  const brazeClient = {
    initialize: () => {
      initialize($config.brazeApiKey, {
        baseUrl: 'sdk.iad-05.braze.com',
      });
    },
    subscribeToContentCardsUpdates: () => {
      subscribeToContentCardsUpdates((updates) => {
        const { cards } = updates;
        store.commit('braze/setContentCards', cards);
      });
    },
    requestContentCardsRefresh: () => {
      setTimeout(() => {
        requestContentCardsRefresh();
      }, 300);
    },
    changeUser: (guid) => {
      changeUser(guid);
      openSession();
    },
    logCardImpression: (card, bool) => {
      const brazeCard = new ClassicCard();
      brazeCard.id = card.id;
      logCardImpressions([brazeCard], bool);
    },
    logCardClick: (card, bool) => {
      if (!card.url) {
        return;
      }
      const brazeCard = new ClassicCard();
      brazeCard.id = card.id;
      brazeCard.url = card.url;
      logCardClick(brazeCard, bool);
    },
    track: (name, eventData = {}) => {
      if (!name) {
        return;
      }
      logCustomEvent(name, eventData);
    },
    getSubscribedEvents: () => {
      return subscribedEvents;
    },
    automaticallyShowInAppMessages: () => {
      automaticallyShowInAppMessages();
    },
  };

  const logMockCall = (functionName, params) => {
    console.log(['MOCK BRAZE CALLED', functionName, params]);
  };

  const mockClient = {
    initialize: (...args) => {
      logMockCall('initialize', args);
    },
    subscribeToContentCardsUpdates: (...args) => {
      logMockCall('subscribeToContentCardsUpdates', args);
    },
    requestContentCardsRefresh: (...args) => {
      logMockCall('requestContentCardsRefresh', args);
    },
    changeUser: (...args) => {
      logMockCall('changeUser', args);
    },
    logCardImpression: (...args) => {
      logMockCall('logCardImpression', args);
    },
    logCardClick: (...args) => {
      logMockCall('logCardClick', args);
    },
    track: (...args) => {
      logMockCall('track', args);
    },
    getSubscribedEvents: () => {
      return subscribedEvents;
    },
    automaticallyShowInAppMessages: () => {
      logMockCall('automaticallyShowInAppMessages');
    },
  };

  onGlobalSetup(async () => {
    const braze = $config.analyticsEnabled ? brazeClient : mockClient;
    braze.initialize();
    braze.automaticallyShowInAppMessages();
    braze.subscribeToContentCardsUpdates();
    provide('braze', braze);
  });
};
