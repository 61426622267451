<template>
  <div class="container page">
    <main tabindex="-1" id="main-content">
      <CenteredContainer>
        <div class="d-flex flex-column align-items-center">
          <div
            v-if="error.statusCode === 404"
            class="ghost d-flex flex-column align-items-center mb-5"
          >
            <IconDefault class="mb-4 glide" :width="125" path="illustrations/ghost" />
            <div class="ellipse"></div>
          </div>
          <Heading size="h1" styleSize="h3" class="text-center mb-5">
            <template v-if="error.statusCode === 401"
              >You are not authorized to access this page
            </template>
            <template v-else-if="error.statusCode === 404">
              Page or resource could not be found
            </template>
            <template v-else>An error occurred</template>
          </Heading>
          <Button size="lg" variant="filled" :to="$auth.user ? '/dashboard' : '/'"
            >{{ $auth.user ? 'Dashboard' : 'Home' }}
          </Button>
        </div>
      </CenteredContainer>
    </main>
  </div>
</template>

<script>
import { defineComponent, useMeta } from '@nuxtjs/composition-api';
import CenteredContainer from '@/components/layout/CenteredContainer';
import Button from '@/components/Button';
import IconDefault from '@/components/icon/Default';
import Heading from '@/components/Heading';

export default defineComponent({
  components: {
    CenteredContainer,
    Button,
    IconDefault,
    Heading,
  },
  props: ['error'],
  head: {},
  layout: 'plain',
  setup() {
    useMeta({ title: 'An error occurred' });
    return {};
  },
});
</script>

<style lang="scss">
.ellipse {
  background: #dadada;
  height: 8px;
  border-radius: 50%;
  -webkit-animation: widthAnimate 1s ease-in-out alternate infinite;
}

.icon {
  &.glide {
    position: relative;
    -webkit-animation: glide 1s ease-in-out alternate infinite;
  }
}

.ghost {
  height: 160px;
}

@-webkit-keyframes widthAnimate {
  0% {
    width: 80px;
    height: 8px;
  }
  100% {
    width: 87px;
    height: 15px;
  }
}

@-webkit-keyframes glide {
  from {
    left: 0;
    top: 0;
  }

  to {
    left: 0;
    top: 20px;
  }
}
</style>
