<template>
  <button role="link" @click="setFocusToMainContent" class="skip-to-main visually-hidden-focusable">
    Skip to main content
  </button>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  components: {},
  head: {},
  setup() {
    const setFocusToMainContent = () => {
      document.getElementById('main-content').focus();
    };

    return { setFocusToMainContent };
  },
});
</script>

<style scoped lang="scss">
.skip-to-main {
  position: absolute;
  left: -999px;
  z-index: 1040;
  padding: 1em;
  background-color: $primary;
  border-radius: 5px;
  border: none;
  color: white;
  opacity: 0;
  &:focus {
    outline: none;
    top: 7rem;
    left: 7.8rem;
    transform: translateX(-50%);
    opacity: 1;
  }
}
</style>
