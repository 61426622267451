/* eslint-disable no-shadow */

export const state = () => ({
  issuedBadges: null,
  learnerProfile: null,
  learnerSkills: null,
  profileCategories: null,
  hasUnsavedChanges: false,
  triggerNavigateAwayEvent: false,
  targetPath: null,
});

export const mutations = {
  setIssuedBadges: (state, issuedBadges) => {
    state.issuedBadges = issuedBadges;
  },
  setLearnerProfile: (state, learnerProfile) => {
    state.learnerProfile = learnerProfile;
  },
  setLearnerSkills: (state, learnerSkills) => {
    state.learnerSkills = learnerSkills;
  },
  setProfileCategories: (state, profileCategories) => {
    state.profileCategories = profileCategories;
  },
  setTargetPath: (state, targetPath) => {
    state.targetPath = targetPath;
  },
  updateBadge(state, badgeInfo) {
    const badge = state.issuedBadges.find((b) => b.id === badgeInfo.id);
    if (badge) {
      Object.assign(badge, badgeInfo);
    }
  },
  resetLearnerProfile(state) {
    state.issuedBadges = null;
    state.learnerProfile = null;
    state.learnerSkills = null;
    state.profileCategories = null;
  },

  // Tracking if the profile has unsaved changes
  setHasUnsavedChanges: (state, hasUnsavedChanges) => {
    state.hasUnsavedChanges = hasUnsavedChanges;
  },
  setNavigateAwayEvent(state, param) {
    state.triggerNavigateAwayEvent = param;
  },
};

export const getters = {};

export const actions = {};
