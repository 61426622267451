<template>
  <div
    aria-hidden="true"
    class="org-icon d-flex align-items-center justify-content-center flex-shrink-0"
    :class="isSmall && 'is-small'"
    :style="{ 'background-color': role.bgColor }"
  >
    {{ role.orgInitials }}
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  components: {},
  props: {
    role: {
      type: Object,
      required: true,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.org-icon {
  border-radius: 50%;
  width: 30px;
  height: 30px;

  color: white;
  font-weight: 700;
  font-size: 14px;

  &.is-small {
    width: 28px;
    height: 28px;
    font-size: 13px;
  }
}
</style>
