<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 10"
    fill="none"
    alt="''"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M1 0.999999L9 9L17 1"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="10" height="18" fill="currentColor" transform="translate(18) rotate(90)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    width: {
      type: Number,
      required: false,
    },
    height: {
      type: Number,
      required: false,
    },
  },
  setup() {},
});
</script>

<style lang="scss" scoped></style>
