import { ref, watch, computed } from '@nuxtjs/composition-api';
import useEventCallback from '@/hooks/eventCallback';

const useCountdown = () => {
  const countdown = ref();

  const formattedCountdown = computed(() => {
    const minutes = Math.floor(countdown.value / 60);
    const seconds = countdown.value % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  });

  const { register: onCountdownDone, trigger: onCountdownDoneTrigger } = useEventCallback();

  let timer = null;

  const clearTimer = () => {
    clearInterval(timer);
  };

  const startTimer = (initialCountdown) => {
    if (timer) {
      clearTimer();
    }
    countdown.value = initialCountdown;
    timer = setInterval(() => {
      countdown.value -= 1;
    }, 1000);
  };

  watch(countdown, (newVal) => {
    if (newVal <= 0) {
      clearTimer();
      onCountdownDoneTrigger();
    }
  });

  return { formattedCountdown, startTimer, clearTimer, onCountdownDone };
};

export default useCountdown;
