/* eslint-disable */
import Vue from 'vue';
import Router from 'vue-router';
import { getDomainConfig } from '@/utils/contextHelpers';

/* eslint-enable */

function dynamically(promise) {
  return promise.then((m) => m.default || m);
}

Vue.use(Router);

// eslint-disable-next-line import/prefer-default-export,no-unused-vars
export function createRouter(context, createDefaultRouter, routerOptions, config) {
  const defaultRoutes = [
    {
      name: 'index',
      path: '/',
      component: () => dynamically(import('@/pages/school-website/index')),
    },
    {
      name: 'course-search-index',
      path: '/course-search',
      component: () => dynamically(import('@/pages/course-search/index')),
    },
    {
      name: 'course-search-compare',
      path: '/course-compare',
      component: () => dynamically(import('@/pages/course-search/compare')),
    },
    {
      name: 'token-login',
      path: '/toklog/:token',
      component: () => dynamically(import('@/pages/token-login')),
    },
    {
      name: 'external-portfolio',
      path: '/external-portfolio/:id',
      component: () => dynamically(import('@/pages/external/portfolio/index')),
    },
    {
      name: 'login-page',
      path: '/login',
      component: () => dynamically(import('@/pages/login')),
    },
    {
      name: 'register-page',
      path: '/register',
      component: () => dynamically(import('@/pages/register')),
    },
    {
      name: 'forgot-password-page',
      path: '/forgot-password',
      component: () => dynamically(import('@/pages/forgot-password')),
    },
    {
      name: 'dashboard-page',
      path: '/dashboard',
      component: () => dynamically(import('@/pages/dashboard')),
    },
    {
      name: 'notifications-page',
      path: '/notifications',
      component: () => dynamically(import('@/pages/notifications')),
    },
    {
      name: 'recommendations',
      path: '/recommendations',
      component: () => dynamically(import('@/pages/recommendations')),
    },
    {
      name: 'appointments-page',
      path: '/appointments',
      component: () => dynamically(import('@/pages/teacher/appointments/calendar')),
    },
    {
      name: 'appointments-history',
      path: '/appointments/history',
      component: () => dynamically(import('@/pages/teacher/appointments/history')),
    },
    {
      name: 'appointments-settings',
      path: '/appointments/settings',
      component: () => dynamically(import('@/pages/teacher/appointments/settings')),
    },
    {
      name: 'message-board-view-page',
      path: '/message-board/:id',
      component: () => dynamically(import('@/pages/school-website/message-board-view')),
    },
    {
      name: 'profile-page',
      path: '/profile',
      component: () => dynamically(import('@/pages/profile')),
    },
    {
      name: 'documents-page',
      path: '/documents',
      component: () => dynamically(import('@/pages/documents')),
    },
    {
      name: 'invitation-page',
      path: '/invitation',
      component: () => dynamically(import('@/pages/invitation')),
    },
    {
      name: 'reset-password-page',
      path: '/reset-password',
      component: () => dynamically(import('@/pages/reset-password')),
    },
    {
      name: 'unverified-page',
      path: '/unverified',
      component: () => dynamically(import('@/pages/unverified')),
    },
    {
      name: 'verify-page',
      path: '/verify',
      component: () => dynamically(import('@/pages/verify')),
    },
    {
      name: 'secondary-verify-page',
      path: '/secondary/verify',
      component: () => dynamically(import('@/pages/secondary-verify')),
    },
    {
      name: 'terms-policy-website',
      path: '/terms-policy',
      component: () => dynamically(import('@/pages/school-website/terms-policy')),
    },
    {
      name: 'career-pass',
      path: '/career-pass',
      component: () => dynamically(import('@/pages/student/career-pass')),
    },
    {
      name: 'career-pass-overview',
      path: '/career-pass/overview',
      component: () => dynamically(import('@/pages/teacher/career-pass')),
    },
    {
      name: 'career-pass-profile',
      path: '/career-pass/profile',
      component: () => dynamically(import('@/pages/student/career-pass/profile')),
    },
    {
      name: 'career-pass-preview',
      path: '/career-pass/preview/:guid',
      component: () => dynamically(import('@/pages/student/career-pass/preview')),
    },
    {
      name: 'career-pass-share',
      path: '/career-pass/share',
      component: () => dynamically(import('@/pages/student/career-pass/share')),
    },
    {
      name: 'career-pass-view',
      path: '/career-pass/:token',
      component: () => dynamically(import('@/pages/external/career-pass/index')),
    },

    // TEACHER SPECIFIC ROUTES
    {
      name: 'resources',
      path: '/resources',
      component: () => dynamically(import('@/pages/teacher/resources/index')),
    },
    {
      name: 'store',
      path: '/store',
      component: () => dynamically(import('@/pages/store')),
    },
    {
      name: 'newsletter-resource',
      path: '/resources/newsletter-resource',
      component: () => dynamically(import('@/pages/teacher/resources/newsletter')),
    },
    {
      name: 'standard-resource',
      path: '/resources/standard-resource',
      component: () => dynamically(import('@/pages/teacher/resources/standard')),
    },
    {
      name: 'resource-for-colleagues',
      path: '/resources/resource-for-colleagues',
      component: () => dynamically(import('@/pages/teacher/resources/for-colleagues')),
    },
    {
      name: 'website-settings',
      path: '/website/settings',
      component: () => dynamically(import('@/pages/teacher/website/settings/index')),
    },
    {
      name: 'website-settings-general',
      path: '/website/settings/general',
      component: () => dynamically(import('@/pages/teacher/website/settings/general')),
    },
    {
      name: 'website-settings-secure-area',
      path: '/website/settings/secure-area',
      component: () => dynamically(import('@/pages/teacher/website/settings/secure-area')),
    },
    {
      name: 'website-settings-widgets',
      path: '/website/settings/widgets',
      component: () => dynamically(import('@/pages/teacher/website/settings/widgets')),
    },
    {
      name: 'website-custom-pages',
      path: '/website/custom-pages',
      component: () => dynamically(import('@/pages/teacher/website/custom-pages/index')),
    },
    {
      name: 'website-custom-pages-add-website-page',
      path: '/website/custom-pages/website/add',
      component: () => dynamically(import('@/pages/teacher/website/custom-pages/add-website-page')),
    },
    {
      name: 'website-cluster-pages-add-website-page',
      path: '/website/cluster-pages/website/add',
      component: () => dynamically(import('@/pages/teacher/website/custom-pages/add-cluster-page')),
    },
    {
      name: 'website-custom-pages-edit-website-page',
      path: '/website/custom-pages/website/:id',
      component: () =>
        dynamically(import('@/pages/teacher/website/custom-pages/edit-website-page')),
    },
    {
      name: 'website-cluster-pages-edit-website-page',
      path: '/website/cluster-pages/website/:id',
      component: () =>
        dynamically(import('@/pages/teacher/website/custom-pages/edit-cluster-page')),
    },
    {
      name: 'website-custom-pages-edit-embedded-page',
      path: '/website/custom-pages/embedded/:id',
      component: () =>
        dynamically(import('@/pages/teacher/website/custom-pages/edit-embedded-page')),
    },
    {
      name: 'website-custom-pages-add-secure-page',
      path: '/website/custom-pages/secure/add',
      component: () => dynamically(import('@/pages/teacher/website/custom-pages/add-secure-page')),
    },
    {
      name: 'website-custom-pages-edit-secure-page',
      path: '/website/custom-pages/secure/:id',
      component: () => dynamically(import('@/pages/teacher/website/custom-pages/edit-secure-page')),
    },
    {
      name: 'website-communications',
      path: '/website/communications',
      component: () => dynamically(import('@/pages/teacher/website/communications/index')),
    },
    {
      name: 'website-communications-message-board',
      path: '/website/communications/message-board',
      component: () =>
        dynamically(import('@/pages/teacher/website/communications/message-board/index')),
    },
    {
      name: 'website-communications-message-board-add',
      path: '/website/communications/message-board/add',
      component: () =>
        dynamically(import('@/pages/teacher/website/communications/message-board/add')),
    },
    {
      name: 'website-communications-message-board-edit',
      path: '/website/communications/message-board/:id',
      component: () =>
        dynamically(import('@/pages/teacher/website/communications/message-board/edit')),
    },
    {
      name: 'website-communications-newsletters',
      path: '/website/communications/newsletters',
      component: () =>
        dynamically(import('@/pages/teacher/website/communications/newsletters/index')),
    },
    {
      name: 'website-communications-newsletters-add',
      path: '/website/communications/newsletters/add',
      component: () =>
        dynamically(import('@/pages/teacher/website/communications/newsletters/add')),
    },
    {
      name: 'website-communications-newsletters-edit',
      path: '/website/communications/newsletters/:id',
      component: () =>
        dynamically(import('@/pages/teacher/website/communications/newsletters/edit')),
    },
    {
      name: 'website-communications-newsfeed',
      path: '/website/communications/newsfeed',
      component: () => dynamically(import('@/pages/teacher/website/communications/newsfeed/index')),
    },
    {
      name: 'website-communications-newsfeed-add',
      path: '/website/communications/newsfeed/add',
      component: () => dynamically(import('@/pages/teacher/website/communications/newsfeed/add')),
    },
    {
      name: 'website-communications-newsfeed-edit',
      path: '/website/communications/newsfeed/:id',
      component: () => dynamically(import('@/pages/teacher/website/communications/newsfeed/edit')),
    },
    {
      name: 'website-communications-scholarships',
      path: '/website/communications/scholarships',
      component: () =>
        dynamically(import('@/pages/teacher/website/communications/scholarships/index')),
    },
    {
      name: 'website-communications-scholarships-add',
      path: '/website/communications/scholarships/add',
      component: () =>
        dynamically(import('@/pages/teacher/website/communications/scholarships/add')),
    },
    {
      name: 'website-communications-scholarships-edit',
      path: '/website/communications/scholarships/:id',
      component: () =>
        dynamically(import('@/pages/teacher/website/communications/scholarships/edit')),
    },
    {
      name: 'website-communications-current-opportunities',
      path: '/website/communications/current-opportunities',
      component: () =>
        dynamically(import('@/pages/teacher/website/communications/current-opportunities/index')),
    },
    {
      name: 'website-communications-current-opportunities-add',
      path: '/website/communications/current-opportunities/add',
      component: () =>
        dynamically(import('@/pages/teacher/website/communications/current-opportunities/add')),
    },
    {
      name: 'website-communications-current-opportunities-edit',
      path: '/website/communications/current-opportunities/:id',
      component: () =>
        dynamically(import('@/pages/teacher/website/communications/current-opportunities/edit')),
    },
    {
      name: 'website-communications-events-calendar',
      path: '/website/communications/events-calendar',
      component: () =>
        dynamically(import('@/pages/teacher/website/communications/events-calendar/index')),
    },
    {
      name: 'teachers-overview',
      path: '/teachers',
      component: () => dynamically(import('@/pages/teacher/teacher-management/overview')),
    },
    {
      name: 'teachers-invite',
      path: '/teachers/invite',
      component: () => dynamically(import('@/pages/teacher/teacher-management/invite')),
    },
    {
      name: 'teachers-bulk-index',
      path: '/teachers/bulk-manage',
      component: () => dynamically(import('@/pages/teacher/teacher-management/bulk-manage/index')),
    },
    {
      name: 'teachers-bulk-add',
      path: '/teachers/bulk-manage/add',
      component: () => dynamically(import('@/pages/teacher/teacher-management/bulk-manage/add')),
    },
    {
      name: 'teachers-bulk-update',
      path: '/teachers/bulk-manage/update',
      component: () => dynamically(import('@/pages/teacher/teacher-management/bulk-manage/update')),
    },
    {
      name: 'teachers-bulk-view-upload',
      path: '/teachers/bulk-manage/:upload_id',
      component: () =>
        dynamically(import('@/pages/teacher/teacher-management/bulk-manage/view-records')),
    },
    {
      name: 'teachers-edit',
      path: '/teachers/:id',
      component: () => dynamically(import('@/pages/teacher/teacher-management/edit')),
    },
    {
      name: 'student-profiles',
      path: '/students',
      component: () => dynamically(import('@/pages/teacher/student-management/profiles/index')),
    },
    {
      name: 'students-invite',
      path: '/students/invite',
      component: () => dynamically(import('@/pages/teacher/student-management/invite')),
    },
    {
      name: 'students-bulk-index',
      path: '/students/bulk-manage',
      component: () => dynamically(import('@/pages/teacher/student-management/bulk-manage/index')),
    },
    {
      name: 'students-bulk-add',
      path: '/students/bulk-manage/add',
      component: () => dynamically(import('@/pages/teacher/student-management/bulk-manage/add')),
    },
    {
      name: 'students-bulk-update',
      path: '/students/bulk-manage/update',
      component: () => dynamically(import('@/pages/teacher/student-management/bulk-manage/update')),
    },
    {
      name: 'students-bulk-view-upload',
      path: '/students/bulk-manage/:upload_id',
      component: () =>
        dynamically(import('@/pages/teacher/student-management/bulk-manage/view-records')),
    },
    {
      name: 'student-groups',
      path: '/students/groups',
      component: () => dynamically(import('@/pages/teacher/student-management/groups/index')),
    },
    {
      name: 'student-groups-create',
      path: '/students/groups/create',
      component: () => dynamically(import('@/pages/teacher/student-management/groups/create')),
    },
    {
      name: 'student-groups-add-student',
      path: '/students/groups/:id/add-student',
      component: () => dynamically(import('@/pages/teacher/student-management/groups/add-student')),
    },
    {
      name: 'student-add-interaction',
      path: '/students/interaction',
      component: () => dynamically(import('@/pages/teacher/student-management/add-interaction')),
      props: true,
    },
    {
      name: 'student-groups-view',
      path: '/students/groups/:id',
      component: () => dynamically(import('@/pages/teacher/student-management/groups/view')),
    },
    {
      name: 'destination-survey-overview',
      path: '/destination-survey/overview',
      component: () => dynamically(import('@/pages/teacher/destination-survey/index')),
    },
    {
      name: 'destination-survey-custom-questions',
      path: '/destination-survey/custom-questions',
      component: () => dynamically(import('@/pages/teacher/destination-survey/custom-questions')),
    },
    {
      name: 'destination-survey-view',
      path: '/destination-survey/:student_id',
      component: () => dynamically(import('@/pages/teacher/destination-survey/view')),
    },
    {
      name: 'student-profile-view',
      path: '/students/:id',
      component: () => dynamically(import('@/pages/teacher/student-management/profiles/view')),
    },
    {
      name: 'student-profile-edit',
      path: '/students/:id/edit',
      component: () => dynamically(import('@/pages/teacher/student-management/profiles/edit')),
    },
    {
      name: 'student-profile-add-hours',
      path: '/students/:studentId/program/:programSlug/add-hours',
      component: () => dynamically(import('@/pages/teacher/student-management/profiles/add-hours')),
    },
    {
      name: 'student-profile-journey-activity-results',
      path: '/students/:id/journeys/:journeyslug/:activityslug',
      component: () =>
        dynamically(
          import('@/pages/teacher/student-management/profiles/journeys/activity-results'),
        ),
    },
    {
      name: 'student-profile-interactions',
      path: '/students/:id/interactions',
      component: () =>
        dynamically(import('@/pages/teacher/student-management/profiles/interactions/index')),
    },
    {
      name: 'student-portfolio-documents',
      path: '/students/:id/portfolio-documents',
      component: () =>
        dynamically(
          import('@/pages/teacher/student-management/profiles/portfolio-documents/index'),
        ),
    },
    {
      name: 'student-profile-interactions-create',
      path: '/students/:id/interactions/create',
      component: () =>
        dynamically(import('@/pages/teacher/student-management/profiles/interactions/create')),
    },
    {
      name: 'student-profile-interactions-edit',
      path: '/students/:id/interactions/:interaction_id/edit',
      component: () =>
        dynamically(import('@/pages/teacher/student-management/profiles/interactions/edit')),
    },
    {
      name: 'student-profile-interactions-view',
      path: '/students/:id/interactions/:interaction_id/view',
      component: () =>
        dynamically(import('@/pages/teacher/student-management/profiles/interactions/view')),
    },
    {
      name: 'student-profile-career-plans-view',
      path: '/students/:id/career-plans/:career_plan_id/view',
      component: () =>
        dynamically(import('@/pages/teacher/student-management/profiles/career-plan-view')),
    },
    {
      name: 'student-profile-career-investigation-view',
      path: '/students/:id/career-investigations/:career_investigation_id/view',
      component: () =>
        dynamically(
          import('@/pages/teacher/student-management/profiles/career-investigation-view'),
        ),
    },
    {
      name: 'student-profile-enterprise-skills-audit-view',
      path: '/students/:id/enterprise-skills-audit/:enterprise_skills_audit_id/view',
      component: () =>
        dynamically(
          import('@/pages/teacher/student-management/profiles/enterprise-skills-audit-view'),
        ),
    },
    {
      name: 'student-profile-personal-statement-tool-view',
      path: '/students/personal-statement-tool/:id/view',
      component: () =>
        dynamically(
          import('@/pages/teacher/student-management/profiles/personal-statement-tool-view'),
        ),
    },
    {
      name: 'career-plans-overview',
      path: '/career-plans/overview',
      component: () => dynamically(import('@/pages/teacher/career-plans/overview')),
    },
    {
      name: 'career-plans-search',
      path: '/career-plans/search',
      component: () => dynamically(import('@/pages/teacher/career-plans/search')),
    },
    {
      name: 'career-plans-manage',
      path: '/career-plans/manage',
      component: () => dynamically(import('@/pages/teacher/career-plans/manage')),
    },
    {
      name: 'career-plans-view',
      path: '/career-plans/:id',
      component: () => dynamically(import('@/pages/teacher/career-plans/view')),
    },
    {
      name: 'program-overview',
      path: '/program/:slug/overview',
      component: () => dynamically(import('@/pages/teacher/program/overview')),
    },
    {
      name: 'program-lesson-outlines',
      path: '/program/:slug/lesson-outlines',
      component: () => dynamically(import('@/pages/teacher/program/lesson-outlines')),
    },
    {
      name: 'program-manage',
      path: '/program/:slug/manage',
      component: () => dynamically(import('@/pages/teacher/program/manage')),
    },
    {
      name: 'program-manage-hours',
      path: '/program/:slug/manage-hours',
      component: () => dynamically(import('@/pages/teacher/program/manage-hours')),
    },
    {
      name: 'program-add-hours',
      path: '/program/:slug/manage-hours/add',
      component: () => dynamically(import('@/pages/teacher/program/add-hours')),
    },
    {
      name: 'program-edit-hours',
      path: '/program/:slug/manage-hours/:id',
      component: () => dynamically(import('@/pages/teacher/program/edit-hours')),
    },
    {
      name: 'assignments-manage',
      path: '/assignments/manage',
      component: () => dynamically(import('@/pages/teacher/assignments/manage')),
    },
    {
      name: 'assignments-create',
      path: '/assignments/create',
      component: () => dynamically(import('@/pages/teacher/assignments/create')),
    },
    {
      name: 'assignments-create-success',
      path: '/assignments/create/success',
      component: () => dynamically(import('@/pages/teacher/assignments/success')),
    },
    {
      name: 'assignments-view',
      path: '/assignments/view/:id',
      component: () => dynamically(import('@/pages/teacher/assignments/view')),
    },
    {
      name: 'onboarding-page',
      path: '/onboarding/:step?',
      component: () => dynamically(import('@/pages/onboarding')),
    },
    {
      name: 'credential-management',
      path: '/credential-management',
      component: () => dynamically(import('@/pages/teacher/credential-management')),
    },
    {
      name: 'credential-assignment',
      path: '/credential-management/:credential_id',
      component: () => dynamically(import('@/pages/teacher/credential-management/assignment')),
    },
    {
      name: 'teacher-psp-readonly',
      path: '/action-plan/student/:id/:actionplanid',
      component: () => dynamically(import('@/pages/teacher/psp/psp-readonly')),
    },
    // STUDENT SPECIFIC ROUTES
    {
      name: 'my-school-pages',
      path: '/my-school-pages',
      component: () => dynamically(import('@/pages/student/my-school-pages')),
    },
    {
      name: 'my-school-page-view',
      path: '/my-school-pages/:id',
      component: () => dynamically(import('@/pages/student/my-school-pages/view')),
    },

    {
      name: 'user-journey-activity-page',
      path: '/journeys/:journeyslug/:activityslug',
      component: () => dynamically(import('@/pages/student/journeys/activity')),
    },
    {
      name: 'user-journey-activity-completed',
      path: '/journeys/:journeyslug/:activityslug/completed',
      component: () => dynamically(import('@/pages/student/journeys/activity-completed')),
    },
    {
      name: 'career-investigator-index',
      path: '/career-investigator',
      component: () => dynamically(import('@/pages/student/career-investigator')),
    },
    {
      name: 'occupation-page',
      path: '/occupation/:slug',
      component: () => dynamically(import('@/pages/student/career-investigator/occupation')),
    },
    {
      name: 'career-investigator-investigate',
      path: '/career-investigator/:id',
      component: () => dynamically(import('@/pages/student/career-investigator/investigate')),
    },
    {
      name: 'career-investigator-preview',
      path: '/career-investigator/:id/preview',
      component: () => dynamically(import('@/pages/student/career-investigator/preview')),
    },
    {
      name: 'academy-index',
      path: '/academy',
      component: () => dynamically(import('@/pages/student/academy')),
    },
    {
      name: 'academy-viewer',
      path: '/academy/:slug',
      component: () => dynamically(import('@/pages/student/academy/viewer')),
    },
    {
      name: 'academy-overview',
      path: '/academy-manage/overview',
      component: () => dynamically(import('@/pages/teacher/academy/index')),
    },
    {
      name: 'destination-survey',
      path: '/destination-survey',
      component: () => dynamically(import('@/pages/student/destination-survey')),
    },
    {
      name: 'portfolio-index',
      path: '/portfolio',
      component: () => dynamically(import('@/pages/student/portfolio')),
    },
    {
      name: 'cover-letter-view',
      path: '/cover-letter/:id',
      component: () => dynamically(import('@/pages/student/portfolio/cover-letter/view')),
    },
    {
      name: 'cover-letter-help',
      path: '/cover-letter-help/:file',
      component: () =>
        dynamically(import('@/pages/student/portfolio/cover-letter/cover-letter-help')),
    },
    {
      name: 'resume-view',
      path: '/resume/:id',
      component: () => dynamically(import('@/pages/student/portfolio/resume/view')),
    },
    {
      name: 'resume-help',
      path: '/resume-help/:file',
      component: () => dynamically(import('@/pages/student/portfolio/resume/resume-help')),
    },
    {
      name: 'custom-resume-view',
      path: '/custom-resume/:id',
      component: () => dynamically(import('@/pages/student/portfolio/custom-resume/view')),
    },
    {
      name: 'custom-resume-select-modules',
      path: '/custom-resume/:id/select-modules',
      component: () =>
        dynamically(import('@/pages/student/portfolio/custom-resume/select-modules')),
    },
    {
      name: 'custom-resume-update-modules',
      path: '/custom-resume/:id/update-modules',
      component: () =>
        dynamically(import('@/pages/student/portfolio/custom-resume/select-modules')),
    },
    {
      name: 'e-portfolio-view',
      path: '/e-portfolio/:id',
      component: () => dynamically(import('@/pages/student/portfolio/e-portfolio/view')),
    },
    {
      name: 'career-planner-index',
      path: '/career-planner',
      component: () => dynamically(import('@/pages/student/career-planner')),
    },
    {
      name: 'career-planner-preview',
      path: '/career-planner/:id/preview',
      component: () => dynamically(import('@/pages/student/career-planner/preview')),
    },
    {
      name: 'career-planner-plan',
      path: '/career-planner/:id',
      component: () => dynamically(import('@/pages/student/career-planner/plan')),
    },
    {
      name: 'post-school-planner',
      path: '/copilot-quiz',
      component: () => dynamically(import('@/pages/student/psp/index')),
    },
    {
      name: 'post-school-planner-explore',
      path: '/copilot-explore',
      component: () => dynamically(import('@/pages/student/psp/explore')),
    },
    {
      name: 'psp-skills-strengths',
      path: '/copilot/skills-strengths',
      component: () => dynamically(import('@/pages/student/psp/skills-strengths')),
    },
    {
      name: 'psp-occupation-page',
      path: '/occupations/:slug',
      component: () => dynamically(import('@/pages/student/psp/occupation')),
    },
    {
      name: 'psp-action-plan',
      path: '/action-plan/:slug',
      component: () => dynamically(import('@/pages/student/psp/action-plan')),
    },
    {
      name: 'psp-saved-plans',
      path: '/copilot/saved-plans',
      component: () => dynamically(import('@/pages/student/psp/saved-plans')),
    },
    {
      name: 'personal-statement-tool',
      path: '/personal-statement-tool',
      component: () => dynamically(import('@/pages/student/personal-statement-tool')),
    },
    {
      name: 'personal-statement-tool-preview',
      path: '/personal-statement-tool/preview',
      component: () => dynamically(import('@/pages/student/personal-statement-tool/preview')),
    },
    {
      name: 'psp-wallet-overview',
      path: '/reporting/psp-wallet-overview',
      component: () => dynamically(import('@/pages/teacher/psp-wallet/overview')),
    },
    {
      name: 'career-portfolios-overview',
      path: '/reporting/career-portfolios',
      component: () => dynamically(import('@/pages/teacher/career-portfolios/overview')),
    },
    {
      name: 'career-investigations-overview',
      path: '/reporting/career-investigations',
      component: () => dynamically(import('@/pages/teacher/career-investigations/overview')),
    },
    {
      name: 'career-investigation-view',
      path: '/reporting/career-investigations/:id',
      component: () => dynamically(import('@/pages/teacher/career-investigations/view')),
    },
    {
      name: 'student-interactions-overview',
      path: '/reporting/student-interactions',
      component: () => dynamically(import('@/pages/teacher/student-interactions/overview')),
    },
    {
      name: 'student-interactions-view',
      path: '/reporting/student-interactions/:id',
      component: () => dynamically(import('@/pages/teacher/student-interactions/view')),
    },
    {
      name: 'student-interactions-edit',
      path: '/reporting/student-interactions/:id/edit',
      component: () => dynamically(import('@/pages/teacher/student-interactions/edit')),
    },
    {
      name: 'achieveworks-dashboard',
      path: '/achieveworks/dashboard',
      component: () => dynamically(import('@/pages/student/achieveworks/dashboard')),
    },
    {
      name: 'achieveworks-assessment-start',
      path: '/achieveworks/assessment/:section',
      component: () => dynamically(import('@/pages/student/achieveworks/assessment')),
    },
    {
      name: 'achieveworks-assessment-view',
      path: '/achieveworks/assessment/:section/:assessmentId',
      component: () => dynamically(import('@/pages/student/achieveworks/assessment')),
    },
    {
      name: 'achieveworks-overview',
      path: '/achieveworks-manage/overview',
      component: () => dynamically(import('@/pages/teacher/achieveworks/index')),
    },
    {
      name: 'achieveworks-user',
      path: '/achieveworks-manage/user/:id',
      component: () => dynamically(import('@/pages/teacher/achieveworks/user')),
    },
    {
      name: 'achieveworks-user-reports',
      path: '/achieveworks-manage/user/:id/reports/:section',
      component: () => dynamically(import('@/pages/teacher/achieveworks/reports')),
    },
    {
      name: 'achieveworks-view-report',
      path: '/achieveworks-manage/user/:id/report/:section/:assessmentId',
      component: () => dynamically(import('@/pages/teacher/achieveworks/view-report')),
    },
    {
      name: 'personal-statements-overview',
      path: '/reporting/personal-statements',
      component: () => dynamically(import('@/pages/teacher/personal-statements/overview')),
    },
    {
      name: 'personal-statements-view',
      path: '/reporting/personal-statements/:id',
      component: () => dynamically(import('@/pages/teacher/personal-statements/view')),
    },
    {
      name: 'enterprise-skills-audit-overview',
      path: '/reporting/enterprise-skills-audit',
      component: () => dynamically(import('@/pages/teacher/enterprise-skills-audit/overview')),
    },
    {
      name: 'enterprise-skills-audit-view',
      path: '/reporting/enterprise-skills-audit/:id',
      component: () => dynamically(import('@/pages/teacher/enterprise-skills-audit/view')),
    },
    {
      name: 'email-overview',
      path: '/email/lists',
      component: () => dynamically(import('@/pages/teacher/email/overview')),
    },
    {
      name: 'email-users',
      path: '/email/lists/:id/users',
      component: () => dynamically(import('@/pages/teacher/email/email-users')),
    },
    {
      name: 'email-compose',
      path: '/email/compose',
      component: () => dynamically(import('@/pages/teacher/email/create-campaign')),
    },
    {
      name: 'email-history',
      path: '/email/history',
      component: () => dynamically(import('@/pages/teacher/email/email-history')),
    },
    {
      name: 'email-courses',
      path: '/email/courses',
      component: () => dynamically(import('@/pages/teacher/email/courses')),
    },
    {
      name: 'email-campaign-recipients',
      path: '/email/:campaign_id/campaign-recipients',
      component: () => dynamically(import('@/pages/teacher/email/campaign-recipients')),
    },
    {
      name: 'reporting-career-investigation-view',
      path: '/reporting/career-investigation/:id',
      component: () => dynamically(import('@/pages/teacher/career-investigations/view')),
    },
    {
      name: 'enterprise-skills-index',
      path: '/enterprise-skills',
      component: () => dynamically(import('@/pages/student/enterprise-skills/index')),
    },
    {
      name: 'enterprise-skill-start',
      path: '/enterprise-skills/quiz',
      component: () => dynamically(import('@/pages/student/enterprise-skills/start-skill')),
    },
    {
      name: 'enterprise-skills-preview',
      path: '/enterprise-skills/preview',
      component: () => dynamically(import('@/pages/student/enterprise-skills/preview')),
    },
    {
      name: 'interactions',
      path: '/interactions',
      component: () => dynamically(import('@/pages/student/interactions')),
    },
    {
      name: 'interaction-view',
      path: '/interactions/:id/view',
      component: () => dynamically(import('@/pages/student/interactions/view')),
    },
    {
      name: 'assignments',
      path: '/assignments',
      component: () => dynamically(import('@/pages/student/assignments')),
    },
    {
      name: 'healthcheck',
      path: '/healthcheck',
      component: () => dynamically(import('@/pages/healthcheck')),
    },
    {
      name: 'diagnostics',
      path: '/diagnostics',
      component: () => dynamically(import('@/pages/diagnostics')),
    },
    {
      name: 'subscription-centre',
      path: '/subscription-centre',
      component: () => dynamically(import('@/pages/unsubscribe-school')),
    },
    {
      name: 'sso-error',
      path: '/sso/:provider/error/:errorCode',
      component: () => dynamically(import('@/pages/sso/school-error')),
    },
    {
      name: 'oauth-error',
      path: '/oauth/:provider/error',
      component: () => dynamically(import('@/pages/teacher/oauth/error')),
    },
    {
      name: 'sitemap',
      path: '/sitemap',
      component: () => dynamically(import('@/pages/sitemap')),
    },
    {
      name: 'switch-role',
      path: '/switch-role/:roleId',
      component: () => dynamically(import('@/pages/switch-role')),
    },
    {
      name: 'error-test-ssr',
      path: '/error-test/ssr',
      component: () => dynamically(import('@/pages/error-test/ssr')),
    },
    {
      name: 'error-test-client',
      path: '/error-test/client',
      component: () => dynamically(import('@/pages/error-test/client')),
    },
    {
      name: 'localization-test',
      path: '/localization-test/:key',
      component: () => dynamically(import('@/pages/localization-test')),
    },
    {
      name: 'school-website-page',
      path: '/:relativeUrl(.*)',
      component: () => dynamically(import('@/pages/school-website/page')),
    },
  ];

  const businessSiteRoutes = [
    // add root site specific routes only
    {
      name: 'root-index',
      path: '/',
      component: () => dynamically(import('@/pages/root/index')),
    },
    {
      name: 'sso-error',
      path: '/sso/:provider/error/:errorCode',
      component: () => dynamically(import('@/pages/sso/root-error')),
    },
    {
      name: 'sso-callback',
      path: '/sso/:provider/callback',
      component: () => dynamically(import('@/pages/sso/callback')),
    },
    {
      name: 'sso',
      path: '/sso/:provider',
      component: () => dynamically(import('@/pages/sso/index')),
    },
    {
      name: 'root-faq',
      path: '/faq',
      component: () => dynamically(import('@/pages/root/faq')),
    },
    {
      name: 'root-our-story',
      path: '/our-story',
      component: () => dynamically(import('@/pages/root/our-story')),
    },
    {
      name: 'root-pricing',
      path: '/pricing',
      component: () => dynamically(import('@/pages/root/pricing')),
    },
    {
      name: 'root-your-custom-website',
      path: '/your-custom-website',
      component: () => dynamically(import('@/pages/root/your-custom-website')),
    },
    {
      name: 'root-achieve-works',
      path: '/achieve-works',
      component: () => dynamically(import('@/pages/root/achieve-works')),
    },
    {
      name: 'root-life-ready',
      path: '/life-ready',
      component: () => dynamically(import('@/pages/root/life-ready')),
    },
    {
      name: 'root-all-my-own-work',
      path: '/all-my-own-work',
      component: () => dynamically(import('@/pages/root/all-my-own-work')),
    },
    {
      name: 'root-addons',
      path: '/addons',
      component: () => dynamically(import('@/pages/root/select-addons')),
    },
    {
      name: 'root-get-started',
      path: '/get-started',
      component: () => dynamically(import('@/pages/root/get-started')),
    },
    {
      name: 'root-get-started-complete',
      path: '/get-started-complete',
      component: () => dynamically(import('@/pages/root/get-started-complete')),
    },
    {
      name: 'root-contact',
      path: '/contact',
      component: () => dynamically(import('@/pages/root/contact')),
    },
    {
      name: 'root-blog-index',
      path: '/community/:categoryOrType?',
      component: () => dynamically(import('@/pages/root/blog/index')),
    },
    {
      name: 'root-blog-post',
      path: '/community/:categoryOrType/:slug',
      component: () => dynamically(import('@/pages/root/blog/post')),
    },
    {
      name: 'root-blog-post-preview',
      path: '/community/:categoryOrType/:slugBase64/preview',
      component: () => dynamically(import('@/pages/root/blog/post')),
    },
    {
      name: 'root-enquiry',
      path: '/enquiry',
      component: () => dynamically(import('@/pages/root/enquiry')),
    },
    {
      name: 'root-subscribe',
      path: '/subscribe',
      component: () => dynamically(import('@/pages/root/subscribe-to-insights')),
    },
    {
      name: 'root-subscribe-thank-you',
      path: '/thank-you',
      component: () => dynamically(import('@/pages/root/subscribe-thank-you')),
    },
    {
      name: 'unsubscribe',
      path: '/unsubscribe',
      component: () => dynamically(import('@/pages/unsubscribe-global')),
    },
    {
      name: 'healthcheck',
      path: '/healthcheck',
      component: () => dynamically(import('@/pages/healthcheck')),
    },
    {
      name: 'terms-policy',
      path: '/terms-policy',
      component: () => dynamically(import('@/pages/root/terms-policy')),
    },
  ];

  const placeholderRoutes = [
    {
      name: 'placeholder-index',
      path: '/',
      component: () => dynamically(import('@/pages/placeholder')),
    },
    {
      name: 'unsubscribe',
      path: '/unsubscribe',
      component: () => dynamically(import('@/pages/unsubscribe-global')),
    },
    {
      name: 'healthcheck',
      path: '/healthcheck',
      component: () => dynamically(import('@/pages/healthcheck')),
    },
  ];

  const { isRootDomain } = getDomainConfig(context, config);

  let routes = null;

  if (isRootDomain) {
    if (config.enableBusinessSite) {
      routes = businessSiteRoutes;
    } else {
      routes = placeholderRoutes;
    }
  } else {
    routes = defaultRoutes;
  }

  const router = new Router({
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
    mode: 'history',
    routes,
  });

  return router;
}
