<template>
  <div>
    <SkipToMainButton />
    <template v-if="$store.state.impersonate.enteringImpersonate">
      <CenteredContainer>
        <div class="d-flex flex-column align-items-center pt-10">
          <h1 class="mb-5">Impersonating</h1>
          <Loader :width="250" :height="250" />
        </div>
      </CenteredContainer>
    </template>
    <template v-else-if="$store.state.impersonate.leavingImpersonate">
      <CenteredContainer>
        <div class="d-flex flex-column align-items-center pt-10">
          <h1 class="mb-5">Stopping impersonate</h1>
          <Loader :width="250" :height="250" />
        </div>
      </CenteredContainer>
    </template>
    <template v-else-if="$store.state.rateLimited">
      <CenteredContainer>
        <div class="d-flex flex-column align-items-center pt-10">
          <h3 class="mb-5">Too many requests sent from {{ $store.state.clientIp }}</h3>
          <h5 class="text-center">
            Please try again later. Contact support at
            <a href="https://www.careertools.com.au/contact">www.careertools.com.au/contact</a> if
            this issue persists.
          </h5>
        </div>
      </CenteredContainer>
    </template>
    <template v-else-if="$store.state.loggingOut">
      <CenteredContainer>
        <div class="d-flex flex-column align-items-center pt-10">
          <h1 class="mb-5">Logging you out</h1>
          <Loader :width="250" :height="250" />
        </div>
      </CenteredContainer>
    </template>
    <template v-else-if="$store.state.fetchSchoolError">
      <SchoolFetchError :error="$store.state.fetchSchoolError" />
    </template>
    <template v-else-if="!schoolEnabled">
      <template v-if="school && school.ct_disabled_override_html">
        <div v-html="school.ct_disabled_override_html" />
      </template>
      <template v-else>
        <SchoolDisabledError />
      </template>
    </template>
    <template v-else-if="hasAuthChanged">
      <FullPageError
        title="Action Required"
        subTitle="This session has expired. Please close this window and log in again to continue."
      />
    </template>
    <template v-else-if="isImpersonatingInNewTab">
      <FullPageError
        title="Action Required"
        subTitle="Looks like you're impersonating a student in another tab or window. Please exit impersonation to continue as a Teacher."
      >
        <template v-slot:additional>
          <Button variant="filled" @click="exitImpersonate($route)" class="me-3">
            Exit Impersonation
          </Button>
        </template>
      </FullPageError>
    </template>
    <template v-else>
      <ModalDefault closeButtonStyle="none" v-if="$store.state.buildMismatch">
        <div class="d-flex flex-column align-items-center">
          <h6 class="mb-5 text-center">
            We've made some quick updates, please refresh your browser to continue
          </h6>
          <p class="mb-5 text-center">If this popup persists, please try again in a few minutes</p>
          <Button size="md" @click="reloadWindow" variant="filled"> Refresh Now </Button>
        </div>
      </ModalDefault>
      <slot></slot>
    </template>

    <OverlayLoader v-if="overlayLoaderVisible" />

    <InactiveSessionModal
      v-if="$auth.user && showModal"
      :sessionTimeoutData="sessionTimeoutData"
    ></InactiveSessionModal>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  useStore,
  useContext,
  useMeta,
  watch,
  useRouter,
} from '@nuxtjs/composition-api';
import chroma from 'chroma-js';
import CenteredContainer from '@/components/layout/CenteredContainer';
import Loader from '@/components/loaders/Loader';
import Button from '@/components/Button';
import ModalDefault from '@/components/modal/Default';
import useSchool from '@/hooks/school';
import useDatadogLogging from '@/hooks/datadogLogging';
import useAnalytics from '@/hooks/analyticsService';
import * as EVENT from '@/constants/analyticsEvent';
import useOverlayLoader from '@/hooks/overlayLoader';
import OverlayLoader from '@/components/loaders/OverlayLoader';
import SkipToMainButton from '@/components/SkipToMainButton';
import InactiveSessionModal from '@/components/modal/InactiveSessionModal';
import useSessionTimeoutModal from '@/hooks/sessionTimeout';
import usePageTitle from '@/hooks/pageTitle';
import SchoolFetchError from '@/components/layout/SchoolFetchError';
import SchoolDisabledError from '@/components/layout/SchoolDisabledError';
import usePlatform from '@/hooks/platform';
import useAuthCheck from '@/hooks/authCheck';
import FullPageError from '@/components/layout/FullPageError';
import { PLATFORM_CAREERPASS } from '@/constants/platforms';

export default defineComponent({
  components: {
    InactiveSessionModal,
    CenteredContainer,
    Loader,
    Button,
    ModalDefault,
    OverlayLoader,
    SkipToMainButton,
    SchoolFetchError,
    SchoolDisabledError,
    FullPageError,
  },
  head: {},
  setup() {
    const store = useStore();
    const { platform } = usePlatform();
    const { $cookies, $config, $auth } = useContext();
    const { isImpersonatingInNewTab, hasAuthChanged } = useAuthCheck();

    const { school, websiteThemeColor } = useSchool();

    const schoolThemeColor = computed(() => websiteThemeColor.value);
    const schoolThemeColorDarker = computed(() => chroma(websiteThemeColor.value).darken(1).hex());
    const schoolThemeColorLighter = computed(() =>
      chroma(websiteThemeColor.value).brighten(3).hex(),
    );

    const clusterPrimaryColors = computed(() => {
      if (school.value?.school_cluster?.primary_colour) {
        const shade1 = school.value.school_cluster.primary_colour;
        const shade2 = chroma(shade1).brighten(0.8).desaturate(0.2).hex();
        const shade3 = chroma(shade1).brighten(1.8).desaturate(0.5).hex();
        const shade4 = chroma(shade1).brighten(2.6).desaturate(0.7).hex();
        const shade5 = chroma(shade1).brighten(3.4).desaturate(0.9).hex();
        return `--cluster-primary-1-color:${shade1}; --cluster-primary-2-color:${shade2}; --cluster-primary-3-color:${shade3}; --cluster-primary-4-color:${shade4}; --cluster-primary-5-color:${shade5}`;
      }
      return null;
    });

    const faviconPath = computed(() => {
      if (school.value?.school_cluster?.favicon_file_path) {
        return school.value.school_cluster.favicon_file_path;
      }

      if (platform.value === PLATFORM_CAREERPASS) {
        return '/favicon-careerpass.ico';
      }

      return '/favicon.ico';
    });

    const router = useRouter();

    router.afterEach(() => {
      if (store.state.loggingOut) {
        store.commit('setLoggingOut', false);
      }

      if (store.state.impersonate.enteringImpersonate) {
        store.commit('impersonate/setEnteringImpersonate', false);
      }

      if (store.state.impersonate.leavingImpersonate) {
        store.commit('impersonate/setLeavingImpersonate', false);
      }
    });

    const { pageTitle } = usePageTitle();

    const meta = computed(() => ({
      link: [
        {
          hid: 'icon',
          rel: 'icon',
          type: 'image/x-icon',
          href: faviconPath.value,
        },
      ],
      bodyAttrs: {
        style: `--theme-color: ${schoolThemeColor.value}; --theme-color-darker:${schoolThemeColorDarker.value}; --theme-color-lighter: ${schoolThemeColorLighter.value}; ${clusterPrimaryColors.value};`,
        class: 'school-website',
      },
      title: pageTitle.value,
    }));

    useDatadogLogging();
    useMeta(meta);

    const reloadWindow = () => {
      window.location.reload();
    };

    const schoolEnabled = computed(() => {
      if ($config.isRootDomain || $config.isHealthCheckRequest) {
        return true;
      }

      if (store.state?.school?.ct_enabled) {
        return true;
      }

      return false;
    });

    const analytics = useAnalytics();
    const impersonatedUserId = $cookies.get('auth.impersonate.user_id');
    const impersonatorUserId = $cookies.get('auth.impersonate.impersonator_id');
    watch(
      () => store.state.impersonate.enteringImpersonate,
      (val) => {
        if (val) {
          analytics.track(EVENT.CTX_IMPERSONATED_USER, {
            impersonated_user_id: $cookies.get('auth.impersonate.user_id'),
            impersonator_user_id: $cookies.get('auth.impersonate.impersonator_id'),
          });
        }
      },
    );

    watch(
      () => store.state.impersonate.leavingImpersonate,
      (val) => {
        if (val) {
          analytics.track(EVENT.CTX_LEAVE_IMPERSONATED_USER, {
            impersonated_user_id: impersonatedUserId,
            impersonator_user_id: impersonatorUserId,
          });
        }
      },
    );

    const { overlayLoaderVisible } = useOverlayLoader();
    const { showModal, ...sessionTimeoutData } = useSessionTimeoutModal();

    const exitImpersonate = (route) => {
      $auth.leaveImpersonate(route.path);
      isImpersonatingInNewTab.value = false;
    };

    return {
      reloadWindow,
      schoolEnabled,
      school,
      overlayLoaderVisible,
      showModal,
      sessionTimeoutData,
      hasAuthChanged,
      exitImpersonate,
      isImpersonatingInNewTab,
    };
  },
});
</script>

<style scoped lang="scss">
.skip-to-main {
  position: absolute;
  left: -999px;
  z-index: 1040;
  padding: 1em;
  background-color: #2f3957;
  border-radius: 5px;
  border: none;
  color: white;
  opacity: 0;
  &:focus {
    outline: none;
    top: 7rem;
    left: 7.8rem;
    transform: translateX(-50%);
    opacity: 1;
  }
}
.timeout-cta {
  gap: 15px;
}
</style>
