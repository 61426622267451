<template>
  <GlobalLayoutWrapper>
    <PageBackground :includeBottomBlob="true" />
    <div class="site-layout">
      <div class="w-100 d-flex justify-content-center flex-grow-1">
        <div class="main-wrapper">
          <main tabindex="-1" id="main-content">
            <Nuxt />
          </main>
        </div>
      </div>
      <Footer />
    </div>
  </GlobalLayoutWrapper>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import GlobalLayoutWrapper from '@/components/layout/GlobalLayoutWrapper';
import PageBackground from '@/components/layout/freeTier/PageBackground';
import Footer from '@/components/layout/freeTier/Footer';

export default defineComponent({
  components: {
    GlobalLayoutWrapper,
    PageBackground,
    Footer,
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.site-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-wrapper {
  margin: 0px 10px;
  width: 100%;

  @include m-small {
    max-width: 560px;
  }

  @include m-medium {
    max-width: 700px;
  }

  @include m-large {
    max-width: 800px;
  }

  @include m-xl {
    max-width: 1000px;
  }

  @include m-xxl {
    max-width: 1200px;
  }
}
</style>
