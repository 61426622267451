/* eslint-disable no-shadow */
import gql from 'graphql-tag';
import { CONFIG_SCHOOL_FIELDS } from '@/graphql/fragments/school';
import { recursiveConstructMenuItems } from '@/utils/navMenu';
import { PRICING_TIER_FREE } from '@/constants/pricingTiers';
import setupLocalizationForSchool from '@/utils/setupLocalizationForSchool';
import { COUNTRY_CODE_USA } from '@/constants/countries';

const GET_SCHOOL_BY_LINKED_DOMAIN = gql`
  ${CONFIG_SCHOOL_FIELDS}
  query getSchoolByLinkedDomain($domain: String!) {
    linkedDomain(domain: $domain) {
      id
      domain
      school {
        ...ConfigSchoolFields
      }
    }
  }
`;

const GET_SCHOOL_BY_SLUG = gql`
  ${CONFIG_SCHOOL_FIELDS}
  query getSchoolBySlug($slug: String!) {
    getSchoolBySlug(slug: $slug) {
      ...ConfigSchoolFields
    }
  }
`;

export const state = () => ({
  academies: [],
  activities: [],
  programs: [],
  sidebar: false,
  school: null,
  buildMismatch: false,
  vjeUrl: null,
  clientIp: null,
  schoolGroups: [],
  schoolStats: null,
  favouriteGroups: [],
  schoolYearDisabledComponents: [],
  rateLimited: false,
  loggingOut: false,
  postOnboardingRedirect: '/dashboard',
  overlayLoaderVisible: false,
  toolboxEntries: [],
  pageTitle: null,
  cardDeckDroppedStatus: false,
  expireTimeTeacher: 60 * 360, // In seconds
  expireTimeStudent: 60 * 60, // In seconds
  pspSkillsCompleted: false,
  lastUsedTableMenu: [],
  fetchSchoolError: null,
});

export const mutations = {
  setLastUsedTableMenu: (state, val) => {
    const stateIndex = state.lastUsedTableMenu.findIndex((element) => element.slug === val.slug);
    if (stateIndex > -1) {
      state.lastUsedTableMenu[stateIndex] = val;
    } else {
      state.lastUsedTableMenu.push(val);
    }
  },
  showOverlayLoader: (state) => {
    state.overlayLoaderVisible = true;
  },
  hideOverlayLoader: (state) => {
    state.overlayLoaderVisible = false;
  },
  setPostOnboardingRedirect: (state, redirect) => {
    state.postOnboardingRedirect = redirect;
  },
  setPspSkillsCompleted: (state, val) => {
    state.pspSkillsCompleted = val;
  },
  setBuildMismatch: (state, buildMismatch) => {
    state.buildMismatch = buildMismatch;
  },
  setRateLimited: (state, rateLimited) => {
    state.rateLimited = rateLimited;
  },
  setLoggingOut: (state, loggingOut) => {
    state.loggingOut = loggingOut;
  },
  setAcademies: (state, academies) => {
    state.academies = academies;
  },
  setActivities: (state, activities) => {
    state.activities = activities;
  },
  setPageTitle: (state, pageTitle) => {
    state.pageTitle = pageTitle;
  },
  setPrograms: (state, programs) => {
    state.programs = programs;
  },
  setSchoolStats: (state, stats) => {
    state.schoolStats = stats;
  },
  setSchoolSettings: (state, settings) => {
    state.school.settings = settings;
  },
  setUserSettings: (state, settings) => {
    state.auth.user.settings = settings;
  },
  setActivityState: (state, activityState) => {
    const existingActivityStateIndex = state.auth.user.activityStates.findIndex(
      (as) => as.id === activityState.id,
    );

    if (existingActivityStateIndex !== -1) {
      state.auth.user.activityStates = [
        ...state.auth.user.activityStates.slice(0, existingActivityStateIndex),
        activityState,
        ...state.auth.user.activityStates.slice(existingActivityStateIndex + 1),
      ];
      return;
    }

    state.auth.user.activityStates = [...state.auth.user.activityStates, activityState];
  },
  setSchoolYearDisabledComponents: (state, components) => {
    state.schoolYearDisabledComponents = components;
  },
  setSchool: (state, school) => {
    state.school = school;
  },
  setSchoolWidgets: (state, widgets) => {
    state.school.widgets = widgets;
  },
  setGroups: (state, groups) => {
    state.schoolGroups = groups;
  },
  setFavouriteGroups: (state, groups) => {
    state.favouriteGroups = groups;
  },
  setClusterToolboxEntries: (state, entries) => {
    state.toolboxEntries = entries || [];
  },
  addSchoolGroup: (state, group) => {
    state.schoolGroups = [...state.schoolGroups, group];
  },
  editSchoolGroup: (state, group) => {
    const index = state.schoolGroups.findIndex((g) => g.id === group.id);
    const { schoolGroups: groups } = state;
    groups[index] = group;
    state.schoolGroups = [...groups];
  },
  removeSchoolGroup: (state, groupId) => {
    const filteredGroups = state.schoolGroups.filter((g) => g.id !== groupId);
    state.schoolGroups = [...filteredGroups];
  },
  addUserFormAttempt: (state, formAttempt) => {
    state.auth.user.formAttempts.push(formAttempt);
  },
  addCompletedRecommendation: (state, completedRecommendation) => {
    state.auth.user.completedRecommendations.push(completedRecommendation);
  },
  removeUserFormAttempt: (state, formAttemptId) => {
    state.auth.user.formAttempts = state.auth.user.formAttempts.filter(
      (fa) => fa.id !== formAttemptId,
    );

    state.auth.user.activityStates = state.auth.user.activityStates.filter(
      (as) => as.form_attempt_id !== formAttemptId,
    );
  },
  setVjeUrl: (state, vjeUrl) => {
    state.vjeUrl = vjeUrl;
  },
  setFetchSchoolError: (state, error) => {
    state.fetchSchoolError = error;
  },
  setClientIp: (state, clientIp) => {
    state.clientIp = clientIp;
  },
  setQuickTourComplete: (state, value) => {
    state.auth.user.quick_tour_complete = value;
  },
  setCardDeckDroppedStatus: (state, val) => {
    state.cardDeckDroppedStatus = val;
  },
  markAllNotificationsRead: (state, notificationIds) => {
    state.auth.user.recentNotifications.forEach((n) => {
      if (notificationIds.includes(n.id)) {
        // eslint-disable-next-line no-param-reassign
        n.read = true;
      }
    });
  },
  setUserNotificationRead: (state, payload) => {
    const notification = state.auth.user.recentNotifications.find((n) => {
      return n.id === payload.notificationId;
    });
    notification.read = payload.read;
  },
  pushNewNotification: (state, notification) => {
    const existingNotification = state.auth.user.recentNotifications.find((n) => {
      return n.id === notification.id;
    });

    if (existingNotification) {
      const updatedNotifications = state.auth.user.recentNotifications.map((n) => {
        if (n.id === notification.id) {
          return notification;
        }
        return n;
      });
      state.auth.user.recentNotifications = updatedNotifications;
    } else {
      state.auth.user.recentNotifications = [notification, ...state.auth.user.recentNotifications];
    }
  },
};

export const getters = {
  getCardDeckDroppedStatus: (state) => state.cardDeckDroppedStatus,
  school: (state) => state.school || null,
  getSchoolSettings: (state) => (key) => {
    let settings = state.school?.settings || '{}';
    settings = JSON.parse(settings);
    return key ? settings[key] || null : settings;
  },
  getSchoolStats: (state) => {
    return state.schoolStats;
  },
  schoolPermissions: (state) => {
    let tierFeatures = state.school?.tier_features || '{}';
    tierFeatures = JSON.parse(tierFeatures);
    return tierFeatures || {};
  },
  schoolTestPermissions: (state) => {
    let testFeatures = state.school?.enabled_test_features || '{}';
    testFeatures = JSON.parse(testFeatures);

    // CAP and Wallet are always enabled for all
    if (!testFeatures.cap_and_wallet) {
      testFeatures.cap_and_wallet = true;
    }

    testFeatures.course_search_v2 = state?.school?.country.code !== COUNTRY_CODE_USA;

    return testFeatures || {};
  },
  schoolFeaturePermissionMeta: (state) => {
    const meta = state.school?.feature_permission_meta || '{}';
    return JSON.parse(meta) || {};
  },
  schoolCalendarSettings: (state) => {
    let settings = state.school?.settings || '{}';
    settings = JSON.parse(settings);
    return settings.appointments_calendar || {};
  },
  schoolNavMenuItems: (state, getters) => {
    if (!state.school) {
      return [];
    }

    const { schoolPermissions } = getters;

    const allMenuItems =
      state.school.menu?.menuItems.reduce((carry, mi) => {
        if (mi.instance_type === 'component-page' && mi.instance.component_name === 'newsfeed') {
          const schoolSettings = getters.getSchoolSettings();
          // Check for tiers as well
          const hasAdvancedComm = schoolPermissions.advanced_website_communications || false;
          if (!schoolSettings.newsfeed.is_enabled || !hasAdvancedComm) {
            return carry; // remove item
          }
        }

        if (
          mi.instance_type === 'component-page' &&
          mi.instance.component_name === 'current-opportunities'
        ) {
          const hasAdvancedComm = schoolPermissions.advanced_website_communications || false;
          if (!hasAdvancedComm) {
            return carry; // remove item
          }
        }

        if (
          mi.instance_type === 'component-page' &&
          mi.instance.component_name === 'appointment-booking'
        ) {
          const schoolSettings = getters.getSchoolSettings();
          if (!schoolSettings.appointments_calendar.is_enabled) {
            return carry; // remove item
          }

          if (schoolSettings.appointments_calendar.calendar_type === 'EXTERNAL') {
            // Inject external link item instead
            carry.push({
              ...mi,
              instance_type: 'external-link',
              instance: {
                id: 'external-appointment-cal',
                url: schoolSettings.appointments_calendar.calendar_url,
              },
            });
            return carry;
          }
        }

        carry.push(mi);
        return carry;
      }, []) || [];

    const rootItems = allMenuItems.filter((mi) => mi.parent_id === null);

    return rootItems.map((ri) =>
      recursiveConstructMenuItems({ vjeUrl: state.vjeUrl }, allMenuItems, ri),
    );
  },
  clusterToolboxEntries: (state) => {
    const entries = state.toolboxEntries.reduce((carry, entry) => {
      carry.push({
        ...entry,
        type:
          entry.url.startsWith('http:') || entry.url.startsWith('https:') ? 'external' : 'internal',
      });
      return carry;
    }, []);
    return entries ?? [];
  },
};

const loadSchoolConfig = async (config, app, commit) => {
  if (!config.schoolDomain || config.isHealthCheckRequest) {
    return null;
  }

  // is a subdomain school domain, fetch school by slug
  if (config.schoolSubDomainSlug) {
    const res = await app.apolloProvider.defaultClient.query({
      query: GET_SCHOOL_BY_SLUG,
      variables: {
        slug: config.schoolSubDomainSlug,
      },
    });
    if (res?.data?.getSchoolBySlug) {
      commit('setSchool', res.data.getSchoolBySlug);
      return res.data.getSchoolBySlug;
    }
    return null;
  }

  // is a custom school domain, fetch school by linked domain
  const res = await app.apolloProvider.defaultClient.query({
    query: GET_SCHOOL_BY_LINKED_DOMAIN,
    variables: {
      domain: config.schoolDomain,
    },
  });

  if (res?.data?.linkedDomain) {
    commit('setSchool', res.data.linkedDomain.school);
    return res.data.linkedDomain.school;
  }

  return null;
};

export const actions = {
  async nuxtServerInit({ commit }, { $config, app, req }) {
    const ip = req.headers['x-forwarded-for'].split(', ').shift() || req.connection.remoteAddress;
    commit('setClientIp', ip);
    commit('setVjeUrl', $config.vjeUrl);

    try {
      const school = await loadSchoolConfig($config, app, commit);
      setupLocalizationForSchool(app, school);
    } catch (e) {
      commit('setFetchSchoolError', e.message);
    }
  },
};
