<template>
  <div class="container">
    <PlatformLogo class="mb-5" :width="100" variant="iconmark-dark" />
    <slot name="contents">
      <h1 class="title">{{ title }}</h1>
      <div class="subtitle" v-if="subTitle" v-html="subTitle"></div>

      <div class="mt-3">
        <slot name="additional"></slot>
      </div>
    </slot>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import PlatformLogo from '@/components/icon/PlatformLogo';

export default defineComponent({
  components: {
    PlatformLogo,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
    },
  },
  setup() {},
});
</script>

<style scoped lang="scss">
.container {
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 900px;
  line-height: 1.5;
}

.subtitle {
  max-width: 450px;
  font-size: 16px;
  color: $primary;
  font-weight: 600;
  text-align: center;
}
</style>
