<template>
  <div class="flex-column align-items-center sidebar flex-shrink-0" :class="{ expanded: expanded }">
    <div class="d-flex justify-content-end justify-content-md-center w-100 px-3 px-md-0">
      <button
        type="button"
        @click="expanded = !expanded"
        class="no-style menu-toggle d-flex align-items-center justify-content-center"
        :class="{ expanded: expanded }"
        aria-label="Toggle Side Menu"
        :aria-expanded="expanded ? 'true' : 'false'"
      >
        <component :is="toggleComponent" :width="20" />
      </button>
    </div>

    <NuxtLink class="mb-7" to="/" aria-label="dashboard">
      <div class="logo-container d-flex justify-content-center align-items-center">
        <Transition name="fade">
          <PlatformLogo
            v-if="!transitioning && expanded"
            class="wordmark"
            :width="145"
            variant="lockup-primary-light"
          />
        </Transition>
        <Transition name="fade">
          <PlatformLogo
            v-if="!transitioning && !expanded"
            class="logo"
            :width="35"
            :height="35"
            variant="iconmark-light"
          />
        </Transition>
      </div>
    </NuxtLink>

    <div class="w-100 h-100 d-flex flex-column justify-content-between">
      <ul class="nav-links">
        <SidebarNavLink
          v-for="(link, index) in navLinkConfig"
          :key="`sidebar-nav-link-${index}`"
          :to="link.to"
          :iconComponent="link.iconComponent"
          :text="link.text"
          @click="triggerLink(link)"
          :activeRoutes="link.activeRoutes"
          :expanded="Boolean(expanded)"
          :transitioning="transitioning"
        />
      </ul>

      <Transition name="fade">
        <div v-if="!transitioning && expanded">
          <ul class="nav-links">
            <SidebarFooterLink
              v-for="(link, index) in footerLinkConfig"
              :key="`sidebar-footer-link-${index}`"
              :to="link.to"
              :text="link.text"
            />
          </ul>
          <div class="copy-right text-center mb-7">{{ year }} &copy; {{ tPlatform('name') }}</div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, useStore } from '@nuxtjs/composition-api';
import IconDefault from '@/components/icon/Default';
import SidebarNavLink from '@/components/layout/freeTier/SidebarNavLink';
import SidebarFooterLink from '@/components/layout/freeTier/SidebarFooterLink';
import ChevronLeft from '@/components/icon/inline/ChevronLeft';
import Hamburger from '@/components/icon/inline/Hamburger';
import Cross from '@/components/icon/inline/Cross';
import useI18n from '@/hooks/i18n';
import useAuth from '@/hooks/auth';
import { ROLE_TYPE_STUDENT, ROLE_TYPE_TEACHER } from '@/constants/role_types';
import useBreakpoint from '@/hooks/breakpoint';
import useSchool from '@/hooks/school';
import PlatformLogo from '@/components/icon/PlatformLogo';
import useAnalytics from '@/hooks/analyticsService';
import * as EVENT from '@/constants/analyticsEvent';

export default defineComponent({
  components: {
    IconDefault,
    SidebarNavLink,
    SidebarFooterLink,
    ChevronLeft,
    Hamburger,
    Cross,
    PlatformLogo,
  },
  props: {},
  setup() {
    const { t, tPlatform, tc } = useI18n();

    const { user } = useAuth();
    const { hasTestFeatureEnabled } = useSchool();

    const hasAttemptId = computed(() => {
      return !!(user.value.latestCompletedQuizAttempt && user.value.latestCompletedQuizAttempt.id);
    });

    const currentBreakpoint = useBreakpoint();
    const analytics = useAnalytics();
    const store = useStore();

    const transitioning = ref(false);

    const expanded = computed({
      get: () => store.state.ui.sidebarExpanded,
      set: (val) => {
        transitioning.value = true;
        setTimeout(() => {
          transitioning.value = false;
        }, 500);
        store.commit('ui/setSidebarExpanded', val);
      },
    });

    const navLinkConfig = computed(() => {
      if (user.value?.role_type === ROLE_TYPE_STUDENT) {
        const config = [
          {
            to: '/dashboard',
            text: 'Career Action Plan',
            iconComponent: () => import('@/components/icon/inline/Checklist'),
            onClick: () => {},
            activeRoutes: [
              'dashboard-page',
              'psp-skills-strengths',
              'post-school-planner-explore',
              'psp-occupation-page',
            ],
          },
          {
            to: '/career-pass',
            text: 'Career Wallet',
            iconComponent: () => import('@/components/icon/inline/Shield'),
            onClick: () => {},
            activeRoutes: [
              'career-pass',
              'career-pass-profile',
              'career-pass-preview',
              'career-pass-share',
            ],
            eventName: EVENT.WAL_SIDEBAR_ICON_CLICKED,
          },
        ];

        if (hasTestFeatureEnabled('cap_and_wallet') && hasAttemptId.value) {
          config.push({
            to: { name: 'psp-saved-plans' },
            text: t('toCapital', { text: tc('general.favourite.label', 2) }),
            iconComponent: () => import('@/components/icon/inline/Star'),
            onClick: () => {},
            activeRoutes: ['psp-saved-plans'],
          });
        }

        return config;
      }

      if (user.value?.role_type === ROLE_TYPE_TEACHER) {
        return [
          {
            to: '/dashboard',
            text: 'Dashboard',
            iconComponent: () => import('@/components/icon/inline/Home'),
            onClick: () => {},
            activeRoutes: [
              'dashboard-page',
              'students-bulk-index',
              'students-bulk-add',
              'students-bulk-update',
              'students-bulk-view-upload',
              'students-invite',
              'student-groups',
              'student-groups-create',
              'student-groups-add-student',
              'student-groups-view',
              'student-profile-edit',
            ],
          },
          {
            to: '/credential-management',
            text: 'Credential Management',
            iconComponent: () => import('@/components/icon/inline/Shield'),
            onClick: () => {},
            activeRoutes: ['credential-management', 'credential-assignment'],
          },
        ];
      }

      return [];
    });

    const footerLinkConfig = computed(() => {
      return [
        ...(user.value?.role_type === ROLE_TYPE_STUDENT
          ? [
              {
                to: '/sitemap',
                text: 'Sitemap',
              },
            ]
          : []),
        {
          to: '/terms-policy',
          text: 'Terms & Conditions',
        },
      ];
    });

    const year = new Date().getFullYear();

    const isOverlay = computed(() => {
      return ['xs', 'sm'].includes(currentBreakpoint.value);
    });

    const toggleComponent = computed(() => {
      if (isOverlay.value) {
        return 'Cross';
      }

      if (expanded.value) {
        return 'ChevronLeft';
      }

      return 'Hamburger';
    });

    const triggerLink = (link) => {
      if (isOverlay.value) {
        expanded.value = false;
      }
      link.onClick();
      analytics.track(EVENT.WAL_SIDEBAR_ICON_CLICKED);
    };

    return {
      navLinkConfig,
      footerLinkConfig,
      tPlatform,
      year,
      expanded,
      user,
      currentBreakpoint,
      toggleComponent,
      triggerLink,
      transitioning,
      hasTestFeatureEnabled,
      hasAttemptId,
    };
  },
});
</script>

<style lang="scss" scoped>
.sidebar {
  background-color: $primary;
  color: white;
  box-shadow: 0px 32px 64px 0px #0200623d;
  z-index: 10;
  transition: width 0.5s;
  display: none;
  height: 100vh;

  @include m-medium {
    position: sticky;
    top: 0;
    display: flex;
    width: 75px;
    min-width: 75px;
  }

  &.expanded {
    position: fixed;
    width: calc(100vw - (100vw - 100%));
    display: flex;

    @include m-medium {
      position: sticky;
      top: 0;
      display: flex;
      width: 215px;
    }
  }

  .menu-toggle {
    color: white;
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    margin: 20px 0px 30px 0px;
    border-radius: 50%;
    &.expanded {
      background-color: darken($primary, 10%);
    }
  }

  .nav-links {
    width: 100%;
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;
  }

  .copy-right {
    font-size: 12px;
  }

  .logo {
    max-width: 35px;
  }

  .logo-container {
    height: 40px;
  }
}

.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-enter {
  opacity: 0;
}
</style>
