<template>
  <component :user="user" :is="navComponent"></component>
</template>

<script>
import { defineComponent, computed, useRoute } from '@nuxtjs/composition-api';
import useAuth from '@/hooks/auth';
import useSchool from '@/hooks/school';
import { PRICING_TIER_FREE } from '@/constants/pricingTiers';
import { ROLE_TYPE_TEACHER, ROLE_TYPE_STUDENT } from '@/constants/role_types';

const lockedDownNavRoutes = ['onboarding-page', 'unverified-page', 'switch-role', 'token-login'];
const externalRoutes = ['career-pass-view'];

export default defineComponent({
  components: {},
  setup() {
    const { user } = useAuth();
    const { school } = useSchool();

    const route = useRoute();

    const navComponent = computed(() => {
      if (!user.value || externalRoutes.includes(route.value.name)) {
        return () => import('@/components/layout/navigation/unauthenticated');
      }

      if (lockedDownNavRoutes.includes(route.value.name)) {
        return () => import('@/components/layout/navigation/lockedDown');
      }

      if (user.value.role_type === ROLE_TYPE_STUDENT) {
        return school.value.pricing_tier === PRICING_TIER_FREE
          ? () => import('@/components/layout/navigation/freeTierStudent')
          : () => import('@/components/layout/navigation/student');
      }

      if (user.value.role_type === ROLE_TYPE_TEACHER) {
        return school.value.pricing_tier === PRICING_TIER_FREE
          ? () => import('@/components/layout/navigation/freeTierTeacher')
          : () => import('@/components/layout/navigation/teacher');
      }

      return null;
    });

    return { user, navComponent };
  },
});
</script>

<style lang="scss" scoped></style>
