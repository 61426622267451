/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client/core';

export const CONFIG_SCHOOL_FIELDS = gql`
  fragment ConfigSchoolFields on School {
    id
    old_id
    name
    logo_small
    logo_full
    timezone
    ct_enabled
    ct_disabled_override_html
    certificate_teacher_id
    settings
    website_public_registration
    website_theme_color
    website_banner_title
    website_banner_text
    website_banner_text_color
    website_banner_background_image
    website_banner_video
    website_banner_video_type
    website_footer_text
    website_twitter
    website_facebook
    website_instagram
    achieve_works_enabled
    recaptcha_enabled
    sso_provider
    enabled_test_features
    menu {
      id
      menuItems {
        id
        text
        slug
        parent_id
        order
        instance_type
        instance {
          __typename
          ... on ContentPage {
            id
            title
            content
            embedded_name
            type
            school_id
            school_cluster_id
          }
          ... on ComponentPage {
            id
            title
            component_name
          }
          ... on ExternalLink {
            id
            url
          }
          ... on SubMenu {
            id
            image_url
            description
          }
        }
      }
    }
    embedded_pages {
      id
      title
      content
      embedded_name
    }
    website_widgets {
      left
      center
      right
    }
    pricing_tier
    tier_features
    feature_permission_meta
    school_cluster_id
    school_cluster {
      id
      primary_colour
      disable_upgrade_prompts
      logo_file_path
      logo_footer_file_path
      favicon_file_path
    }
    state {
      id
      name
      code
    }
    country {
      id
      name
      code
      locale
      rollover_day
      rollover_month
      first_year_group
      last_year_group
      display_as_graduated_start_year_group
    }
    localization_overrides {
      id
      locale
      key
      value
    }
  }
`;
