<template>
  <component
    :is="isActive ? 'div' : 'NuxtLink'"
    @click.stop=""
    class="role-switcher-role no-style d-flex align-items-center justify-content-between py-2 px-4"
    :class="{
      active: isActive,
      inactive: !isActive,
      'hover-highlight': !isActive && highlightOnHover,
    }"
    :to="{ name: 'switch-role', params: { roleId: role.id } }"
  >
    <div class="d-flex align-items-center">
      <MultiSchoolRoleIcon class="me-2" :role="role" />
      <div class="org-name">{{ role.org.name }}</div>
    </div>
    <div
      class="is-active-indicator active d-flex align-items-center justify-content-center"
      v-if="isActive"
    >
      <IconDefault path="default/check-white" :width="18"></IconDefault>
    </div>
    <div class="is-active-indicator inactive" v-else></div>
  </component>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import IconDefault from '@/components/icon/Default';
import MultiSchoolRoleIcon from '@/components/layout/navigation/MultiSchoolRoleIcon';

export default defineComponent({
  components: { IconDefault, MultiSchoolRoleIcon },
  props: {
    role: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    highlightOnHover: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.role-switcher-role {
  background-color: white;
  .org-icon {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: white;
    font-weight: 700;
    font-size: 14px;
  }

  .org-name {
    color: $primary-1;
    font-size: 14px;
    max-width: 150px;
  }

  .is-active-indicator {
    border-radius: 50%;
    width: 30px;
    height: 30px;

    &.active {
      border: 2px solid $valid-green;
      background-color: $valid-green;
    }
    &.inactive {
      border: 2px solid $primary-1;
      background-color: white;
    }
  }
}

.hover-highlight:hover {
  background-color: $cloud-blue;
}
</style>
