<template>
  <img src="/loading.svg" alt="loading" :width="width" :height="height" />
</template>

<script>
export default {
  name: 'loading-component',
  props: {
    width: {
      type: Number,
      default: 50,
    },
    height: {
      type: Number,
      default: 50,
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  animation: rotate 2s infinite;
}

@keyframes rotate {
  1% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(380deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
