import Echo from 'laravel-echo';
import { onGlobalSetup, watch, useContext, useStore, ref } from '@nuxtjs/composition-api';
import useNotifications from '@/hooks/notifications';
import useEventBus from '@/hooks/eventBus';

window.Pusher = require('pusher-js');

export default ({ $config, $cookies }) => {
  onGlobalSetup(() => {
    let echo = null;

    const { $auth } = useContext();
    const store = useStore();
    const { pushNewNotification } = useNotifications();
    const { bus } = useEventBus();

    const echoUserId = ref(null);

    const connectEcho = () => {
      echoUserId.value = $auth.user.id;

      const impersonateUserId = $cookies.get('auth.impersonate.user_id');
      const impersonateRoleType = $cookies.get('auth.impersonate.role_type');

      echo = new Echo({
        broadcaster: 'pusher',
        key: 'careertools-ws-key',
        wsHost: `sockets.${$config.careertoolsDomain}`,
        forceTLS: $config.useHttps,
        encrypted: true,
        disableStats: true,
        enabledTransports: $config.useHttps ? ['ws', 'wss'] : ['ws'],
        authEndpoint: '/api/broadcasting/auth',
        auth: {
          headers: {
            ...(impersonateUserId && { 'Impersonate-User-Id': impersonateUserId }),
            ...(impersonateRoleType && { 'Impersonate-Role-Type': impersonateRoleType }),
          },
        },
      });

      echo
        .private(`user.${$auth.user.id}`)
        .listen('RecommendationCompleted', (e) => {
          store.commit('addCompletedRecommendation', e.completedRecommendation);
        })
        .listen('PSPAttemptCompleted', (e) => {
          const { completed } = e;
          store.commit('setPspSkillsCompleted', completed);
        })
        .listen('UserNotification', (e) => {
          pushNewNotification(e.notification);
        });

      echo
        .private(`school.${$auth.user.currentOrgRole.org_id}`)
        .listen('AssignmentCreated', (event) => {
          bus.$emit('assignmentCreated', event.data);
        });
    };

    const disconnectEcho = () => {
      echoUserId.value = null;
      echo.disconnect();
      echo = null;
    };

    watch(
      () => $auth.user,
      () => {
        if (echo && !$auth.user) {
          disconnectEcho();
          return;
        }

        if (echo && echoUserId.value !== $auth.user.id) {
          disconnectEcho();
          connectEcho();
          return;
        }

        if (!echo && $auth.user) {
          connectEcho();
        }
      },
      { immediate: true },
    );
  });
};
