export default (store, user) => {
  const formActivities = store.state.activities.filter(
    (a) => a.type === 'App\\Models\\Activity\\Type\\Form',
  );

  return formActivities.reduce((carry, formActivity) => {
    // eslint-disable-next-line no-param-reassign
    carry[formActivity.slug] = user.formAttempts
      .filter((formAttempt) => formAttempt.activity_id === formActivity.id)
      .map((formAttempt) => {
        const activityState = user.activityStates.find((as) => {
          if (formActivity.id !== as.activity_id) {
            return false;
          }

          if ((as.form_attempt_id ? as.form_attempt_id.toString() : null) !== formAttempt.id) {
            return false;
          }

          return true;
        });

        return {
          ...formAttempt,
          activitySlug: formActivity.slug,
          activityState: activityState ?? null,
        };
      });

    return carry;
  }, {});
};
