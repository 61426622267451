import { computed, useStore } from '@nuxtjs/composition-api';

export default function useNotifications() {
  const store = useStore();

  const recentNotifications = computed(() => store.state.auth.user.recentNotifications);
  const unreadRecentNotifications = computed(() =>
    recentNotifications.value.filter((n) => !n.read),
  );

  const markNotificationAsReadStore = (notificationId, read) => {
    store.commit('setUserNotificationRead', {
      notificationId,
      read,
    });
  };

  const markAllNotificationsAsReadStore = (notificationIds) => {
    store.commit('markAllNotificationsRead', notificationIds);
  };

  const pushNewNotification = (notification) => {
    if (
      notification.context_org_id &&
      store.state.auth.user.currentOrgRole.org_id !== notification.context_org_id
    ) {
      return;
    }

    const newNotification = {
      ...notification,
      read: false,
      data: JSON.stringify(notification.data),
    };
    store.commit('pushNewNotification', newNotification);
  };

  return {
    recentNotifications,
    unreadRecentNotifications,
    pushNewNotification,
    markNotificationAsReadStore,
    markAllNotificationsAsReadStore,
  };
}
