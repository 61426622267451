<template>
  <ModalDefault closeButtonStyle="outside" @close="keepMeLoggedIn" title="Account Inactivity">
    <p class="mb-5">
      Due to account inactivity, you will be logged out in {{ formattedCountdown }} minutes.
    </p>
    <div class="d-flex timeout-cta justify-content-end">
      <Button variant="filled" @click="keepMeLoggedIn" class="me-3"> Keep me logged in </Button>
      <Button variant="outlined" innerBgColor="#fff" @click="logMeOut"> Logout </Button>
    </div>
  </ModalDefault>
</template>

<script>
import ModalDefault from '@/components/modal/Default';
import Button from '@/components/Button';

export default {
  components: { ModalDefault, Button },
  props: {
    sessionTimeoutData: {
      type: Object,
    },
  },
  setup(props) {
    const { keepMeLoggedIn, logMeOut, formattedCountdown } = props.sessionTimeoutData;
    return { keepMeLoggedIn, formattedCountdown, logMeOut };
  },
};
</script>
