<template>
  <GlobalLayoutWrapper>
    <PageBackground />
    <div class="d-flex">
      <Sidebar />
      <div class="w-100 d-flex justify-content-center">
        <div class="main-wrapper">
          <TopNav />
          <main tabindex="-1" id="main-content">
            <Nuxt />
          </main>
        </div>
      </div>
    </div>
  </GlobalLayoutWrapper>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import GlobalLayoutWrapper from '@/components/layout/GlobalLayoutWrapper';
import Sidebar from '@/components/layout/freeTier/Sidebar';
import TopNav from '@/components/layout/freeTier/TopNav';
import PageBackground from '@/components/layout/freeTier/PageBackground';

export default defineComponent({
  components: {
    GlobalLayoutWrapper,
    Sidebar,
    PageBackground,
    TopNav,
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.main-wrapper {
  padding: 0px 0.75rem 2rem 0.75rem;
  width: 100%;

  @include m-small {
    max-width: 560px;
  }

  @include m-medium {
    max-width: 700px;
  }

  @include m-large {
    max-width: 800px;
  }

  @include m-xl {
    max-width: 1000px;
  }

  @include m-xxl {
    max-width: 1200px;
  }
}
</style>
